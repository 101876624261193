import React, { Component } from "react";
import Slider from "react-slick";

export default class PreviousNextMethods extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: !0,
      arrows: !1,
      centerPadding: "0px",
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: !0,
      autoplaySpeed: 3e3,
      responsive: [{ breakpoint: 768, settings: { slidesToShow: 1 } }]
    }; 
    return (
      <>    <div className="feedback-section-two  xl-mt-100 md-mt-60" data-aos="fade-up" id="tools">
      <img src="images/shape/shape_08.svg" alt="" className="shapes shape-one" />
      <img src="images/shape/shape_09.svg" alt="" className="shapes shape-two" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-4 col-md-5 col-sm-8">

          <div className="title-style-one align-items-center" data-aos="fade-up">
            <div className="sc-title-three">over 150k+ client</div>
          
            {/* <h2 className="main-title md">Partners and Investors </h2> */}
          </div> {/* /.title-style-one */}


            <div className="title-style-one text-center text-sm-start xs-pb-20">
              <h2 className="main-title">IGNITE Tools</h2>
              <p>The Product Layer provides a variety of ways to interact with the Ignite blockchain ecosystem including Swap, Cross Chain Wallet, Explorer,Dex,NFT Market, Token Issuer and more.								</p>
            </div> {/* /.title-style-one */}
          </div>
          <div className="col-xl-6 col-lg-8 col-md-7 col-sm-4 ms-auto d-flex justify-content-center justify-content-sm-end">
            <ul className="slider-arrows slick-arrow-two d-flex style-none">
              <li className="prev_f1 slick-arrow ripple-btn"  onClick={this.previous}><i className="bi bi-arrow-left" /></li>
              <li className="next_f1 slick-arrow ripple-btn" onClick={this.next}><i className="bi bi-arrow-right" /></li>
            </ul>


          </div>
        </div>
      </div> {/* /.container */}
      <div className="inner-content mt-60 md-mt-40">
        <div className="slider-wrapper">
          <div className="feedback_slider_two">
            <Slider ref={c => (this.slider = c)} {...settings}>
              <div className="item">
                <div className="feedback-block-two d-sm-flex">
                  <div className="img-meta">
                    <img src="images/tools/dex_blc.png" alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="h3">DEX</div>
                    <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p>
                    <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                      <span>Continue Reading</span>
                      <img src="images/icon/icon_14.svg" alt="" className="arrow" />
                    </a>
                  </div> {/* /.text-wrapper */}
                </div> {/* /.feedback-block-two */}
              </div>
              <div className="item">
                <div className="feedback-block-two d-sm-flex">
                  <div className="img-meta">
                    <img src="images/tools/explorer_blc.png" alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="h3">Explorer</div>
                    <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p>
                    <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                      <span>Continue Reading</span>
                      <img src="images/icon/icon_14.svg" alt="" className="arrow" />
                    </a>
                  </div> {/* /.text-wrapper */}
                </div> {/* /.feedback-block-two */}
              </div>
              <div className="item">
                <div className="feedback-block-two d-sm-flex">
                  <div className="img-meta">
                    <img src="images/tools/nft-market_blc.png" alt="" />
                   
                  </div>
                  <div className="text-wrapper">
                    <div className="h3">NFT</div>
                    <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p>
                    <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                      <span>Continue Reading</span>
                      <img src="images/icon/icon_14.svg" alt="" className="arrow" />
                    </a>
                  </div> {/* /.text-wrapper */}
                </div> {/* /.feedback-block-two */}
              </div>
              <div className="item">
                <div className="feedback-block-two d-sm-flex">
                  <div className="img-meta">
                    <img src="images/tools/swap_blc.png" alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="h3">Swap</div>
                    <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p>
                    <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                      <span>Continue Reading</span>
                      <img src="images/icon/icon_14.svg" alt="" className="arrow" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="feedback-block-two d-sm-flex">
                  <div className="img-meta">
                    <img src="images/tools/token-issuer_blc.png" alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="h3">Token Issuer</div>
                    <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p>
                    <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                      <span>Continue Reading</span>
                      <img src="images/icon/icon_14.svg" alt="" className="arrow" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="feedback-block-two d-sm-flex">
                  <div className="img-meta">
                    <img src="images/tools/wallet_blc.png" alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="h3">Wallet</div>
                    <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p>
                    <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                      <span>Continue Reading</span>
                      <img src="images/icon/icon_14.svg" alt="" className="arrow" />
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          {/* /.feedback_slider_two */}
        </div> 
        {/* /.slider-wrapper */}
      </div> 
      
      {/* /.inner-content */}
    </div></>
    );
  }
}