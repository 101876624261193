import Footer from "../footer";
import Header from "../header";

function Comingsoon() {
  document.title="Coming Soon | IGNITE Blockchain";
  return (
    <>
    <Header/>
      <div className="main-page-wrapper">
        <div className="hero-banner-two">
          <div className="bg-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-md-7">
                  <h1 className="hero-heading text-uppercase"> Comming Soon..</h1>
                </div>
              </div>
            </div>
            <div className="illustration-holder">
              <img src="images/assets/ils_04_6.svg" alt="" className="shapes shape-seven1" />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Comingsoon;