
function IGNITEvideo() {
    return (
        <>
            <div className="fancy-feature-eighteen ignite_vedio  position-relative  pb-150 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
                <div className="container">
                    <div className="row">

                        <div className="title-style-one text-center mt-4" data-aos="fade-up">
                            <div className="sc-title-three"> overview  </div>

                            {/* <h2 className="main-title md">Partners and Investors </h2> */}
                        </div> {/* /.title-style-one */}

                        <div class="title-style-one text-center aos-init aos-animate mt-0" data-aos="fade-up">
                            <h2 class="main-title md"><span className="ignite_vedio_main_heading">IGNITE</span> Overview</h2>
                            <p>The next generation BLOCKCHAIN for Decentralized Finance. We are building a Thriving Ecosystem on top of Ignite Chain to maximize the power of Decentralization.</p>
                        </div>
                        <div className="fancy-short-banner-four mt-2">
                            <div className="container">
                                <div className="bg-wrapper d-flex align-items-center justify-content-center" style={{ backgroundImage: 'url(images/media/video_img_first.png)' }}>
                                    {/* <a href="https://www.youtube.com/watch?v=1iIZeIy7TqM" className="video-icon tran3s d-flex align-items-center justify-content-center"><i className="fas fa-play" />
                                    </a> */}
                                    {/* <a href="https://www.youtube.com/watch?v=1iIZeIy7TqM" class="play-btn popup-youtube"><i class="fas fa-play"></i></a> */}
                                    {/* <a class="fancybox video-icon tran3s" data-fancybox="" href="images\Final Render-_2.mp4"><i class="fas fa-play"></i></a> */}
                                    <a href="https://www.youtube.com/watch?v=0XOIwJ2il5E" className="fancybox video-icon tran3s d-flex align-items-center justify-content-center" data-fancybox ><i className="fas fa-play" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shapes oval-one" />
                <div className="shapes oval-two" />
                <div className="shapes oval-three" />
            </div>
        </>
    );
}

export default IGNITEvideo;