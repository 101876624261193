
function Banner() {
    return (
        <><div className="hero-banner-two position-relative">

            <video autoPlay muted loop id="myVideo">
                <source src="images/SC_1.webm" type="video/mp4" />
            </video>

            <div className="bg-wrapper">
                <div className="container ">
                    <div className="row ">
                        <div className="col-xl-12 col-md-12 text-center">
                            {/* <h4 className="hero-heading text-uppercase banner_main_heading"> fast network for
                                the people, visionary
                                for the developer</h4> */}
                                 <h4 className="hero-heading text-uppercase banner_main_heading"> fast network for
                                the people, visionary
                                for the developer</h4>
                            <p className="text-lg pt-25 pb-40 lg-pb-20 sm-pt-10 text-white">Ignite is a Decentralized Blockchain Built to Enable Scalable, user-Friendly dApps for the Global.</p>


                            <ul className="style-none button-group d-sm-flex align-items-center justify-content-center">


                                <li className="me-4 mt-10"><a target="_blank" href="https://ignitescan.com/" className="btn-one ripple-btn">Explorer</a></li>
                                <li className="me-4 mt-10"><a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/" className="btn-one ripple-btn">documentation</a></li>

                            </ul>

                        </div>
                    </div>
                </div> {/* /.container */}
                {/* <div className="illustration-holder">
                    <img src="images/assets/ils_04.svg" alt="" className="main-illustration w-100" />
                    <img src="images/assets/ils_04_1.svg" alt="" className="shapes shape-one" />
                    <img src="images/assets/ils_04_2.svg" alt="" className="shapes shape-two" />
                    <img src="images/assets/ils_04_2.svg" alt="" className="shapes shape-three" />
                    <img src="images/assets/ils_04_3.svg" alt="" className="shapes shape-four" />
                    <img src="images/assets/ils_04_4.svg" alt="" className="shapes shape-five" />
                    <img src="images/assets/ils_04_5.svg" alt="" className="shapes shape-six" />
                    <img src="images/assets/ils_04_6.svg" alt="" className="shapes shape-seven" /> */}
                {/* <div className="card-one shapes d-flex align-items-center justify-content-center">
                        <div className="icon"><i className="bi bi-check-lg" /></div>
                        <h6>A++ Performance</h6>
                    </div>  */}
                {/* <div className="card-two shapes text-center">
                        <div className="icon"><i className="bi bi-check-lg" /></div>
                        <div className="main-count"><span className="counter">20</span>k</div>
                        <div className="info">5 Start Rating</div>
                        <ul className="style-none d-flex justify-content-center rating">
                            <li><i className="bi bi-star-fill" /></li>
                            <li><i className="bi bi-star-fill" /></li>
                            <li><i className="bi bi-star-fill" /></li>
                            <li><i className="bi bi-star-fill" /></li>
                            <li><i className="bi bi-star-fill" /></li>
                        </ul>
                    </div> /.card-two */}
                {/* </div> */}
                {/* /.illustration-holder */}
            </div>
            {/* /.bg-wrapper */}
        </div>

            <div className="counter-section-one fancy-bg">
                <div className="container">
                    <div className="inner-container">
                        <div className="row justify-content-center banner_background">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one text-center mb-20">
                                <div className="main-count"><span className="counter"> 0.000021</span></div>
                                    {/* <div className="main-count"><span className="counter">0.0000525</span></div> */}
                                    {/* <p> Avg. cost per Transaction
                                    </p> */}
                                    <p>Min.  cost per transaction
                                    </p>
                                </div> {/* /.counter-block-one */}
                            </div>
                            <div className="col-md-3 col-sm-6" data-aos="fade-up" data-aos-delay={100}>
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">2</span>s</div>
                                    <p>Block Time
                                    </p>
                                </div> {/* /.counter-block-one */}
                            </div>
                            <div className="col-md-2 col-sm-6" data-aos="fade-up" data-aos-delay={200}>
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">540B</span></div>
                                    <p>Block Size
                                    </p>
                                </div> {/* /.counter-block-one */}
                            </div>

                            <div className="col-md-3 col-sm-6" data-aos="fade-up" data-aos-delay={200}>
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">2</span>s</div>
                                    <p className="counter_info">Transaction Time
                                    </p>
                                </div> {/* /.counter-block-one */}
                            </div>


                        </div>
                    </div> {/* /.inner-container */}
                </div>
                {/* <img src="images/shape/shape_04.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_05.svg" alt="" className="shapes shape-two" /> */}
            </div></>
    );
}

export default Banner;