import AOS from 'aos';
import '../App.css'
import Header from ".././header/index";
import Banner from "./banner/index";
import Footer from '.././footer/index';
import Preloader from './preloader';
import IGNITEBlockchain from './igniteblockchain';
import IGNITEBlockchainWorks from './igniteblockchainworks';
import Client from './client';
import IGNITEFeatures from './features';
import Proofofreward from './proofofreward';
import Wallet from './wallet';
import Tools from './tools';
import Explorer from './explorer';
import Codes from './codes';
import Governance from './governance';
import Decentralize from './decentralize';
import FAQ from './faq';
import Feedback from './feedback';
import BlogNews from './blognews';
import Inquiry from './inquiry';
import IGNITEvideo from './ignitevideo';
import IGNITEbuild from './ignitebuild';



AOS.init({
  // initialise with other settings
  duration: 2000
});
function MainPage() {
  document.title = "Home Page | IGNITE Blockchain";
  //   useEffect(() => {
  //     $("#ctn-preloader").fadeOut();
  // }, []);
  return (
    <div>

      <div className="main-page-wrapper" id="scroll_top">
        {/* ===================================================
    Loading Transition
  ==================================================== */}
        <Preloader />
        <Header />

        <Banner />
        <br /><br />
        <IGNITEBlockchain />
        <br></br>
        <IGNITEFeatures />
        <Proofofreward/>

        <IGNITEvideo />
     
        {/* <Tools />  */}
       
        <IGNITEbuild />



        {/* <IGNITEBlockchainWorks /> */}


        {/* <Codes /> */}

        {/* <Wallet /> */}



        {/* <Explorer /> */}




        {/* <Governance /> */}

        {/* <Decentralize /> */}

        {/* <Feedback /> */}

        {/* <FAQ /> */}

        {/* <BlogNews /> */}


        <Inquiry />

        <Client />

        <Footer />
        <a href="#scroll_top" className="scroll-top">
          <i className="bi bi-arrow-up-short top_arrow" />
        </a>

      </div>
    </div>
  );
}

export default MainPage;