function IgniteSolutionsContactUs() {
    return (
        <>

            <div className="fancy-short-banner-three position-relative mt-160 lg-mt-80">
                <div className="container">
                    <div className="bg-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-8 m-auto aos-init aos-animate" data-aos="fade-up">
                                <div className="title-style-one text-center white-vr mb-30 aos-init aos-animate" data-aos="fade-up">
                                    <h2 className="main-title">Want to build on Ignite?</h2>
                                </div> {/* /.title-style-one */}
                                <a href="contact-us.html" className="btn-eight rounded-pill ripple-btn">Contact us</a>
                            </div>
                        </div>
                    </div> {/* /.bg-wrapper */}
                </div> {/* /.container */}
            </div>


        </>


    )
}

export default IgniteSolutionsContactUs;