import Header from ".././header/index";
import WithdrawSidebar from "./withdrawsidebar";
import WithdrawBoxHeading from "./withdrawboxheading";

import Footer from '.././footer/index';



function ValigatorsWithdrawBox() {
    document.title="Validators-Daligators - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
            <Header/>
            <WithdrawSidebar/>
            <WithdrawBoxHeading/>
            <Footer />     
     
            </div>


        </div>
    );
}
export default ValigatorsWithdrawBox;