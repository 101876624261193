import Header from ".././header/index";
import FooterPrivacy from "./footerprivacy";
import FooterCryptoWallet from "./footercryptowallet";
import FooterIGNITEWallet from "./footerignitewallet";
import FooterexploredApps from "./footerexploredApps";
import FooterMultiChain from "./footermultichain";
import FooterGetStarted from "./footergetstarted";
import FooterCryptoCurrencies from "./footercryptocurrencies";
import FooterPartener from "./footerpartener";
import Footer from '.././footer/index';


// function FooterWallet()
function Wallet() {
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <FooterPrivacy/>
                <FooterCryptoWallet/>
                <FooterIGNITEWallet/>
                <FooterexploredApps/>
                <FooterMultiChain/>
                <FooterGetStarted/>
                <FooterCryptoCurrencies/>
                <FooterPartener/>
                <Footer />

            </div>


        </div>
    );
}
export default Wallet;
// export default FooterWallet;