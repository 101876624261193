function Researchheader() {
    return (
        <>
            <div className="theme-inner-banner research_page_heading">
                <div className="container">
                    <h2 className="intro-title text-center">Re-Search</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li><a href="/">Home</a></li>
                        <li className="current-page link_heading">Re-Search</li>
                    </ul>
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
            </div>

        </>
    )
}

export default Researchheader;