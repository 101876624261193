import Header from ".././header/index";
import Researchheader from "./header";
import Pricingtable from "./pricing";
import Footer from '.././footer/index';


function ResearchPage() {
    document.title = "Reasearch - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <Researchheader />
                <Pricingtable />
                <Footer />

            </div>


        </div>
    );
}
export default ResearchPage;