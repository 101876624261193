import React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';



function IGNITEbuild() {

    const [code, setCode] = React.useState(
        `pragma solidity ^0.7.0;
        contract IgniteMint is Initializable {
            // define used libs
            using SafeMath for uint256;
            using Address for address;
            using SafeERC20 for ERC20;
        IgniteMintAddressProvider public addressProvider;
            function initialize(address owner, address 	
        _addressProvider) public initializer {
        addressProvider = IgniteMProvider(_addressProv`
    );
    return (
        <>


            <div className="fancy-feature-eighteen igt_build_area position-relative pt-200 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-two aos-init aos-animate" data-aos="fade-left" style={{marginTop: '-62px'}}>


                                <div className="title-style-one text-left" data-aos="fade-up">
                                    <div className="sc-title-three">build </div>

                                    {/* <h2 className="main-title md">Partners and Investors </h2> */}
                                </div> {/* /.title-style-one */}

                                <div className="title-style-three">
                                    {/* <div class="sc-title">Over 150k+ Client</div> */}
                                    <h2 className="main-title"><span className="ignite_site_main_heading"> IGNITE</span>
                                        &nbsp; Build Limitless dApps on</h2>
                                </div> {/* /.title-style-three */}
                                <p className="pt-20 pb-25 lg-pb-20">IgniteChain is built for the next generation of apps that enables developers to build scalable user-friendly dApps with low transaction fees without ever sacrificing security. Ignite is creating the best network for Web3 developers with flexible deployment, seamless interoperability, and incredible on-chain incentives. Be a part of this revolution and  Let's start #BuildOnIgnite
                                    .
                                </p>
                                <a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/developer-guide" className="mt-50 lg-mt-30 proof_button">Developer Guide</a>
                            </div> {/* /.block-style-two */}
                        </div>



                    </div>
                </div> {/* /.container */}
                <div className="illustration-holder aos-init aos-animate align-items-center" data-aos="fade-right">
                    {/* <img src="images/assets/sss [2].png" alt="" className="w-100  main-illustration ignite_build max-width: 100%;  height: auto;" /> */}
                    <CodeEditor
                        value={code}
                        language="sol"
                        placeholder="Please enter SOL code."
                        onChange={(evn) => setCode(evn.target.value)}
                        editing={false}
                        padding={15}
                        style={{
                            fontSize: 12,
                            minHeight: "500px",
                            borderRadius: "20px",
                            backgroundColor: "#001944",
                            boxShadow: "0px 8px 15px rgb(7 100 255 / 40%)",
                            color: "#fff",
                            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                        }}
                    />
                </div>
                {/* /.illustration-holder */}
                <div className="shapes oval-one" />
                <div className="shapes oval-two" />
                <div className="shapes oval-three" />
            </div></>
    );
}


export default IGNITEbuild;