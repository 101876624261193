function DeveloperToolsBoxes() {
    return (
        <>
            <div className="container mt-150">
                <div className="row gx-xxl-5">
                    <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up">
                        <div className="block-style-four">
                            {/* <div class="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_35.svg" alt=""></div> */}
                            <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/deploying-on-ignitechain"><h5>Software Development Toolkits</h5></a>
                            <p>Toolkits to support developers building on 5ireChain</p>
                            <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/deploying-on-ignitechain" className="more-btn"><img src="images/icon/icon_13.svg" alt="" className="tran3s" /></a>
                        </div> {/* /.block-style-four */}
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                        <div className="block-style-four">
                            {/* <div class="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_08.svg" alt=""></div> */}
                            <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/api"><h5>REST API</h5></a>
                            <p>Flexible exposed APIs to allow developers to build applications.</p>
                            <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/api" className="more-btn"><img src="images/icon/icon_13.svg" alt="" className="tran3s" /></a>
                        </div> {/* /.block-style-four */}
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                        <div className="block-style-four">
                            {/* <div class="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_09.svg" alt=""></div> */}
                            <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/developer-guide"><h5>Developer Documentation</h5></a>
                            <p>IgniteChain defines Real-time latencies, data API monitoring, Market status, and endpoint checks. </p>
                            <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/developer-guide" className="more-btn"><img src="images/icon/icon_13.svg" alt="" className="tran3s" /></a>
                        </div> {/* /.block-style-four */}
                    </div>
                </div>
            </div>


        </>
    );
}
export default DeveloperToolsBoxes;