function DelegatorListSidebar() {
    return (
        <>

            <div className="sidebar">
                <div className="logo-details">
                    <i className="bx bx-menu" id="btn" />
                </div>
                <ul className="nav-list px-0">
                    <li>
                        <a href="/validator_delegators">
                            <i className="bx bxs-dashboard" />
                            <span className="links_name">Delegator Dashbord</span>
                        </a>
                        <span className="tooltip">Delegator Dashbord</span>
                    </li>

                    <li>
                        <a href="/validator_delegator_list" className="text-white">
                            <i className="bx bx-money" />
                            <span className="links_name">withdraw list</span>
                        </a>
                        <span className="tooltip">withdraw list</span>
                    </li>

                    <li>
                        <a href="/validator_delegators" className="text-white">
                            <i className="bx bx-list-minus" />
                            <span className="links_name">Delegator List</span>
                        </a>
                        <span className="tooltip">Delegator List</span>
                    </li>

                    
                </ul>
            </div>


        </>


    )
}

export default DelegatorListSidebar;