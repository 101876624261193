function IgtSolutionsCounter() {
    return (
        <>

            <div className="fancy-feature-seventeen position-relative mt-160 xl-mt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12" data-aos="fade-right">
                            <div className="title-style-three text-start text-lg-start">
                                <p className="m0 fs-3 text-center md-pt-30 line-height">
                                    Digital assets enable seamless asset management throughout the asset’s lifecycle,
                                    from creation and issuance to distribution and global exchange.</p>
                            </div> {/* /.title-style-three */}
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="shapes shape-one" />
            </div>



            <div className="pr-details-one mt-20 lg-mt-90 mb-80 lg-mb-100">
                <div className="container">
                    <div className="mt-80 pt-80 pb-40 mb-60 lg-mt-50 lg-pt-50 lg-pb-20 lg-mb-40">
                        <div className="row gx-xxl-5">
                            <div className="col-lg-4">
                                <h2 className=" text-primary fw-bolder display-6">95%</h2>
                                <h3 className=" fs-5 text-dark mb-3">The Challange</h3>
                                <p className="line-height">Complete lineup of features from any major Maecena quis interdu, orci at euis  dapibus.</p>
                            </div>
                            <div className="col-lg-4">
                                <h2 className=" text-primary fw-bolder display-6">75%</h2>
                                <h3 className="fs-5 text-dark  mb-3">The Challange</h3>
                                <p className="line-height">Complete lineup of features from any major Maecena quis interdu, orci at euis  dapibus.</p>
                            </div>
                            <div className="col-lg-4">
                                <h2 className=" text-primary fw-bolder display-6">40%</h2>
                                <h3 className="fs-5 text-dark  mb-3">The Challange</h3>
                                <p className="line-height">Complete lineup of features from any major Maecena quis interdu, orci at euis  dapibus.</p>
                            </div>
                            {/* <div class="col-xl-5 col-lg-6 ms-auto">
									<h3 class="sub-title">Our Goal</h3>
									<ul class="style-none list-item">
										<li>Various analysis options with ai.</li>
										<li>Page Load (time, number of requests) lorem quis.</li>
										<li>Big data analysis with fast process.R</li>
										<li>Lorem analysis somthing quis dummy.</li>
									</ul>
								</div> */}
                        </div>
                    </div>
                </div>
            </div>



        </>


    )
}

export default IgtSolutionsCounter;