import Header from ".././header/index";
import Protocol from "./protocol/index";
import Target from "./target/index";
import Business  from "./business/index";
import Audience from "./audience/index";
import Analysis from "./analysis/index";
import Operations from "./operation/index";
import Solutions from "./solutions/index";
import Technology from "./technology/index";

import Footer from '.././footer/index';

function Enterprise() {
    document.title="Enterprise - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <Protocol />
                <Target/>
                <Business/>
                <Audience/>
                <Analysis/>
                <Operations/>
                <Solutions/>
                <Technology/>
               
                <Footer />

            </div>


        </div>
    );
}
export default Enterprise;