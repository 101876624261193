function FooterMultiChain() {
    return (
        <>
            <div className="fancy-feature-fourteen mt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-md-6 order-md-last">
                            <div className="block-style-two" data-aos="fade-left">
                                <div className="title-style-one">
                                    {/* <div class="sc-title-five">Over 150k+ Client</div> */}
                                    <h2 className="fs-3 fw-bold">Multi Chain integrated</h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-2 pb-20">Ignite wallet support Native cross-chain bridge and Multi-chain
                                    integration with low fees and high efficiency.</p>
                            </div> {/* /.block-style-two */}
                        </div>
                        <div className="col-xl-7 col-md-6 order-md-first">
                            <div className="illustration-holder d-inline-block position-relative sm-mt-50">
                            
                            <img src="images/assets/Multi-Chain-integrated.png" alt="" />
                                {/* <img src="images/assets/ils_12.svg" alt="" />
                                <img src="images/assets/ils_12_1.svg" alt="" className="shapes shape-one" data-aos="fade-down" data-aos-duration={1500} />
                                <img src="images/assets/ils_12_1.svg" alt="" className="shapes shape-two" data-aos="fade-down" data-aos-delay={100} data-aos-duration={1500} />
                                <img src="images/assets/ils_12_2.svg" alt="" className="shapes shape-three" data-aos="fade-up" data-aos-delay={100} data-aos-duration={1500} />
                                <img src="images/assets/ils_12_3.svg" alt="" className="shapes shape-four" />
                                <img src="images/assets/ils_12_4.svg" alt="" className="shapes shape-five" data-aos="fade-left" data-aos-duration={1500} /> */}
                            </div> {/* /.illustration-holder */}
                        </div>
                    </div>
                </div> {/* /.container */}
                {/* <img src="images/shape/3.png" alt="" class="shapes shape-1"> */}
            </div>

        </>


    )
}

export default FooterMultiChain;