import Header from ".././header/index";
import DeveloperToolsHeading from "./developertoolsheading";
import DeveloperToolsBoxes from "./developertoolsboxes";
import DeveloperToolsButtons from "./developertoolsbuttons";
import Footer from '.././footer/index';

function DeveloperTools () {
    document.title="Developer-Tools - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <DeveloperToolsHeading/>
                <DeveloperToolsBoxes/>
                <DeveloperToolsButtons/>
                <Footer />

            </div>


        </div>
    );
}
export default DeveloperTools;