import { Link } from "react-router-dom";

function Footer() {
    return (




        <><div className="address-section-one">
            <div className="container">
                <div className="inner-content bg-black">
                    <div className="row g-0">
                        <div className="col-md-6 ">
                            <div className="address-block-one text-center my-auto mx-auto">
                                <div className="text-meta">
                                    <h4 className="title fs-2 text-white">Join Our Community</h4>
                                </div> {/* /.text-meta */}
                            </div> {/* /.address-block-one */}
                        </div>
                        <div className="col-md-6 ">
                            <div className="address-block-one text-center">
                                <div className="text-meta sm-pt-0">
                                    <div className="social-icon style-none">
                                        <a target="_blank" href="https://twitter.com/ignitechain"><i className="fab fa-twitter display-6  mr-3" /></a>
                                        <a target="_blank" href="https://ignitechain.medium.com/">&nbsp;&nbsp;&nbsp;<i className="fab fa-medium-m display-6 mr-3" /></a>
                                        <a target="_blank" href="https://www.youtube.com/channel/UCe2PDO2SBh9psGiqW6-CMIQ">&nbsp;&nbsp;&nbsp;<i className="fab fa-youtube display-6 mr-3" /></a>
                                        <a target="_blank" href="https://www.reddit.com/r/ignitechain/">&nbsp;&nbsp;&nbsp;<i className="fab fa-reddit display-6 mr-3" /></a>
                                        <a target="_blank" href="https://t.me/IgniteChain">&nbsp;&nbsp;&nbsp;<i className="fab fa-telegram-plane display-6 mr-3" /></a>
                                        <a target="_blank" href="https://github.com/ignitechain">&nbsp;&nbsp;&nbsp;<i className="fab fa-github-alt display-6 " /></a>
                                    </div>
                                </div> {/* /.text-meta */}
                            </div> {/* /.address-block-one */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="footer-style-three theme-basic-footer mt-70">
                <img src="images/shape/shape_30.png" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_28.png" alt="" className="shapes shape-two" />
                <img src="images/shape/shape_29.png" alt="" className="shapes shape-three" />
                <div className="container">
                    <div className="inner-wrapper">

                        <div className="row mt-60 justify-content-center">
                            <div className="col-lg-2 footer-intro mb-40">
                                {/* <div className="logo"><a href="index-2.html"><img src="images/logo/logo_01.png" alt="" width={160} /></a></div>
    <p>We will assist you in becoming more successful.</p> */}
                                <div className="newsletter">
                                    <h5 className="footer-title text-left">Ecosystem </h5>



                                    <ul className="footer-nav-link style-none">

                                        <li><a>Ignite Studio</a></li>
                                        {/* <li><a target="_blank" href="ignite_studio">Ignite Studio</a></li> */}
                                        <li><a target="_blank">Governance</a></li>
                                        {/* <li><a target="_blank" href="/becomeavalidator">Become a Validator</a></li> */}
                                        <li><Link target="_blank" to="/becomeavalidator">Become a Validator</Link></li>
                                        <li><a target="_blank" href="https://docs.google.com/forms/d/1YfsH6v4nuQo05dv8LltinZAyQSfU5SKbh8keUwts9Jc/viewform?edit_requested=true">Submit Dapps</a></li>


                                    </ul>

                                    {/* <li><a href="#">The Consensus</a></li> */}
                                    {/* <p>Join over <span className="text-white">68,000</span> people getting our emails</p> */}
                                    {/* <p>Join over <span className="text-white"></span> people getting our emails</p> */}
                                    {/* <p> Keep up to date with our progress <span className="text-white"></span></p>
                                    <form action="#" className="subscribe_box">
                                        <input type="email" placeholder="Enter your email" />
                                        <button>Sign Up</button>
                                    </form>
                                    <div className="info">We only send interesting and relevant emails.</div> */}
                                </div>


                                {/* <ul className="d-flex social-icon style-none">
     
        <li><a href="#"><i className="fab fa-twitter" /></a></li>
        <li><a href="#"><i className="fab fa-youtube" /></a></li>
        <li><a href="#"><i className="fab fa-github" /></a></li>
        <li><a href="#"><i className="fab fa-reddit" /></a></li>
        <li><a href="#"><i className="fab fa-medium" /></a></li>
        <li><a href="#"><i className="fab fa-telegram" /></a></li>
      
    </ul> */}
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6 mb-30">
                                <h5 className="footer-title">Technology</h5>
                                <ul className="footer-nav-link style-none">
                                    {/* <li><a href="#">Home</a></li> */}
                                    <li><Link target="_blank" to ="/ignitewallet">Wallet</Link></li>
                                    {/* <li><a href="#">Explorer</a></li> */}
                                    <li><a target="_blank" href="https://ignitescan.com/maintanance">Testnet</a></li>
                                    <li><a target="_blank" href="https://ignitescan.com/">Mainnet</a></li>
                                    <li><a target="_blank" href="/network">Network</a></li>
                                    {/* <li><a href="#">Home</a></li>
                                    <li><a href="#">Introduce to Ignite</a></li>
                                    <li><a href="#">Stake on Ignite</a></li>
                                    <li><a href="#">Ignite Coin</a></li>
                                    <li><a href="#">The Consensus</a></li> */}
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
                                {/* <h5 className="footer-title">Ignite Tool</h5> */}
                                <h5 className="footer-title">Community</h5>
                                <ul className="footer-nav-link style-none">
                                    {/* <li><a href="#">Partners</a></li> */}
                                    <li><a target="_blank" href="https://blog.ignite.info/">Blog</a></li>
                                    <li><a target="_blank" href="/ignitecommunity">Community</a></li>
                                    {/* <li><a href="#">Media & PR</a></li> */}

                                    <li><a target="_blank" href="https://drive.google.com/drive/folders/1dJfWA9m0hEdyHwjnd4F4ggqlaLp9Vghi">Brand Kit</a></li>
                                    <li><a target="_blank">Support</a></li>
                                    {/* <li><a href="#">Mainnet</a></li>
                                    <li><a href="https://ignitevalidator.ignite.info" target="_blank" rel="noreferrer">Get a Node</a></li> */}
                                    {/* <li><a href="#">Wallet</a></li>
                                    <li><a href="#">Explorer</a></li>
                                    <li><a href="#">Documentation</a></li>
                                    <li><a href="#">Testnet</a></li>
                                    <li><a href="#">Mainnet</a></li>
                                    <li><a href="https://ignitevalidator.ignite.info" target="_blank" rel="noreferrer">Get a Node</a></li> */}
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
                                {/* <h5 className="footer-title">Ecosystem</h5> */}
                                <h5 className="footer-title">Developer </h5>
                                <ul className="footer-nav-link style-none">
                                    {/* <li><a href="#">Whitepaper </a></li>
                                    <li><a href="#">Litepaper</a></li> */}
                                    <li><a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/">Documentation</a></li>
                                    <li><a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/developer-guide">Developer Guide</a></li>
                                    <li><a href="https://discord.gg/DNvJGxdezJ" target="_blank">Dev Forum</a></li>
                                    <li><a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/developer-guide/token-standards
                                      "> Token issue</a></li>
                                    {/* <li><a href="#">Team</a></li> */}
                                    {/* <li><a href="#">About Us </a></li>
                                    <li><a href="#">Media and Press</a></li>
                                    <li><a href="#">Release</a></li>
                                    <li><Link to="/Roadmap"><a>Roadmap</a></Link></li>
                                    <li><a href="#">Community</a></li>
                                    <li><a href="#">Submit DAPPs</a></li>
                                    <li><a href="#">Team</a></li> */}
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
                                <h5 className="footer-title">Learn</h5>
                                {/* <h5 className="footer-title">Developer</h5> */}
                                <ul className="footer-nav-link style-none">
                                    <li><a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/">About us </a></li>
                                    <li><a target="_blank" href="/ignitecoin">Ignite Coin</a></li>
                                    <li><a target="_blank" href="/research">Research </a></li>
                                    <li><a target="_blank" href="/career">Careers</a></li>
                                    {/* <li><a>Careers</a></li> */}
                                    <li><a target="_blank" href="/enterprise">Enterprise</a></li>
                                    {/* <li><a href="#">White Paper</a></li>
                                    <li><a href="#">Dev Documents</a></li>
                                    <li><a href="#">Become a validator</a></li>
                                    <li><a href="#">Devforum</a></li> */}
                                </ul>
                            </div>
                            {/* <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
        <h5 className="footer-title">Legal</h5>
        <ul className="footer-nav-link style-none">
            <li><Link to="/Terms-Conditions"><a>Ignite Terms of use</a></Link></li>
            <li><Link to="/Coming-soon"><a>AML &amp; kyc Process</a></Link></li>
            <li><Link to="/Privacy-Policy"><a>Privacy policy</a></Link></li>
        </ul>
    </div> */}
                        </div>


                        <div className="row">
                            <div className="col-xl-12 mb-30">
                                <div className="newsletter ps-xl-5">
                                    <h5 className="footer-title">Subscribe - Email </h5>
                                    {/* <p>Join over <span>68,000</span> people getting our emails</p> */}
                                    <p> Keep up to date with our progress</p>
                                    <form className="footer_subscribe">
                                        <input type="email" placeholder="Enter your email" />
                                        <button disabled>Subscribe</button>
                                    </form>
                                    <div className="info">We only send interesting and relevant emails.</div>
                                </div>
                            </div>
                        </div>





                        <div className="bottom-footer">
                            <div className="d-lg-flex justify-content-between align-items-center">
                                <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                    {/* <li><Link to="/Privacy-Policy"><a >Privacy &amp; Terms.</a></Link></li> */}
                                    <li><a target="_blank" href="images/ignitechain_terms_of_use.pdf">Ignite Terms of use</a></li>
                                    {/* <li><a href="#">AML kyc Process</a></li> */}
                                    <li><a target="_blank" href="images/ignitechain_privacy_policy.pdf">Privacy policy</a></li>
                                </ul>
                                <p className="copyright text-center order-lg-0 pb-15">Copyright @2022 IGT IT Solution </p>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
        </>






    )
}
export default Footer;