function DeveloperToolsHeading() {
    return (
        <>

            <div className="hero-banner-five mt-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-md-7">
                            <h1 className="hero-heading">Developer Tools</h1>
                            {/* <p class="text-lg mb-20 pt-40 pe-xl-5 md-pt-30 md-mb-40"></p> */}
                            <ul className="style-none mt-50 button-group d-flex align-items-center">
                                <li className="me-4"><a href="#" className="btn-eight rounded-pill ripple-btn tran3s" disabled>Learn More</a></li>
                            </ul>
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="illustration-holder ">
                    <img src="images/assets/ils_13.svg" alt="" className="main-illustration ms-auto" />
                    <img src="images/assets/ils_13_1.svg" alt="" className="shapes shape-one" />
                    <img src="images/assets/ils_13_2.svg" alt="" className="shapes shape-two" data-aos="fade-down" />
                    <img src="images/assets/ils_13_2.svg" alt="" className="shapes shape-three" data-aos="fade-down" />
                </div> {/* /.illustration-holder */}
                {/* <div class="shapes oval-one"></div> */}
            </div>


        </>


    )
}
export default DeveloperToolsHeading;