import { BrowserRouter, Routes, Route } from "react-router-dom";
import Comingsoon from "./coming-soon";

import MainPage from "./homepage";
import MediaAssets from "./media-assets";
import PrivacyPolicy from "./privacy-policy";
import ResearchPage from "./research";
// import Ignitestudio from "./ignitestudio";
import IgniteStudio from "./ignite_studio";
import IgniteCoin from "./ignitecoin";
import Ignitefootercareer from "./career";
import RelationshipManager from "./relationshipmanager";
import SalesExecutive from "./salesexecutive";
import InternationalBusinessMarketer from "./internationalbusinessmarketer";
import GrowthMarketer from "./growthmarketer";
import MobileApplicationDeveloper from "./mobileapplicationdeveloper";
import BlockChainDeveloper from "./blockchaindeveloper";
import NodejsDeveloper from "./nodejsdeveloper";
import IgniteSolutions from "./ignitesolutions";
import IgniteSolutionsDescription from "./ignitesolutiondescription";
import DeveloperTools from "./developertools";
import Become_Sub_Validator from "./become_sub_validator";
import ValigatorsDaligators from "./validator_delegators";
import ValigatorsDaligatorsList from "./validator_delegator_list";
import ValigatorsWithdrawBox from "./validator_withdraw_box";
import ValigatorsDashboard from "./validators_dashboard";


import IGNITECommunity from "./ignitecommunity";
import Becomeavalidator from "./becomeavalidator";
// import FooterWallet from "./footerwallet";
import Wallet from "./wallet";
import Enterprise from "./enterprise";
import Network from "./network";
import Roadmap from "./roadmap";
import TermsCondtions from "./terms-conditions";



function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact key={"MainPage"} path="/" element={<MainPage />} />
        <Route exact key={"research"} path="/research" element={<ResearchPage />} />
        {/* <Route path="/ignitestudio" element={<Ignitestudio/>} /> */}
        <Route exact key={"ignite_studio"} path="/ignite_studio" element={<IgniteStudio />} />
        <Route exact key={"ignitecoin"} path="/ignitecoin" element={<IgniteCoin />} />
        <Route exact key={"career"} path="/career" element={<Ignitefootercareer />} />
        <Route exact key={"relationshipmanager"} path="/relationshipmanager" element={<RelationshipManager />} />

        <Route exact key={"salesexecutive"} path="/salesexecutive" element={<SalesExecutive />} />
        <Route exact key={"internationalbusinessmarketer"} path="/internationalbusinessmarketer" element={<InternationalBusinessMarketer />} />
        <Route exact key={"growthmarketer"} path="/growthmarketer" element={<GrowthMarketer />} />
        <Route exact key={"mobileapplicationdeveloper"} path="/mobileapplicationdeveloper" element={<MobileApplicationDeveloper />} />
        <Route exact key={"blockchaindeveloper"} path="/blockchaindeveloper" element={<BlockChainDeveloper />} />
        <Route exact key={"nodejsdeveloper"} path="/nodejsdeveloper" element={<NodejsDeveloper />} />


        <Route exact key={"ignitesolutions"} path="/ignitesolutions" element={<IgniteSolutions />} />
        <Route exact key={"ignitesolutiondescription"} path="/ignitesolutiondescription" element={<IgniteSolutionsDescription />} />
        <Route exact key={"developertools"} path="/developertools" element={<DeveloperTools />} />


        <Route exact key={"ignitecommunity"} path="/ignitecommunity" element={<IGNITECommunity />} />
        <Route exact key={"becomeavalidator"} path="/becomeavalidator" element={<Becomeavalidator />} />
        <Route exact key={"become_sub_validator"} path="/become_sub_validator" element={<Become_Sub_Validator />} />

        <Route exact key={"validator_delegators"} path="/validator_delegators" element={<ValigatorsDaligators />} />
        <Route exact key={"validator_delegator_list"} path="/validator_delegator_list" element={<ValigatorsDaligatorsList />} />
        <Route exact key={"validator_withdraw_box"} path="/validator_withdraw_box" element={<ValigatorsWithdrawBox />} />
        <Route exact key={"validators_dashboard"} path="/validators_dashboard" element={<ValigatorsDashboard />} />



        {/* <Route exact key={"footerwallet"} path="/footerwallet" element={<FooterWallet />} /> */}
        <Route exact key={"wallet"} path="/ignitewallet" element={<Wallet />} />

        <Route exact key={"enterprise"} path="/enterprise" element={<Enterprise />} />
        <Route exact key={"network"} path="/network" element={<Network />} />
        <Route exact key={"coming-soon"} path="/coming-soon" element={<Comingsoon />} />
        <Route exact key={"Media-assets"} path="/Media-assets" element={<MediaAssets />} />
        <Route exact key={"Privacy-Policy"} path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route exact key={"Terms-Conditions"} path="/Terms-Conditions" element={<TermsCondtions />} />
        <Route exact key={"Roadmap"} path="/Roadmap" element={<Roadmap />} />
        <Route path="*" element={<Comingsoon />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;