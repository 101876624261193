function ValidatorsDashboardHeading() {
    return (
        <>

            <section className="home-section mt-150">
                {/* <header className="theme-main-menu sticky-menu theme-menu-two" style={{ boxShadow: '0 13px 35px -12px rgb(35 35 35 / 10%)' }}>
                    <div className="inner-content">
                        <div className="container d-flex align-items-center justify-content-between">
                            <div className="logo order-lg-0"><a href="index.html" className="d-block main_logo"><img src="images/logo/48.png" alt="" /></a></div>
                            <button href="contact-us.html" className="get-in-touch-btn d-none d-lg-block order-lg-3  px-4 py-1">Connect</button>
                            <nav className="navbar navbar-expand-lg order-lg-2">
                                <button className="navbar-toggler second_toggler_button d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span />
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="d-block d-lg-none">
                                            <div className="main_logo"><a href="index.html"><img src="images/logo/48.png" alt="" /></a></div>
                                        </li>
                                        <li className="d-block d-lg-none">
                                            <div className="d-flex align-content-center">
                                                <img src="images/icon/icon_10.svg" width="25px" alt="" />
                                                <p className="hero-heading fs-5 text-capitalize mx-1 mt-25"> Mind Heart Soul</p>
                                            </div>
                                        </li>
                                        <li className="d-block d-lg-none">
                                            <a href="#">
                                                <i className="bx bx-home" />
                                                <span className="links_name">Home</span>
                                            </a>
                                        </li>
                                        <li className="d-block d-lg-none">
                                            <a href="#">
                                                <i className="bx bxs-dashboard" />
                                                <span className="links_name">Dashbord</span>
                                            </a>
                                        </li>
                                    </ul>
                                   
                                    <div className="mobile-content d-block d-lg-none">
                                        <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                                            <a href="contact-us.html" className="get-in-touch-btn px-4">#BuildOnIgnite</a>
                                        </div>
                                    </div> 
                                </div>
                            </nav>
                        </div>
                    </div> 
                </header> */}


                <div className="fancy-feature-thirteen position-relative mt-50 pb-60 lg-pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-sm-12 d-flex aos-init aos-animate mt-3 " data-aos="fade-up">
                                <div className="block-style-ten tran3s mt-0 py-4">
                                    <div className="col-xl-12 col-md-7">
                                        <div className="d-flex flex-wrap align-content-center px-3">
                                            <img src="images/icon/icon_10.svg" alt="" />
                                            <h4 className="hero-heading fs-2 text-capitalize m-2"> Mind Heart Soul</h4>
                                            {/* <p><a href="" class="text-white">https://ict.mind-heart-soul.org</a></p> */}
                                        </div>
                                        <div className="d-flex flex-wrap mt-30 justify-content-start px-1">
                                            <div className=" px-3 py-2  text-start d-block ">
                                                <h6 className="card-text">Owner</h6>
                                                <p className="card-text text-dark"><i className="fa fa-map-marker" /> 0xd3136axxxxxxxxxxxxxxx621c56 &nbsp; <i className="fa fa-copy" /> </p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap mt-30 justify-content-start px-1">
                                            <div className=" px-3 py-2  text-start d-block ">
                                                <h6 className="card-text">Signer</h6>
                                                <p className="card-text text-dark"><i className="fa fa-map-marker" /> 0xd3136axxxxxxxxxxxxxxx621c56 &nbsp; <i className="fa fa-copy" /> </p>
                                            </div>
                                        </div>
                                        {/* <ul class="style-none button-group d-lg-flex align-items-center pt-25 pb-50">
										<li><a href="withdraw_box.html" class="btn-five rounded-pill ripple-btn px-5 py-1">Withdraw</a></li>
									</ul> */}
                                    </div>
                                </div> {/* /.block-style-ten */}
                            </div>
                            <div className="col-xl-4 col-sm-12 d-flex aos-init aos-animate mt-3" data-aos="fade-up" data-aos-delay={100}>
                                <div className="block-style-ten tran3s mt-0 py-4 text-center">
                                    <br /><br /><br />
                                    <a href="/validator_withdraw_box" className="btn-five rounded-pill ripple-btn px-5 py-1">Withdraw</a>
                                    {/* <a href="contact-us.html" class="btn-five rounded-pill ripple-btn px-3 py-2">Become a Delegator</a> */}
                                    {/* <div class="style-none button-group d-lg-flex justify-content-center pt-25 pb-50">
								</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-xl-4 col-sm-6 d-flex aos-init aos-animate mt-3 " data-aos="fade-up">
                                <div className="block-style-ten tran3s mt-0 py-4">
                                    {/* <div class="icon d-flex align-items-end"><img src="images/icon/icon_24.svg" alt=""></div> */}
                                    <h6><a href="service-details-V1.html">Total Staking</a></h6>
                                    <h5>1000 IGT</h5>
                                </div> {/* /.block-style-ten */}
                            </div>
                            <div className="col-xl-4 col-sm-6 d-flex aos-init aos-animate mt-3" data-aos="fade-up" data-aos-delay={100}>
                                <div className="block-style-ten tran3s mt-0 py-4">
                                    {/* <div class="icon d-flex align-items-end"><img src="images/icon/icon_25.svg" alt=""></div> */}
                                    <h6><a href="service-details-V1.html">Total Earning </a></h6>
                                    <h5>1000 IGT</h5>
                                </div> {/* /.block-style-ten */}
                            </div>
                            <div className="col-xl-4 col-sm-6 d-flex aos-init aos-animate mt-3" data-aos="fade-up" data-aos-delay={200}>
                                <div className="block-style-ten tran3s mt-0 py-4">
                                    {/* <div class="icon d-flex align-items-end"><img src="images/icon/icon_26.svg" alt=""></div> */}
                                    <h6><a href="service-details-V1.html">Total Withdraw</a></h6>
                                    <h5>100 IGT </h5>
                                </div> {/* /.block-style-ten */}
                            </div>
                        </div>
                    </div> {/* /.container */}
                </div>
                <div className="container chart-bg">
                    <div className="px-5 pt-50 pb-50">
                        <button id="change-chart" className="btn-five ripple-btn">Change to Classic</button>
                        <br /><br />
                        <div id="chart_div" style={{ maxWidth: '800px', width: 'auto', height: '500px' }} />
                    </div>
                </div>
                {/*
				=====================================================
					Footer
				=====================================================
				*/}
             
                {/* /.footer-style-four */}
                <button className="scroll-top">
                    <i className="bi bi-arrow-up-short" />
                </button>
            </section>


        </>

    )
}

export default ValidatorsDashboardHeading;