function IGNITECommunityMainheading() {
    return (
        <>

            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title text-center nav-link-color text-capitalize">Ignite Community</h2>
                    {/* <p class="text-center text-white small pt-10">Ignite opened up the doors of freedom and ﬂexibility Work Culture. If you are a go-getter, dynamic and willing to grab opportunities that come your way, Ignite can be your playground where dreams take flight, ideas turn into innovations and every goal achieved is celebrated.</p> */}
                    {/* <ul class="page-breadcrumb style-none d-flex justify-content-center">
							<li><a href="index-2.html">Home</a></li>
							<li class="current-page">Blog</li>
						</ul> */}
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
            </div>

        </>

    )
}

export default IGNITECommunityMainheading;
