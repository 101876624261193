function FooterPrivacy() {
    return (
        <>

            <section className="footer_privacy_main_page mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-md-6 mt-200 sm-mt-100">
                            <h1 className="hero-heading">Your Privacy is our Priority</h1>
                            <p className="text-lg mb-50 lg-mb-40 text-dark">Your key to access the world of crypto.
                            </p>
                            <ul className="style-none button-group d-lg-flex align-items-center footer_wallet_button_group">
                                <li className="me-1"><a target="_blank" href="https://wallet.ignite.info/" className="ripple-btn px-4 footer_wallet_button">Web Wallet</a></li>&nbsp;&nbsp;
                                <li className="me-2"><a className=" ripple-btn px-3 footer_wallet_button">Mobile Wallet</a></li>
                                {/* <li class="help-btn">Need any help? <a href="contact-us.html">Contact us</a></li> */}
                            </ul>
                        </div>

                        <div className="illustration-holder sm-mt-50">
                            <img src="images/assets/A-3.png" alt="" className="main-illustration transform-img-meta ms-auto is-out" style={{ transform: 'perspective(700px) rotateX(1.89344deg) rotateY(5.35438deg)' }} />
                            {/* <img ssrc="images/shape/shape_19.png" alt="" class="shapes shape-one"> */}
                        </div>

                    </div>
                </div>
            </section>

        </>



    )
}

export default FooterPrivacy;