import Footer from "../footer";
import Header from "../header";

function PrivacyPolicy() {
  document.title="Privacy Policy | IGNITE Blockchain";
  return (
    <>
    <Header/>
      <div className="main-page-wrapper">
      <div className="theme-inner-banner">
        <div className="container">
          <h2 className="intro-title text-center">Privacy Policy</h2>
          <ul className="page-breadcrumb style-none d-flex justify-content-center">
            <li><a href="Ignite.html">Home</a></li>
            <li className="current-page">Privacy Policy</li>
          </ul>
        </div>
        <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
      </div>
      <div className="fancy-feature-two position-relative mt-140 lg-mt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="card" style={{padding: '30px', border: 'none'}}>
              <h4 className="mt-4"><strong>User Account and Verification</strong></h4>
              <p>User may be asked to register on the Website to access or avail certain products and services
                on the Website. To register a new user account, User need to provide Their personal
                information including but not limited to their full name, email address and contact
                information ("Acount"). They must agree and authorize us to, directly or indirectly (via a
                third party), undertake the necessary checks and inquiries concerning the accuracy and
                truthfulness of the information provided. For using some features of the website theymay be
                asked to go through the know your customer ("KYC") process. On completion of the know your
                customer ("KYC") process, they may be provided with "Account Information" to enable them to
                access their Account, which shall include a user identification code, password and/or any
                other piece of information. If they register on the Website by logging into any third-party
                websites like Facebook, LinkedIn, Twitter or Gmail, the login information of such
                third-party website, as the case may be, such details shall be considered to be a part of
                the Account Information.</p>
              <h4><strong>Log Data</strong></h4>
              <p>Like many site operators, we collect information that your browser sends whenever you visit
                our Site ("Log Data"). This Log Data may include information such as your computer's
                Internet Protocol ("IP") address (with replaced last byte), browser type, browser version,
                the pages of our site that you visit, the time and date of your visit, the time spent on
                those pages and other statistics.</p>
              <h4><strong>How do we use your information?</strong></h4>
              <p>We process your information for purposes based on legitimate business interests, the
                fulfilment of our contract with you, compliance with our legal obligations, and/or your
                consent.</p>
              <p>We use personal information collected via our Website for a variety of business purposes
                described below. We process your personal information for these purposes in reliance on our
                legitimate business interests, to enter into or perform a contract with you, with your
                consent, and/or for compliance with our legal obligations. We indicate the specific
                processing grounds. we rely on next to each purpose listed below.</p>
              <h5><strong>We use the information we collect or receive:</strong></h5>
              <ul>
                <li>To enforce our terms, conditions and policies for business purposes, to comply with
                  legal and regulatory requirements or in connection with our contract.</li>
                <li>To respond to legal requests and prevent harm. If we receive a subpoena or other legal
                  request, we may need to inspect the data we hold to determine how to respond.</li>
                <li>
                  <p>To send you marketing and promotional communications. We and/or our third-party
                    marketing partners may use the personal information you send to us for our marketing
                    purposes if this is under your marketing preferences. For example, when expressing
                    an interest in obtaining information about us or our Website, subscribing to
                    marketing or otherwise contacting us, we will collect personal information from you.
                  </p><br />
                </li></ul>
              <p>You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS"
                below).
                Deliver targeted advertising to you.</p><br />
              <p>We may use your information to develop and display personalized content and advertising (and
                work with third parties who do so) tailored to your interests and/or location and to measure
                its effectiveness.</p>
              <p>For other business purposes: We may use your information for other business purposes, such as
                data analysis, identifying usage trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Website, products, marketing and your experience.
                We may use and store this information in aggregated and anonymized form so that it is not
                associated with individual end-users and does not include personal information. We will not
                use identifiable personal information without your consent. This all policy and the
                transactions are subject to market risk.</p>
              <h4><strong>Cookies Policy</strong></h4>
              <p>Our websites use cookies to distinguish each and everyone from other users of our websites.
                This helps us to provide them with exemplary experience when they Visit our websites and
                also allows us to improve our sites. By continuing to use the site, Users are agreeing to
                our use of cookies, and the terms of this policy ("Cookie Policy"). A cookie is a small file
                of letters and numbers that we store on User's browser or the hard drive of their computer
                if they agree. Cookies contain information that is transferred to their computer's hard
                drive.</p>
              <h5><strong>We use the following cookies:</strong></h5>
              <ul>
                <li>Strictly necessary cookies: These are cookies that are required for the operation of our
                  websites. They include, for example, cookies that enable you to log into secure areas of
                  our website.</li>
                <li>Analytical/performance cookies: They allow us to recognise and count the number of
                  visitors and to see how visitors move around our websites when they are using it. This
                  helps us to improve the way our websites work, for example, by ensuring that users are
                  finding what they are looking for easily.</li>
                <li>Functionality cookies: These cookies are used to recognise you when you return to our
                  websites. They enable us to personalise our content for you, greet you by name and
                  remember your preferences (for example, your choice of language or region).</li>
                <li>Targeting cookies: These cookies record your visit to our websites, the pages you have
                  visited and the links you have followed.</li>
              </ul>
              <p>Except for essential cookies, all cookies will expire after two years.</p>
              <h4><strong>Social Media</strong></h4>
              <p>We use plug-ins from social networks such as Blog, YouTube, Reddit, Twitter, Facebook,
                Meetups on our websites. When you activate them (by clicking on them), the operators of the
                respective social networks may record that you are on our websites and may use this
                information. This processing of your personal data lays in the responsibility of these
                individual social media platforms and occurs according to their privacy policy. Please check
                with these individual social media platforms regarding their privacy policies. Aarma is not
                responsible for data collected by these individual social media platforms. We only use these
                platforms to inform our community of each updates and answer user every questions.</p>
              <h5><strong>Other parties who have access to information we collect</strong></h5>
              <p>Here, With the exception of the provider of our website, we do not make users personal data
                available to third parties unless user has expressly consented to it, if we are legally
                obligated to, or if this is necessary to enforce our rights concerning a contractual
                relationship with user.</p>
              <p>The websites service provider to whom the personal data collected via the websites is passed
                on or user who has or can have access to Web Services Inc. The websites is hosted on
                servers. The transfer of data is for purpose of providing and maintaining the functionality
                of our websites. This is our legitimate interest in the sense of Art.</p>
              <p>If user pay by credit card through the website, we forward their credit card information to
                the credit card issuer and the credit card acquirer. If user choose to pay by credit card,
                user will be asked to provide all the necessary information. The legal basis for passing on
                the data lies in the fulfilment of an agreement in all the sense.</p>
              <h4><strong>Data security</strong></h4>
              <p>Our team use appropriate technical and organizational security measures to protect user's
                stored personal data against manipulation, partial or complete loss, and unauthorized access
                by third parties. Our security measures are continuously being improved in line with
                technical developments.</p>
              <p>Please note that any data transmission on the Internet (e.g. communication by e-mail) is
                generally not secure and we accept no liability for data transmitted to us via the Internet.
                Unfortunately, absolute protection is not technically possible.</p>
              <p>This information does not apply to the websites of any third parties and the corresponding
                links given on our websites. The Foundation assumes no responsibility and liability to any
                of these.</p>
              <h4><strong>Changes to the conditions and privacy policy</strong></h4>
              <p>We may update this privacy policy from time to time. We will notify you of any changes by
                posting the new privacy policy on the Site. You are advised to review this privacy policy
                periodically for any changes.</p>
              <p>Aarma token is just providing payment gateways to various merchandise and it is very
                transparent relation with both the merchant and clients/users /customers.</p>
              <p>Here by we are not taking any kind of guarantee of any of the third party company or other
                companies we are dealing with.</p>
              <p>Kindly please read terms and conditions carefully before using our website.</p>
              <h4><strong>Contact us</strong></h4>
              <p>If you have any questions about our privacy policy, or how your data is being collected and
                processed, please contact<br />
                <strong>info@ignitechain.network</strong>
              </p>
            </div>
          </div>
        </div> 
      </div>
      </div>
      <Footer/>
    </>
  );
}

export default PrivacyPolicy;