function IgtSolutionsDescriptionSolution() {
    return (
        <>

            <div className="pr-details-one  pt-50 mt-20 lg-mt-90 mb-90 lg-mb-100">
                <div className="container">
                    <div className="col-9">
                        <h2 className="fs-2 fw-bolder text-start">The solution</h2>
                        <p className="pb-25 line-height">Blockchains provide maximum transparency and very low costs through
                            decentralization.</p>
                    </div>
                    <div className="wrapper ps-xxl-4 pe-xxl-4 ms-xxl-5 me-xxl-5 bg-color">
                        <div className="row gx-xxl-5 pb-100">
                            <div className="col-lg-9 border-end">
                                <div className="title-style-five">
                                    <h2 className="main-title fs-4">Trustless and transparent</h2>
                                    <p className="pt-10 line-height">Blockchains increase data availability by acting as a
                                        single source of truth. All involved parties access data from a single ledger,
                                        minimizing reconciliation errors; the increased transparency allows for more precise
                                        risk management and offers a better client experience.</p>
                                </div>
                            </div>
                            {/* <div class="col-lg-6 border-end">
								<div class=" col-10 text-start ">
								</div>
							</div> */}
                            <div className="col-lg-3">
                                <ul className=" list-unstyled">
                                    <li><i className="fa fa-check-circle">&nbsp; Single source of truth</i></li>
                                    <li><i className="fa fa-check-circle">&nbsp; No reconciliation errors</i></li>
                                    <li><i className="fa fa-check-circle">&nbsp; Better investor experience</i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gx-xxl-5 pb-100">
                            <div className="col-lg-9 border-end">
                                <div className="title-style-five">
                                    <h2 className="main-title fs-4">Trustless and transparent</h2>
                                    <p className="pt-10 line-height">Blockchains increase data availability by acting as a
                                        single source of truth. All involved parties access data from a single ledger,
                                        minimizing reconciliation errors; the increased transparency allows for more precise
                                        risk management and offers a better client experience.</p>
                                </div>
                            </div>
                            {/* <div class="col-lg-6 border-end">
								<div class=" col-10 text-start ">
								</div>
							</div> */}
                            <div className="col-lg-3">
                                <ul className=" list-unstyled">
                                    <li><i className="fa fa-check-circle">&nbsp; Single source of truth</i></li>
                                    <li><i className="fa fa-check-circle">&nbsp; No reconciliation errors</i></li>
                                    <li><i className="fa fa-check-circle">&nbsp; Better investor experience</i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gx-xxl-5 pb-100">
                            <div className="col-lg-9 border-end">
                                <div className="title-style-five">
                                    <h2 className="main-title fs-4">Trustless and transparent</h2>
                                    <p className="pt-10 line-height">Blockchains increase data availability by acting as a
                                        single source of truth. All involved parties access data from a single ledger,
                                        minimizing reconciliation errors; the increased transparency allows for more precise
                                        risk management and offers a better client experience.</p>
                                </div>
                            </div>
                            {/* <div class="col-lg-6 border-end">
								<div class=" col-10 text-start ">
								</div>
							</div> */}
                            <div className="col-lg-3">
                                <ul className=" list-unstyled">
                                    <li><i className="fa fa-check-circle">&nbsp; Single source of truth</i></li>
                                    <li><i className="fa fa-check-circle">&nbsp; No reconciliation errors</i></li>
                                    <li><i className="fa fa-check-circle">&nbsp; Better investor experience</i></li>
                                </ul>
                            </div>
                        </div>
                    </div> {/* /.wrapper */}
                </div>
            </div>


        </>

    )
}

export default IgtSolutionsDescriptionSolution;