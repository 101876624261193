import Header from ".././header/index";
import IGNITECommunityMainheading from "./ignitemainheading";
import IGNITECommunityjoinpage from "./ignitejoinpage";
import Footer from '.././footer/index';

function IGNITECommunity() {

    document.title="Ignite-Community - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <IGNITECommunityMainheading />
                <IGNITECommunityjoinpage/>
                <Footer />

            </div>


        </div>
    );
}
export default IGNITECommunity;