import { Link, useLocation } from "react-router-dom";
// import { } from "react-router-dom";


function Header() {

  var location = useLocation();
  console.log(location);
  return (

    <>
      {/* <div className="text-white text-center" style={{ background: "#001944" }} id="scroll_top">
        This is the message from Ignite
      </div> */}
      {/* <header className="theme-main-menu sticky-menu  theme-menu-two sm-mt-5 mt-md-3 mt-lg-0"> */}
      <header className="theme-main-menu sticky-menu  theme-menu-two  mt-lg-0">
        <div className="text-white text-center header_heading pb-5" style={{ background: "#001944", fontSize: "14px", overflow: "hidden" }} id="scroll_top">
          <a>BNB Chain smart contract address for IGT is <a target="_blank" href="https://bscscan.com/address/0x3Bed11BB72F11FA734d5286537829E0C7C950d03">0x3Bed11BB72F11FA734d5286537829E0C7C950d03</a> </a>
        </div>
        <div className="inner-content">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="logo order-lg-0"> <a href="/" className="d-block"><img src="images/logo/logo_01.png" alt="" width={250} /></a></div>
            {/* <button className="get-in-touch-btn d-none d-lg-block order-lg-3  rounded-pill"> #BuildwithIgnite</button> */}

            {/* <button className="get-in-touch-btn d-none d-lg-block order-lg-3  rounded-pill" data-bs-toggle="modal" data-bs-target="#exampleModal"> #BuildwithIgnite</button> */}

            <a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/api" className="get-in-touch-btn d-none d-lg-block order-lg-3 px-3  rounded-pill"> #BuildwithIgnite</a>


            <nav className="navbar navbar-expand-lg order-lg-2">
              <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="d-block d-lg-none"><div className="logo"><a href="index-2.html"><img src="images/logo/logo_01.png" alt="" width={130} /></a></div></li>
                  <li className={location.pathname === "/research" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/research"> Research</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://ignitechain.gitbook.io/ignitechain-docs/api" role="button">Developer</a>
                  </li>
                  <li className={location.pathname === "/enterprise" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/enterprise">Enterprise</Link>
                  </li>
                  <li className={location.pathname === "/network" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/network"> Network</Link>
                    {/* <a  className="nav-link dropdown-toggle" href="/network" >Network</a> */}
                  </li>
                  <li className={location.pathname === "/ignite_studio" ? "nav-item active" : "nav-item"}>
                    {/* <Link className="nav-link" to="/ignite_studio"> Ignite Studio</Link> */}
                    {/* <Link className="nav-link" to="/ignite_studio"> Ignite Studio</Link> */}
                    <a className="nav-link">Ignite Studio</a>
                  </li>
                </ul>
                {/* Mobile Content */}
                <div className="mobile-content d-block d-lg-none">
                  <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                    <a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/api" className="get-in-touch-btn rounded-pill">#BuildwithIgnite</a>
                  </div>
                </div> {/* /.mobile-content */}
              </div>
            </nav>
          </div>
        </div> {/* /.inner-content */}
      </header>

      {/* Modal */}
      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              {/* <button type="button text-right" className="btn-close" data-bs-dismiss="modal" aria-label="Close" /> */}
              <img src="./images/coming-soon.jpg" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </>





  );
}

export default Header;