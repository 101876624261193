function Datasolution() {
    return (
        <>

<div className="fancy-feature-two position-relative  lg-mt-120">
  <div className="container">
    <div className="row">
      <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
        <div className="block-style-two" data-aos="fade-left">
          <div className="title-style-one pt-50">
            {/* <div className="sc-title">Over 150k+ Client</div> */}
            <h2 className="main-title">Why you should choose Ignite Chain.</h2>
          </div> {/* /.title-style-one */}
          <p className="pt-10 pb-20 lg-pb-10">Ignite Chain is a third generation blockchain created to provide deep-tech solutions to businesses and enterprises.</p>
          <ul className="style-none list-item">
            <li>Low Cost .</li>
            <li>High Performance.</li>
            <li>Secure Mechanism .</li>
          </ul>
          {/* <div className="btn-three mt-45 lg-mt-30">Want to learn more about us? <a href="about-us1.html">Click here <i className="fas fa-chevron-right" /></a></div> */}
        </div> {/* /.block-style-two */}
      </div>
    </div>
  </div> {/* /.container */}
  <div className="illustration-holder sm-mt-20">
  <img src="images/assets/Why_igt_image.png" alt="" className="main-illustration w-100" />
    {/* <img src="images/assets/ils_02.svg" alt="" className="main-illustration w-100" />
    <img src="images/assets/ils_02_1.svg" alt="" className="shapes shape-one" />
    <img src="images/assets/ils_02_1.svg" alt="" className="shapes shape-two" />
    <img src="images/assets/ils_02_1.svg" alt="" className="shapes shape-three" />
    <img src="images/assets/ils_02_2.svg" alt="" className="shapes shape-four" data-aos="fade-up" data-aos-anchor=".fancy-feature-two" data-aos-delay={100} data-aos-duration={1500} />
    <img src="images/assets/ils_02_2.svg" alt="" className="shapes shape-five" data-aos="fade-down" data-aos-anchor=".fancy-feature-two" data-aos-delay={200} data-aos-duration={1500} />
    <img src="images/assets/ils_02_3.svg" alt="" className="shapes shape-six" data-aos="fade-down" data-aos-anchor=".fancy-feature-two" data-aos-delay={100} data-aos-duration={1500} />
    <img src="images/assets/ils_02_4.svg" alt="" className="shapes shape-seven" data-aos="fade-up" data-aos-anchor=".fancy-feature-two" data-aos-delay={250} data-aos-duration={1500} /> */}
  </div> {/* /.illustration-holder */}
</div>


        </>

        )
    }
    
    export default Datasolution;