function Careerpagemaininformation() {
    return (
        <>

            {/* career */}
            <div className="fancy-feature-thirteen position-relative mt-100 pb-20 lg-pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up">
                            <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_24.svg" alt="" /></div> */}
                                <h6><a target="_blank" href="/relationshipmanager">Relationship Manager</a></h6>
                                <p>Exprience : 2-4 Years
                                    <br />Vacancy : 5 </p>
                                <a target="_blank" href="/relationshipmanager" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                            </div> {/* /.block-style-ten */}
                        </div>
                        <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={100}>
                            <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_25.svg" alt="" /></div> */}
                                <h6><a target="_blank" href="/salesexecutive">Sales Executive</a></h6>
                                <p>Exprience : 0-2 Years
                                    <br />Vacancy : 15 </p>
                                <a target="_blank" href="/salesexecutive" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                            </div> {/* /.block-style-ten */}
                        </div>
                        <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={200}>
                            <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_26.svg" alt="" /></div> */}
                                <h6><a target="_blank" href="/internationalbusinessmarketer">International Business Marketer</a></h6>
                                <p>Exprience : 0-6 Months
                                    <br />Vacancy : 15 </p>
                                <a target="_blank" href="/internationalbusinessmarketer" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                            </div> {/* /.block-style-ten */}
                        </div>
                        <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={300}>
                            <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_27.svg" alt="" /></div> */}
                                <h6><a target="_blank" href="/growthmarketer">Growth Marketer</a></h6>
                                <p>Exprience : 0 Months
                                    <br />Vacancy : 10 </p>
                                <a target="_blank" href="/growthmarketer" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                            </div> {/* /.block-style-ten */}
                        </div>



                        <div className="row justify-content-center mt-2">


                            <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up">
                                <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                    {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_24.svg" alt="" /></div> */}
                                    <h6><a target="_blank" href="/mobileapplicationdeveloper">Mobile Application Developer </a></h6>
                                    <p>Exprience : 2-4 Years
                                        <br />Vacancy : 5 </p>
                                    <a target="_blank" href="/mobileapplicationdeveloper" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                                </div> {/* /.block-style-ten */}
                            </div>
                            <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={100}>
                                <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                    {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_25.svg" alt="" /></div> */}
                                    <h6><a target="_blank" href="/blockchaindeveloper">Block chain Developer</a></h6>
                                    <p>Exprience : 0-2 Years
                                        <br />Vacancy : 15 </p>
                                    <a target="_blank" href="/blockchaindeveloper" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                                </div> {/* /.block-style-ten */}
                            </div>
                            <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={200}>
                                <div className="block-style-ten tran3s mt-30 ignite_feature_box">
                                    {/* <div className="icon d-flex align-items-end"><img src="images/icon/icon_26.svg" alt="" /></div> */}
                                    <h6><a target="_blank" href="/nodejsdeveloper">Node.js Developer</a></h6>
                                    <p>Exprience : 0-6 Months
                                        <br />Vacancy : 15 </p>
                                    <a target="_blank" href="/nodejsdeveloper" className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                                </div> {/* /.block-style-ten */}
                            </div>
                            
                        </div>



                        {/* <div className="col-xl-3 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={300}>
                            <div className="block-style-ten tran3s mt-30">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_27.svg" alt="" /></div>
                                <h6><a >Data Analysis</a></h6>
                                <p>Exprience : 0 Months
                                    <br />Vacancy : 10 </p>
                                <a className="read-btn tran3s"><i className="bi bi-arrow-right" /></a>
                            </div>
                        </div> */}
                    </div>
                </div> {/* /.container */}
                {/* <img src="images/shape/shape_31.svg" alt="" class="shapes shape-one"> */}
            </div>
            {/* end career */}


        </>

    )
}

export default Careerpagemaininformation;