function IGNITETransactionid({totalTransactions , totalBlocks}) {
    return (
        <>


            <div className="counter-section-one fancy-bg pt-100">
                <div className="container">
                    <div className="inner-container-two">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-4 aos-init aos-animate" data-aos="fade-up">
                                <div className="counter-block-two text-center mb-20">
                                    {/* <div className="main-count-two"><span className="counter">2493</span></div> */}
                                    <div className="main-count-two"><span className="counter">2608</span></div>
                                    <p>TRANSACTIONS PER SECOND
                                    </p>
                                </div> {/* /.counter-block-one */}
                            </div>

                            <div className="col-md-4 col-sm-4 aos-init aos-animate" data-aos="fade-up">
                                <div className="counter-block-two text-center mb-20">
                                    {/* <div className="main-count-two"><span className="counter">2493</span></div> */}
                                    <div className="main-count-two"><span className="counter">{totalBlocks}</span></div>
                                    <p>TOTAL BLOCKS
                                    </p>
                                </div> {/* /.counter-block-one */}
                            </div>


                            <div className="col-md-4 col-sm-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                                <div className="counter-block-two text-center mb-20">
                                    <div className="main-count-two"><span className="counter">{totalTransactions}</span></div>
                                    {/* <p>TOTAL TRANSACTIONS
                                    </p> */}
                                    <p>TOTAL TRANSACTIONS
                                    </p>


                                </div> {/* /.counter-block-one */}
                            </div>
                            {/* <div class="col-md-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
								<div class="counter-block-two text-center mb-20">
									<div class="main-count-two"><span class="counter">15</span>k</div>
									<p>TOTAL BLOCKS
									</p>
								</div> 
							</div> */}
                        </div>
                    </div> {/* /.inner-container */}
                </div>
                {/* <img src="images/shape/shape_04.svg" alt="" class="shapes shape-one">
				<img src="images/shape/shape_05.svg" alt="" class="shapes shape-two"> */}
            </div>


        </>





    )
}

export default IGNITETransactionid;