function DelegatorListTable() {
    return (
        <>

            <div className="fancy-feature-thirteen position-relative mt-100 pb-60 lg-pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex aos-init aos-animate mt-3" data-aos="fade-up">
                            <div className="block-style-ten tran3s mt-0 py-4">
                                <div className="col-12 px-4">
                                    <table id="example" className="table table-striped" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>sr no</th>
                                                <th>Delegator address</th>
                                                <th>Withdraw</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Tiger Nixon</td>
                                                <td>System Architect</td>
                                                <td>Edinburgh</td>
                                            </tr>
                                            <tr>
                                                <td>Garrett Winters</td>
                                                <td>Accountant</td>
                                                <td>Tokyo</td>
                                            </tr>
                                            <tr>
                                                <td>Ashton Cox</td>
                                                <td>Junior Technical Author</td>
                                                <td>San Francisco</td>
                                            </tr>
                                            <tr>
                                                <td>Cedric Kelly</td>
                                                <td>Senior Javascript Developer</td>
                                                <td>Edinburgh</td>
                                            </tr>
                                            <tr>
                                                <td>Airi Satou</td>
                                                <td>Accountant</td>
                                                <td>Tokyo</td>
                                            </tr>
                                            <tr>
                                                <td>Brielle Williamson</td>
                                                <td>Integration Specialist</td>
                                                <td>New York</td>
                                            </tr>
                                            <tr>
                                                <td>Herrod Chandler</td>
                                                <td>Sales Assistant</td>
                                                <td>San Francisco</td>
                                            </tr>
                                            <tr>
                                                <td>Rhona Davidson</td>
                                                <td>Integration Specialist</td>
                                                <td>Tokyo</td>
                                            </tr>
                                            <tr>
                                                <td>Colleen Hurst</td>
                                                <td>Javascript Developer</td>
                                                <td>San Francisco</td>
                                            </tr>
                                            <tr>
                                                <td>Sonya Frost</td>
                                                <td>Software Engineer</td>
                                                <td>Edinburgh</td>
                                            </tr>
                                            <tr>
                                                <td>Jena Gaines</td>
                                                <td>Office Manager</td>
                                                <td>London</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> {/* /.block-style-ten */}
                            </div>
                        </div>
                    </div> {/* /.container */}
                </div>
             
                
               
            </div>


        </>

    )
}

export default DelegatorListTable;