
function Inquiry() {
  return (
    <>



      <div className="blog-section-three pt-90 pb-90 lg-pt-40 lg-mb-100">
        <div className="container">
          <div className="title-style-three text-center mb-50 lg-mb-20" data-aos="fade-up">
            <div className="sc-title">supported community  </div>
            <h2 className="main-title">Join the fastest-growing ecosystem in crypto across DeFi, Web3, and NFTs  <span></span></h2>
          </div>
          <div className="row gx-xxl-5">
            <div className="col-lg-12">
              <div id="isotop-gallery-wrapper" className="grid-2column" style={{ position: 'relative', height: '2262.16px' }}>
                <div className="grid-sizer" />
                <div className="isotop-item  d-flex " style={{ position: 'absolute', left: '0%', top: '0px' }}>
                  <article className="blog-meta-two gallary_image mt-45">

                    <div className="post-data px-3">

                      <a target="_blank" href="/ignitecommunity" className="blog-title"><h5>Ignite Community</h5></a><br></br>
                      <a target="_blank" href="/ignitecommunity" className="read-btn tran3s messonary_image_info">It's a vibrant global community powered by IgniteChain. Ignite empowers community members to work together and create valuable IgniteHub. </a>
                    </div> {/* /.post-data */}
                    <figure className="post-img m0"><a target="_blank" href="/ignitecommunity" className="w-100 d-block"><img src="images/blog/b_one.webp" alt="" className="w-100 tran4s" /></a></figure>
                  </article>
                </div>
                <div className="isotop-item  d-flex" style={{ position: 'absolute', left: '50%', top: '0px' }}>
                  <article className="blog-meta-two gallary_image mt-45">

                    <div className="post-data px-3">

                      <a target="_blank" href="/becomeavalidator" className="blog-title"><h5>Become a Validator</h5></a>
                      <a target="_blank" href="/becomeavalidator" className="read-btn tran3s messonary_image_info">Validators form the Backbone of Ignite network. By processing transactions and participating in consensus, apiece validator helps make it great Ignite blockchain network globally. </a>
                    </div> {/* /.post-data */}
                    <figure className="post-img m0"><a target="_blank" href="/becomeavalidator" className="w-100 d-block"><img src="images/blog/bl_two.webp" alt="" className="w-100 tran4s" /></a></figure>

                  </article>
                </div>
                <div className="isotop-item  d-flex mt-2" style={{ position: 'absolute', left: '0%', top: '671px' }}>
                  <article className="blog-meta-two gallary_image mt-45">
                    <div className="post-data px-3 ">

                      <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/api" className="blog-title"><h5>Developer Resources</h5></a>
                      <a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/api" className="read-btn tran3s messonary_image_info">Welcome to the most exciting and innovative platform This documentation provides Developer Resources details on the Ignite technology. </a>
                    </div>
                    <figure className="post-img m0"><a target="_blank" href="https://ignitechain.gitbook.io/ignite-chain-docs/api" className="w-100 d-block"><img src="images/blog/bl0_04.webp" alt="" className="w-100 tran4s" /></a></figure>{/* /.post-data */}
                  </article>
                </div>
                <div className="isotop-item  d-flex mt-2" style={{ position: 'absolute', left: '50%', top: '795px' }}>
                  <article className="blog-meta-two gallary_image mt-45">

                    <div className="post-data px-3">

                      <a target="_blank" href="/ignitecoin" className="blog-title"><h5>Learn about Ignite</h5></a>
                      <a target="_blank" href="/ignitecoin" className="read-btn tran3s ">Learn more about IgniteChain and see what makes it great.</a><br></br>
                      <span className="messonary_learn_info">Learn more about IgniteChain and see what makes it great</span>
                      
                      {/* <p className="messonary_learn_info">Learn more about IgniteChain and see what makes it great</p> */}
                    </div> {/* /.post-data */}
                    <figure className="post-img m0"><a target="_blank" href="/ignitecoin" className="w-100 d-block"><img src="images/blog/b_three_1.webp" alt="" className="w-100 tran4s" /></a></figure>

                  </article>
                </div>


              </div> {/* /.row */}

            </div>
          </div>
        </div> {/* /.container */}
      </div>































      {/* <div className="blog-section-three position-relative pt-70 lg-pt-40">
        <div className="container">
          <div className="title-style-three text-center mb-50 lg-mb-20" data-aos="fade-up">
            <div className="sc-title">RECENT NEWS</div>
            <h2 className="main-title">Inside Story &amp; <span>Blog</span></h2>
          </div>
          <div className="row gx-xxl-5  ">
            <div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up">
              <article className="blog-meta-one  blog_image_first  color-two tran3s mt-30">
                <div className="post-data">
                  <div className=" h3 fw-bold"><a href="blog-details.html"> Ignite Community</a> 
                  </div>
                  <a href="blog-details.html" className="blog-title">
                    <h6>Make more daily productive working flow like pro.</h6>
                  </a>
                </div>
                <figure className="post-img m0">
                  <a href="blog-details.html" className="w-100 d-block">
                    <img src="images/blog/igt-icon-1-1.png" alt="" className="w-100 tran4s first_blog_image" />
                  </a>
                </figure>
              </article>
            </div>
            <div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={100}>
              <article className="blog-meta-one blog_image_second color-two tran3s mt-30">
                <div className="post-data">
                  <div className=" h3 fw-bold"><a href="blog-details.html">Become a Validator</a>
                  </div>
                  <a href="blog-details.html" className="blog-title">
                    <h6>Internet of the things and why it’s becoming important in the century.</h6>
                  </a>
                </div>
                <figure className="post-img m0">
                  <a href="blog-details.html" className="w-100 d-block">
                    <img src="images/blog/blog_img_02.jpg" alt="" className="w-100 tran4s second_blog_image" />
                  </a>
                </figure>
              </article>
            </div>
            <div className="col-lg-6 col-sm-6 d-flex mt-4" data-aos="fade-up" data-aos-delay={100}>
              <article className="blog-meta-one blog_image_third color-two tran3s mt-30">
                <div className="post-data">
                  <div className=" h3 fw-bold"><a href="blog-details.html">Developer Resources</a>
                  </div>
                  <a href="blog-details.html" className="blog-title">
                    <h6>Internet of the things and why it’s becoming important in the century.</h6>
                  </a>
                </div>
                <figure className="post-img m0">
                  <a href="blog-details.html" className="w-100 d-block">
                    <img src="images/blog/blog_img_02.jpg" alt="" className="w-100 tran4s third_blog_image" />
                  </a>
                </figure>
              </article>
            </div>
            <div className="col-lg-6 col-sm-6 d-flex mt-4" data-aos="fade-up">
              <article className="blog-meta-one blog_image_fourth color-two tran3s mt-30">
                <div className="post-data">
                  <div className=" h3 fw-bold"><a href="blog-details.html">Learn about Ignite</a>
                  </div>
                  <a href="blog-details.html" className="blog-title">
                    <h6>Make more daily productive working flow like pro.</h6>
                  </a>
                </div>
                <figure className="post-img m0">
                  <a href="blog-details.html" className="w-100 d-block">
                    <img src="images/blog/blog_img.jpg" alt="" className="w-100 tran4s  fourth_blog_image" />
                  </a>
                </figure>

              </article>
            </div>
            
            <div className="col-lg-4 col-sm-6 d-lg-none d-flex" data-aos="fade-up">
              <article className="blog-meta-one color-two tran3s mt-30">
                <figure className="post-img m0"><a href="blog-details.html" className="w-100 d-block"><img src="images/blog/blog_img_01.jpg" alt="" className="w-100 tran4s" /></a></figure>
                <div className="post-data">
                  <div className="post-tag"><a href="blog-details.html">Data Science</a> - 15 minute read
                  </div>
                  <a href="blog-details.html" className="blog-title">
                    <h5>Make more daily productive working flow like pro.</h5>
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div> */}








      {/* <div className="fancy-short-banner-one position-relative mt-160 lg-mt-100 md-mt-80">
        <div className="container ">
          <div className="row">
            <div className="col-6">
              <div className="bg-wrapper ">
                <div className="row align-items-center gx-xxl-5">
                  <div className="col-lg-12 text-center text-lg-start" data-aos="fade-right">
                    <div className="sub-title h2">Ignite Community</div>
                    <h4 className="pe-xl-5 md-pb-20">Ignite Community is spreading into the crypto market</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="bg-wrapper ">
                <div className="row align-items-center gx-xxl-5">
                  <div className="col-lg-12 text-center text-lg-start" data-aos="fade-right">
                    <div className="sub-title h2">Ignite Community</div>
                    <h4 className="pe-xl-5 md-pb-20">Ignite Community is spreading into the crypto market</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">

          <div className="col-12">
          <div className="bg-wrapper ">
            <div className="row align-items-center gx-xxl-5">
              <div className="col-lg-12 text-center text-lg-start" data-aos="fade-right">
                <div className="sub-title h2">Ignite Community</div>
                <h4 className="pe-xl-5 md-pb-20">Ignite Community is spreading into the crypto market</h4>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>

      <div className="fancy-short-banner-one position-relative mt-160 lg-mt-100 md-mt-80">
        <div className="container">
          <div className="bg-wrapper">
            <div className="row align-items-center gx-xxl-5">
              <div className="col-lg-6 text-center text-lg-start" data-aos="fade-right">
                <div className="sub-title">Work Inquiry</div>
                <h3 className="pe-xl-5 md-pb-20">Having Any Query! Book an appointment.</h3>
              </div>
              <div className="col-lg-6 text-center text-lg-end" data-aos="fade-left">
                <a href="contact-us.html" className="msg-btn tran3s">Send Us Message</a>
              </div>
            </div>
          </div> 
        </div> 
        <img src="images/shape/shape_10.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/shape_11.svg" alt="" className="shapes shape-two" />
      </div> */}

    </>
  );
}

export default Inquiry;