function FooterIGNITEWallet() {
    return (
        <>

            <div className="fancy-feature-ten mt-190 lg-mt-110">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="block-style-two md-mb-50" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="d-flex my-auto">
                                        <img src="images/logo/51.png" alt="" />
                                    </div>
                                    {/* <div class="sc-title-four">App Integration</div> */}
                                    <h2 className="fs-3 fw-bold mt-3">Welcome to IGNITE wallet</h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-2 pb-30 lg-pt-20 lg-pb-10 small">IGNITE wallet is a free, client-side interface
                                    helping you interact with ignite. Our easy-to-use, open-source platform allows you to
                                    generate wallets and so much more.</p>
                                {/* <div class="btn-three color-three">How to integrate? <a href="about-us1.html">Click here <i class="fas fa-chevron-right"></i></a></div> */}
                            </div> {/* /.block-style-two */}
                            <div className="block-style-two md-mb-50" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="d-flex my-auto">
                                        {/* <img src="images/logo/key-icon.png" height="65px" width="56px" alt="" /> */}
                                        <img src="images/logo/key-icon.png"  alt="" />
                                    </div>
                                    {/* <div class="sc-title-four">App Integration</div> */}
                                    <h2 className="fs-3 fw-bold mt-3">Manage all your crypto assets with a single private key
                                    </h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-2 pb-30 lg-pt-20 lg-pb-10 small">You can manage all of your tokens, Coins, and
                                    NFTs on multiple chains with the private key.</p>
                                {/* <div class="btn-three color-three">How to integrate? <a href="about-us1.html">Click here <i class="fas fa-chevron-right"></i></a></div> */}
                            </div>
                            <div className="block-style-two md-mb-50" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="d-flex my-auto">
                                        <img src="images/logo/Key.png" alt="" />
                                    </div>
                                    {/* <div class="sc-title-four">App Integration</div> */}
                                    <h2 className="fs-3 fw-bold mt-3">Your key to the world of crypto</h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-2 pb-30 lg-pt-20 lg-pb-10 small">Ignite wallet Support 1000+ tokens, Coins,
                                    NFTs, or dApps you can store all of in one place.</p>
                                {/* <div class="btn-three color-three">How to integrate? <a href="about-us1.html">Click here <i class="fas fa-chevron-right"></i></a></div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-6 ms-auto" data-aos="fade-left">
                            <div className="screen-holder-one d-flex align-items-center justify-content-center">
                            <div className=" d-flex align-items-center justify-content-center"><img src="images/logo/IGNITE-wallet.png" alt="" /></div>
                                {/* <div className="round-bg d-flex align-items-center justify-content-center" style={{ width: '200px', height: '200px' }}><img src="images/logo/Plogo-16.png" alt="" /></div> */}
                                {/* <div className="round-bg d-flex align-items-center justify-content-center shapes logo-one" style={{ width: '70px', height: '70px' }}><img src="images/logo/Plogo-17.png" alt="" /></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-two" style={{ width: '115px', height: '115px' }}><img src="images/logo/Plogo-18.png" alt="" /></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-three" style={{ width: '89px', height: '89px' }}><img src="images/logo/Plogo-19.png" alt="" /></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-four" style={{ width: '162px', height: '162px' }}><img src="images/logo/Plogo-20.png" alt="" /></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-five" style={{ width: '115px', height: '115px' }}><img src="images/logo/Plogo-21.png" alt="" /></div> */}
                            </div>
                        </div>
                    </div>
                </div> {/* /.container */}
            </div>


        </>



    )
}

export default FooterIGNITEWallet;