function BecomeavalidatorFAQS() {
    return (
        <>
        
        <div className="fancy-feature-one mt-0 lg-mt-0 sm-mt-0 pt-50 pb-10">
  <div className="container">
    <div className="d-flex justify-content-center">
      {/* <div class="col-xxl-12 col-lg-12"> */}
      <div className="block-style-five md-pb-50" data-aos="fade-right">
        <div className="title-style-one">
          <h2 className="main-title font-weight-bold">FAQs</h2>
        </div> {/* /.title-style-one */}
        {/* <p className>To know more</p> */}
        {/* <button className="btn-five px-3 rounded-pill ripple-btn">Click here</button> */}
      </div> {/* /.block-style-five */}
      {/* </div> */}
    </div>
    <div className="row">
      <div className="col-lg-12 col-lg-12 ms-auto" data-aos="fade-left">
        <div className="accordion accordion-style-one" id="accordionOne">
          <div className="accordion-item">
            <div className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                What is Ignite?
              </button>
            </div>
            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>Ignite is a network of blockchains that provides ledger services to businesses and applications. </p>
                <p>	Ignite Chain is powering the decentralized finance ecosystem not only for the individuals or end-users but for financial institutions &amp; businesses that have Digital asset exposure. Defining itself as the next generation of blockchain, Ignite provides decentralized solutions to a number of on-chain partners ranging from small enterprises to big-tech &amp; investment management funds. </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                What is a Validator?
              </button>
            </div>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>Validators are special actors in the network responsible for adding new blocks of transactions to the blockchain. </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                What is Staking?
              </button>
            </div>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>Staking is the process of locking up a digital to provide economic security for a public blockchain. </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="headingFour">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                What is the minimum amount to Stake?
              </button>
            </div>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>The minimum amount to Stake is 50 ignite. </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="headingFive">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
                How can I resign as a Masternode?
              </button>
            </div>
            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>If you no longer want to be a Masternode, you can resign on Validators. Your Masternode will stop generating rewards and your funds will be locked for 30 days (12,96,000 blocks). After the lockup period you will be able to withdraw your 50,000 Ignite deposit. </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="headingSix">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseFive">
                Who can run a Validator node?
              </button>
            </div>
            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>Anyone! </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="headingSeven">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseFive">
                How do I run a Validator node?
              </button>
            </div>
            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <p>To get started, check out our docs on the technical requirements(Link) and steps to start your own node. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div class="mt-80 pt-80 pb-40 mb-10 lg-mt-50 lg-pt-50 lg-pb-20 lg-mb-20 border-top border-bottom">
						<div class="row gx-xxl-5">
							<div class="col-lg-6">
								<div class="d-block">
									<img src="images/gallery/web3.avif" height="56px" width="56px" alt="">
									<h4 class="sub-title font-weight-bold">Help us build future of Web3</h4>
									<p>Learn more about us or see current openings to join us!</p>
									<a href="#">Learn more about us or see current openings to join us!&nbsp;<i class="bi bi-arrow-up-right font-weight-bold"></i></a>
								</div>
							</div>
							<div class="col-xl-5 col-lg-6 ms-auto">
								<div class="d-block">
									<img src="images/gallery/mail.avif" height="56px" width="56px" alt="">
									<h4 class="sub-title font-weight-bold">Be a part of our Community</h4>
									<p>Keep tabs on all-things-Polygon.</p>
									<a href="#">Explore&nbsp;<i class="bi bi-arrow-up-right font-weight-bold"></i></a>
								</div>
							</div>
						</div>
					</div> */}
  </div> {/* /.container */}
</div>


        </>


        )
    }
    
    export default BecomeavalidatorFAQS;