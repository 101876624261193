import Header from ".././header/index";
import ValidatorsDashboardSidebar from "./validatordashboardsidebar";
import ValidatorsDashboardHeading from "./validatordashboardheading";
import Footer from '.././footer/index';



function ValigatorsDashboard() {
    document.title="Validators-Daligators - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
            <Header/>
            <ValidatorsDashboardSidebar/>
            <ValidatorsDashboardHeading/>
            <Footer />     
     
            </div>


        </div>
    );
}
export default ValigatorsDashboard;