function Jobdescriptionmainpage() {
    return (
        <>


            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title  fs-2 text-center nav-link-color text-capitalize">International Business Marketer</h2>
                    {/* <p className="text-center text-white pt-25">Exprience : 2-4 Years I Vacancy : 1</p> */}
                    {/* <p className="text-center text-white pt-25">We are looking for an outgoing relationship manager to foster strong relationships with our clients.</p> */}
                    {/* <ul class="page-breadcrumb style-none d-flex justify-content-center">
							<li><a href="index-2.html">Home</a></li>
							<li class="current-page">Blog</li>
						</ul> */}
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
            </div>


        </>



    )
}

export default Jobdescriptionmainpage;