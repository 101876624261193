function Ignitestudiomainheading() {
    return (
        <>



            <div className="hero-banner-four bg-one" style={{ height: 950 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-xl-7 col-lg-8 col-md-11 m-auto ">
                            <div className="mt-180">
                                <h1 className="hero-heading text-dark">BUIDL your product on Ignite</h1>
                                <p className="mb-50 lg-mb-30 text-dark">With fast, low-cost & securest blockchain network. </p>
                            </div>
                        </div>
                    </div>
                </div> {/* /.container */}
            </div>



            {/* <div className="hero-banner-one bg-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7">
                            <a href="#" className="slogan"><strong>Offer</strong> is going on till friday, $1.99/mo. <i className="fas fa-chevron-right" /></a>
                            <h1 className="hero-heading">BUIDL your product on Ignite.</h1>
                            <p className="text-lg mb-60 lg-mb-40">With fast, low-cost & securest blockchain network</p>
                            <ul className="style-none button-group d-lg-flex align-items-center">
                                <li className="me-4"><a href="https://docs.google.com/forms/d/1DrTofQNC12gZ9nXSWwCOa6tHSoNTqXb-A_sJn86ARMM/viewform?edit_requested=true" className="btn-one ripple-btn">startup studio</a></li>
                                <li className="help-btn">Need any help? <a href="#">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="illustration-holder">

                <img src="images/assets/hero-banner-for-ignite-studio.jpg" alt="" className="main-illustration ms-auto" />
                   <img src="images/assets/ils_01.svg" alt="" className="main-illustration ms-auto" />
                    <img src="images/assets/bg_01.png" alt="" className="shapes bg-shape" />
                    <img src="images/assets/ils_01_1.svg" alt="" className="shapes shape-one" />
                    <img src="images/assets/ils_01_2.svg" alt="" className="shapes shape-two" />
                    <img src="images/assets/ils_01_3.svg" alt="" className="shapes shape-three" />
                    <img src="images/assets/ils_01_4.svg" alt="" className="shapes shape-four" />
                    <img src="images/assets/ils_01_5.svg" alt="" className="shapes shape-five" />
                    <img src="images/assets/ils_01_6.svg" alt="" className="shapes shape-six" />
                    <img src="images/assets/ils_01_7.svg" alt="" className="shapes shape-seven" /> 




                    <div className="card-one shapes">
                        <div className="icon"><i className="bi bi-check-lg" /></div>
                        <h6>Data Automation <br />Solution.</h6>
                        <span className="info">faster process</span>
                    </div> 
                    <div className="card-two shapes">
                        <div className="icon"><i className="bi bi-check-lg" /></div>
                        <h6>Data Quality Audit</h6>
                    </div> 
                            
                </div>     
            </div> */}


        </>



    )
}

export default Ignitestudiomainheading;