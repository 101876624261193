function DeveloperToolsButtons() {
    return (
        <>

            <div className="partner-section-two mt-30 mb-130 lg-mb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-auto">
                            <ul className="style-none text-center mt-40 lg-mt-10">
                                <li className="partner-logo-block-one d-inline-block aos-init aos-animate" data-aos="fade-up"><a href="#" className="d-flex align-items-center justify-content-center">Github Repository</a></li>
                                <li className="partner-logo-block-one d-inline-block aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}><a href="#" className="d-flex align-items-center justify-content-center">Developer Tools</a></li>
                            </ul>
                        </div>
                    </div>
                </div> {/* /.container */}
            </div>


        </>

    );
}
export default DeveloperToolsButtons;