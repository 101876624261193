
function IGNITEFeatures() {
    return (
        <>        
        <div className="fancy-feature-four position-relative  lg-mt-50">
        <div className="bg-wrapper">
          <div className="inner-container">
            <div className="container">

            <div className="title-style-one text-center" data-aos="fade-up">
            <div className="sc-title-three">feature</div>
          
            {/* <h2 className="main-title md">Partners and Investors </h2> */}
          </div> {/* /.title-style-one */}

            <div class="title-style-one text-center aos-init aos-animate" data-aos="fade-up">
              <h2 class="main-title md"><span className="ignite_features_main_heading"> IGNITE</span> Features</h2>
              </div>
              <div className="row gx-xxl-5">
                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay={100}>
                  <div className="block-style-four ignite_feature_box">
                    <div className="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_08.svg" alt="" /></div>
                    <a href="#"><h5>ETH COMPATIBILITY</h5></a>
                    <p>Ignite Blockchain is a public, permissionless, Ethereum Virtual Machine (EVM) compatible blockchain running on the world’s leading Proof-of-Stake Voting consensus mechanism.</p>
                    {/* <a href="service-V1.html" className="more-btn"></a> */}
                  </div> {/* /.block-style-four */}
                </div>
                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay={100}>
                  <div className="block-style-four ignite_feature_box">
                    <div className="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_card1.png" alt="" /></div>
                    <a href="#"><h5>INTEROPERABILITY</h5></a>
                    <p>Being an Ethereum compatible blockchain, Ignite offers cross-chain transfers of assets or data from one blockchain to another seamlessly.</p>
                    {/* <a href="service-V1.html" className="more-btn"></a> */}
                  </div> {/* /.block-style-four */}
                </div>
                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay={200}>
                  <div className="block-style-four ignite_feature_box">
                    <div className="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_09.svg" alt="" /></div>
                    <a href="#"><h5>LOW COST</h5></a>
                    <p>Ignite blockchain offers inexpensive transactions for both users &amp; developers, costing nearly 0 cent on the network.</p>
                    {/* <a href="service-V1.html" className="more-btn"></a> */}
                  </div> {/* /.block-style-four */}
                </div>
                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay={100}>
                  <div className="block-style-four ignite_feature_box">
                    <div className="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_10.svg" alt="" /></div>
                    <a href="#"><h5>BLAZINGLY FAST</h5></a>
                    <p>Recording a seamless transaction experience that performed under a jiffy.</p>
                    {/* <a href="service-V1.html" className="more-btn"></a> */}
                  </div> {/* /.block-style-four */}
                </div>
                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay={200}>
                  <div className="block-style-four ignite_feature_box">
                    <div className="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_11.svg" alt="" /></div>
                    <a href="#"><h5>SECURITY</h5></a>
                    <p>Ignite blockchain has enhanced security model making use of custom IGT Security protocol.</p>
                    {/* <a href="service-V1.html" className="more-btn"></a> */}
                  </div> {/* /.block-style-four */}
                </div>
                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay={300}>
                  <div className="block-style-four ignite_feature_box">
                    <div className="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_12.svg" alt="" /></div>
                    <a href="#"><h5>PRIVACY ENABLED</h5></a>
                    <p>No third party intermediaries are allowed to obtain data from the Ignite network without permission of sovereign authority.</p>
                    {/* <a href="service-V1.html" className="more-btn"></a> */}
                  </div> {/* /.block-style-four */}
                </div>
              </div>
            </div>
          </div> {/* /.inner-container */}
        </div> {/* /.bg-wrapper */}
        {/* <img src="images/shape/shape_06.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/shape_07.svg" alt="" className="shapes shape-two" /> */}
      </div> </>
    );
  }
  
  export default IGNITEFeatures;