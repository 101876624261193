function IgniteSolutionsImages() {
    return (
        <>

            <div className="blog-section-three pt-90 mb-150 lg-pt-40 lg-mb-100">
                <div className="container">
                    <div className="row gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                            <article className="blog-meta-onee color-two tran3s mt-45 whitespace-normal">
                                <figure className="post-img m0"><a href="solutions-desc.html" className="w-100 d-block"><img src="images/blog/blog_img.jpg" alt="" className="w-100 tran4s" /></a></figure>
                                <div className="post-data">
                                    {/* <div class="post-tag"><a href="blog-details.html">Data Science</a> - 15 minute read</div> */}
                                    <a href="solutions-desc.html" className="blog-title"><h5>Financial markets: Digital Assets</h5></a>
                                    <a href="solutions-desc.html"><p className=" text-dark text-justify">With Fantom, you can easily create digital assets to better monetize traditional financial instruments, gain access to a global investor audience, and improve liquidity while speeding up processes.</p></a>
                                </div> {/* /.post-data */}
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={100}>
                            <article className="blog-meta-onee color-two tran3s mt-45 whitespace-normal">
                                <figure className="post-img m0"><a href="solutions-desc.html" className="w-100 d-block"><img src="images/blog/blog_img_02.jpg" alt="" className="w-100 tran4s" /></a></figure>
                                <div className="post-data">
                                    {/* <div class="post-tag"><a href="blog-details.html">UI/UX Design</a> - 10 minute read</div> */}
                                    <a href="solutions-desc.html" className="blog-title"><h5>Institutional Finance: Clearing and settlement</h5></a>
                                    <a href="solutions-desc.html"><p className=" text-dark text-justify">Blockchain makes back-office processes more efficient by eliminating intermediaries and siloed infrastructure and keeps transactional safety while eliminating custodial risk. Near real-time settlement becomes a reality, together with cost savings of up to 70%.</p></a>
                                </div> {/* /.post-data */}
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={200}>
                            <article className="blog-meta-onee color-two tran3s mt-45 whitespace-normal">
                                <figure className="post-img m0"><a href="solutions-desc.html" className="w-100 d-block"><img src="images/blog/blog_img_03.jpg" alt="" className="w-100 tran4s" /></a></figure>
                                <div className="post-data">
                                    {/* <div class="post-tag"><a href="blog-details.html">Marketing</a> - 8 minute read</div> */}
                                    <a href="solutions-desc.html" className="blog-title"><h5>Central Bank Digital Currency (CBDC)</h5></a>
                                    <a href="solutions-desc.html"><p className=" text-dark text-justify">CBDC is a novel solution for central banks to provide financial stability while making interbank settlements faster, transactions cheaper and the financial ecosystem more accessible for people.</p></a>
                                </div> {/* /.post-data */}
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                            <article className="blog-meta-onee color-two tran3s mt-45 whitespace-normal">
                                <figure className="post-img m0"><a href="solutions-desc.html" className="w-100 d-block"><img src="images/blog/blog_img_08.jpg" alt="" className="w-100 tran4s" /></a></figure>
                                <div className="post-data">
                                    {/* <div class="post-tag"><a href="blog-details.html">Data Science</a> - 15 minute read</div> */}
                                    <a href="solutions-desc.html" className="blog-title"><h5>Decentralized Finance</h5></a>
                                    <a href="solutions-desc.html"><p className=" text-dark text-justify">DeFi is a new financial system that allows everyone to earn money in new ways globally. With DeFi, anyone in the world can use all the financial tools that have been limited to the top 1%. DeFi makes Finance open and permissionless while increasing the efficiency of economic processes.</p></a>
                                </div> {/* /.post-data */}
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={100}>
                            <article className="blog-meta-onee color-two tran3s mt-45 whitespace-normal">
                                <figure className="post-img m0"><a href="solutions-desc.html" className="w-100 d-block"><img src="images/blog/blog_img_09.jpg" alt="" className="w-100 tran4s" /></a></figure>
                                <div className="post-data">
                                    {/* <div class="post-tag"><a href="blog-details.html">UI/UX Design</a> - 10 minute read</div> */}
                                    <a href="solutions-desc.html" className="blog-title"><h5>Tokenized Real Estate</h5></a>
                                    <a href="solutions-desc.html"><p className=" text-dark text-justify">Real Estate tokenization enables better access to real estate investments for individuals. For institutions, it means streamlined operations, a wider investor pool, and alternative financing vehicles. Digitalization and fractionalization of real estate make secondary markets more liquid and attract new investors.</p></a>
                                </div> {/* /.post-data */}
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={200}>
                            <article className="blog-meta-onee color-two tran3s mt-45 whitespace-normal">
                                <figure className="post-img m0"><a href="solutions-desc.html" className="w-100 d-block"><img src="images/blog/blog_img_10.jpg" alt="" className="w-100 tran4s" /></a></figure>
                                <div className="post-data">
                                    {/* <div class="post-tag"><a href="blog-details.html">Marketing</a> - 8 minute read</div> */}
                                    <a href="solutions-desc.html" className="blog-title"><h5>Smart Healthcare</h5></a>
                                    <a href="solutions-desc.html"><p className=" text-dark text-justify">By validating the authenticity of pharmaceuticals, blockchain minimizes health risks. Sensitive patient data can be stored securely, ensuring confidentiality while improving data sharing.</p></a>
                                </div> {/* /.post-data */}
                            </article>
                        </div>
                    </div> {/* /.row */}
                    {/* <div class="page-pagination-one pt-90">
						<ul class="d-flex align-items-center justify-content-center style-none">
							<li class="active"><a href="#">1</a></li>
							<li><a href="#">2</a></li>
							<li><a href="#">3</a></li>
							<li class="arrow"><a href="#"><i class="bi bi-arrow-right"></i></a></li>
						</ul>
					</div> */}
                </div>
            </div>


        </>

    )
}

export default IgniteSolutionsImages;