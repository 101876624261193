import Header from ".././header/index";
import IgniteSolutionsHeading from "./ignitesolutionheading";
import IgniteSolutionsContent from "./ignitesolutioncontent";
import IgniteSolutionsImages from "./ignitesolutionsimages";
import IgniteSolutionsContactUs from "./ignitesolutionscontactus";
import Footer from '.././footer/index';

function IgniteSolutions () {
    document.title="Ignite-Solutions - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <IgniteSolutionsHeading/>
                <IgniteSolutionsContent/>
                <IgniteSolutionsImages/>
                <IgniteSolutionsContactUs/>
                <Footer />

            </div>


        </div>
    );
}
export default IgniteSolutions;