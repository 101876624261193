import Header from ".././header/index";
import Networkarea from "./networkarea/index";
import Networkinfo from "./networkinfo/index";
import Footer from '.././footer/index';

function Network() {
    document.title="Network - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
     
        <div>
            <div className="main-page-wrapper">
                <Header />
               <Networkarea/>
               <Networkinfo/>
                <Footer />

            </div>


        </div>
    );
}
export default Network;