function Solutions() {
  return (
    <>



      <div className="fancy-feature-nineteen position-relative pt-130 lg-pt-80" style={{height:700}}>
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-lg-6 col-md-7">
              <div className="block-style-two" data-aos="fade-right">
                <div className="title-style-three">
                  <div className="sc-title">Bridge</div>

                  <h2 className="main-title"><span>Ignite</span> Bridge</h2>
                  {/* <h2 className="main-title"><span>Bridge</span></h2> */}
                </div> {/* /.title-style-three */}
                <p className="pt-20 pb-25 lg-pb-20">Ignite Bridge is a communication bridge between Ignite and  other Blockchain as well other famous decentralized finance (DeFi) networks. Existing projects, platforms, and communities are able to transfer tokenized assets seamlessly across blockchains with the benefit of Ignite's high speed and low cost.</p>
                {/* <ul className="style-none list-item color-rev">
                  <li>Various analysis options.</li>
                  <li>Page Load (time, size, number of requests).</li>
                  <li>Advance Data analysis operation.</li>
                </ul>
                <a href="about-us2.html" className="btn-eight mt-50 lg-mt-30">More about us</a> */}
              </div>{/* /.block-style-thirteen */}
            </div>
          </div>
        </div> {/* /.container */}
        <div className="illustration-holder" data-aos="fade-left">
        
        <img src="images/assets/Bridge.png" alt="" className="w-100 main-illustration" />
          {/* <img src="images/assets/ils_15.svg" alt="" className="w-100 main-illustration" />
          <img src="images/assets/ils_15_1.svg" alt="" className="shapes shape-one" />
          <img src="images/assets/ils_15_2.svg" alt="" className="shapes shape-two" />
          <img src="images/assets/ils_15_3.svg" alt="" className="shapes shape-three" />
          <img src="images/assets/ils_15_4.svg" alt="" className="shapes shape-four" />
          <img src="images/assets/ils_15_5.svg" alt="" className="shapes shape-five" data-aos="fade-down" data-aos-delay={200} data-aos-duration={2000} />
          <img src="images/assets/ils_15_6.svg" alt="" className="shapes shape-six" data-aos="fade-down" data-aos-delay={100} data-aos-duration={2000} />
          <img src="images/assets/ils_15_7.svg" alt="" className="shapes shape-seven" data-aos="fade-down" data-aos-duration={2000} /> */}
        </div> {/* /.illustration-holder */}
        <div className="shapes oval-one" />
        <div className="shapes oval-two" />
        <img src="images/shape/shape_35.svg" alt="" className="shapes bg-shape" />
      </div>


    </>



  )
}
export default Solutions;