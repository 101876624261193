function DaligatorsDashboardSidebar() {
    return (
        <>


            <div className="sidebar">
                <div className="logo-details">
                    <i className="bx bx-menu" id="btn" />
                </div>
                <ul className="nav-list px-0">
                    <li>
                        <a href="/validator_delegators">
                            <i className="bx bxs-dashboard" />
                            <span className="links_name">Dashbord</span>
                        </a>
                        <span className="tooltip">Dashbord</span>
                    </li>
                    <li>
                        <a href="#" className="text-white">
                            <i className="bx bx-list-minus" />
                            <span className="links_name">Deligators List</span>
                        </a>
                        <span className="tooltip">Deligators List</span>
                    </li>
                </ul>
            </div>






        </>

    )
}

export default DaligatorsDashboardSidebar;