import Footer from "../footer";
import Header from "../header";

function MediaAssets() {
  document.title="Media Assets | IGNITE Blockchain";
  return (
    <>
    <Header/>
      <div className="main-page-wrapper">
      <div className="theme-inner-banner">
          <div className="container">
            <h2 className="intro-title text-center">Media Assets</h2>
            <ul className="page-breadcrumb style-none d-flex justify-content-center">
              <li><a href="Ignite.html">Home</a></li>
              <li className="current-page">Media Assets</li>
            </ul>
          </div>
          <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
          <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
        </div> 
        <div className="fancy-feature-two position-relative mt-140 lg-mt-100">
          <div className="container">
            <div className="title-style-one">
              <h2 className="blog-title">Master Logo</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 col-md-7 mt-50">
                <div className="block-style-two">
                  <img src="images/logo/Plogo-10.png" alt="" style={{height: 'auto', width: '100%'}} />
                  <a download href="images/logo/Plogo-10.png">
                    <button className="download-btn" style={{zIndex: 2, top: '120px'}}><i className="fa fa-download" style={{fontSize: '14px'}} />
                    </button>
                  </a>
                </div> 
              </div>
              <div className="col-xxl-5 col-lg-6 col-md-7 mt-50">
                <div className="block-style-two">
                  <img src="images/logo/Plogo-10.png" alt="" style={{height: 'auto', width: '100%'}} />
                  <a href="images/logo/Plogo-10.png" download>
                    <button className="download-btn1" download style={{zIndex: 2, top: '120px'}}><i className="fa fa-download" style={{fontSize: '14px'}} />
                    </button>
                  </a>
                </div> 
              </div>
            </div>
          </div> 
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default MediaAssets;