import Header from ".././header/index";
// import DaligatorsDashboardSidebar from "./delegator_dashboard";
// import SidebarHeading from "./sidebar_heading";
import DelegatorListSidebar from "./daligatorslistsidebar";
import DelegatorListTable from "./daligatorslisttable";
import Footer from '.././footer/index';



function ValigatorsDaligatorsList() {
    document.title="Validators-Daligators - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
            <Header/>
            {/* <DaligatorsDashboardSidebar/>
            <SidebarHeading/> */}
            <DelegatorListSidebar/>
            <DelegatorListTable/>
            <Footer />     
     
            </div>


        </div>
    );
}
export default ValigatorsDaligatorsList;