function Footerlinkmaininformation() {
    return (
        <>

            <div className="pricing-section-two position-relative mt-20 mb-180 lg-mt-80 lg-mb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-6 col-md-8 m-auto">
                            <div className="title-style-one text-justify mb-20" data-aos="fade-up">
                                {/* <div class="sc-title-five">Pricing & Plan</div> */}
                                <h2 className="main-title fs-1">The IGT supply</h2>
                                <p className="pt-25">The total supply for IGT is 100 Million. 50 Million is currently in circulation and remaining is locked for next five years in form of economical liquidity. Depending on the rewards, it is going to take above five years to reach the full circulation of the total IGT supply.<br></br>
                                    The total supply is distributed over different token standards on different networks to ensure easier trading and liquidity diversification. All the IGT tokens in addition over different blockchain networks will never exceed the maximum supply of 100 Million. Currently, IGT is available as native mainnet token on Ignite Chain.

                                </p>
                            </div> {/* /.title-style-one */}
                        </div>
                    </div>
                    <div className="row align-items-center mt-50">
                        <div className="col-xl-7 col-lg-6 col-md-8 m-auto">
                            <div className="title-style-one text-justify mb-20" data-aos="fade-up">
                                {/* <div class="sc-title-five">Pricing & Plan</div> */}
                                <h2 className="main-title fs-1">How to buy IGT? How to store IGT?</h2>
                                <p className="pt-25">IGT will be available to buy very soon on Bitmart exchange following the initial exchange offering (IEO).<br></br>
                                    Holders will have several options to store IGT in different crypto wallets. However, we would recommend using Ignite Wallet to store IGT as it will provide easy staking option and highest security for your assets.
                                </p>
                            </div> {/* /.title-style-one */}
                        </div>
                    </div>
                </div>
                {/* <img src="images/shape/shape_34.svg" alt="" class="shapes shape-one"> */}
            </div>

        </>

    )
}

export default Footerlinkmaininformation;