function IgniteSolutionsContent() {
    return (
        <>

            <div className="fancy-feature-seventeen position-relative mt-160 xl-mt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-10 col-lg-10" data-aos="fade-right">
                            <div className="title-style-three text-start text-lg-start text-sm-center ">
                                <h4 className="main-title fs-2 pb-2 ">Solutions</h4>
                                <p className="m0 text-center text-lg-start md-pt-30 line-height">Fantom is the ideal platform to develop global solutions using blockchain technology.
                                    A wide range of industries like financial markets, healthcare, education, and many more can benefit from Fantom’s unique and future-ready infrastructure.
                                    Build on top of the existing Fantom ecosystem, or integrate Lachesis into your technology stack.</p>
                            </div> {/* /.title-style-three */}
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="shapes shape-one" />
            </div>


        </>


    )
}

export default IgniteSolutionsContent;