function FooterGetStarted() {
    return (
        <>

            <div className="fancy-feature-eleven mt-225 lg-mt-120">
                <div className="title-style-one text-center mt-50 mb-50">
                    {/* <div class="sc-title-four">App Integration</div> */}
                    <h2 className="fs-1 fw-bold mt-3 text-white">Get Started with a Few Steps</h2>
                </div>
                {/* <div class="fs-2 text-white fw-bolder text-center mb-50">Get Started with a Few Steps</div> */}
                <div className="container mb-50">
                    {/* <div class="title-style-one white-vr text-center mb-55 lg-mb-30" data-aos="fade-up">
						<h2 class="main-title">How It works</h2>
						<p class="sub-title">Our advance AI system work seamlesly & smartly.</p>
					</div> */}
                    {/* /.title-style-one */}
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row justify-content-center gx-xxl-5">
                                <div className="col-md-4 col-sm-6" data-aos="fade-right">
                                    <div className="block-style-eight position-relative mt-50">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src="images/icon/Download (3).svg" alt="" />
                                            <div className="num">1</div>
                                        </div>
                                        <h5>Download</h5>
                                    </div> {/* /.block-style-eight */}
                                </div>
                                <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="block-style-eight position-relative mt-50 shape-arrow">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src="images/icon/Wallet.svg" alt="" />
                                            <div className="num">2</div>
                                        </div>
                                        <h5>Create Wallet</h5>
                                    </div> {/* /.block-style-eight */}
                                </div>
                                <div className="col-md-4 col-sm-6" data-aos="fade-left" data-aos-delay={200}>
                                    <div className="block-style-eight position-relative mt-50">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src="images/icon/transfer.svg" alt="" />
                                            <div className="num">3</div>
                                        </div>
                                        <h5>Transfer Assets</h5>
                                    </div> {/* /.block-style-eight */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> {/* /.container */}
                {/* <img src="images/shape/shape_20.svg" alt="" class="shapes shape-one">
				<img src="images/shape/shape_21.svg" alt="" class="shapes shape-two">
				<img src="images/shape/shape_22.png" alt="" class="shapes shape-three">
				<img src="images/shape/shape_23.png" alt="" class="shapes shape-four"> */}
            </div>


        </>


    )
}

export default FooterGetStarted;