function IgtSolutionsDescriptionProblem() {
    return (
        <>

            <div className="pr-details-one bg-light pt-150 mt-10 lg-mt-90 mb-40 lg-mb-100">
                <div className="container">
                    <div className="col-9">
                        <h2 className="fs-2 fw-bolder text-start">The problem</h2>
                        <p className="pb-25 line-height">Investment companies have limited control, poor transparency, and
                            higher costs than necessary. Investors are left with sub-optimal experience and high fees.</p>
                    </div>
                    <div className="wrapper ps-xxl-4 pe-xxl-4 ms-xxl-5 me-xxl-5 bg-color">
                        <div className="row gx-xxl-5 pb-100">
                            <div className="col-lg-9 border-end">
                                <div className="title-style-five">
                                    <h2 className="main-title fs-4">Website page re-design with Branding.</h2>
                                    <p className="pt-10 line-height">We’ve created a unique visual system and strategy across
                                        the wide existing spectrum of visible mobile applications found yourself in a wide,
                                        straggling with.</p>
                                </div>
                            </div>
                            {/* <div class="col-lg-6 border-end">
								<div class=" col-10 text-start ">
								</div>
							</div> */}
                            <div className="col-lg-3">
                                <ul className=" list-unstyled">
                                    <li><i className="fa fa-times-circle">&nbsp; Human errors</i></li>
                                    <li><i className="fa fa-times-circle">&nbsp; Inconsistent data</i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gx-xxl-5 pb-100">
                            <div className="col-lg-9 border-end">
                                <div className="title-style-five">
                                    <h2 className="main-title fs-4">Website page re-design with Branding.</h2>
                                    <p className="pt-10 line-height">We’ve created a unique visual system and strategy across
                                        the wide existing spectrum of visible mobile applications found yourself in a wide,
                                        straggling with.</p>
                                </div>
                            </div>
                            {/* <div class="col-lg-6 border-end">
								<div class=" col-10 text-start ">
								</div>
							</div> */}
                            <div className="col-lg-3">
                                <ul className=" list-unstyled">
                                    <li><i className="fa fa-times-circle">&nbsp; Human errors</i></li>
                                    <li><i className="fa fa-times-circle">&nbsp; Inconsistent data</i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gx-xxl-5 pb-100">
                            <div className="col-lg-9 border-end">
                                <div className="title-style-five">
                                    <h2 className="main-title fs-4">Website page re-design with Branding.</h2>
                                    <p className="pt-10 line-height">We’ve created a unique visual system and strategy across
                                        the wide existing spectrum of visible mobile applications found yourself in a wide,
                                        straggling with.</p>
                                </div>
                            </div>
                            {/* <div class="col-lg-6 border-end">
								<div class=" col-10 text-start ">
								</div>
							</div> */}
                            <div className="col-lg-3">
                                <ul className=" list-unstyled">
                                    <li><i className="fa fa-times-circle">&nbsp; Human errors</i></li>
                                    <li><i className="fa fa-times-circle">&nbsp; Inconsistent data</i></li>
                                </ul>
                            </div>
                        </div>
                    </div> {/* /.wrapper */}
                </div>
            </div>


        </>


    )
}

export default IgtSolutionsDescriptionProblem;