import Header from ".././header/index";
import Careerpagemaincontent from "./careerpagemaincontent";
import Careerpagemaininformation from "./careerpagemaininformation";
// import Careerappicon from "./careerappicon";

import Footer from '.././footer/index';

function Ignitefootercareer() {
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <Careerpagemaincontent />
                <Careerpagemaininformation />
                 {/* <Careerappicon/> */}
                <Footer />

            </div>


        </div>
    );
}
export default Ignitefootercareer;