function Protocol() {
  
    return (
        <>



            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title text-center">Protocol & Product</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li><a href="index.html">Home </a></li>
                        <li className="current-page protocol_main_heading">Protocol & Product</li>
                    </ul>
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
            </div>

        </>



    )
}
export default Protocol;