function FooterCryptoWallet() {
    return (
        <>

            <div className="fancy-feature-six position-relative mt-80">
                <div className="container">
                    <div className="title-style-one text-center mb-20" data-aos="fade-up">
                        {/* <div class="sc-title-five">Pricing & Plan</div> */}
                        <h2 className="fs-1 fw-bolder"> Your trusted crypto Wallet </h2>
                        <p className="info mt-15 lg-mt-50" data-aos="fade-up">We've done it carefully and simply.</p>
                    </div>
                    <div className="bg-wrapper">
                        <div className="row gx-xxl-5 justify-content-center">
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: 'rgba(68, 109, 255, 0.07)', color: '#446DFE' }}><i className="bi bi-check-lg" /></div>
                                    <h6>Asset Security</h6>
                                    <p>Protect your digital assets with industry-leading security Now you can safely store
                                        your crypto and NFTs in one place.</p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay={100}>
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: 'rgba(253, 232, 255, 1)', color: '#F36EFF' }}><i className="bi bi-check-lg" /></div>
                                    <h6>Platform</h6>
                                    <p>Support for seamless integration between both Mobile and Web.</p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay={200}>
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: 'rgba(255, 122, 65, 0.12)', color: '#FF7A41' }}><i className="bi bi-check-lg" /></div>
                                    <h6>Cross-Chain transaction</h6>
                                    <p>It supports connecting multi-chain applications and can exchange cross-chain assets.
                                    </p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: 'rgba(68, 109, 255, 0.07)', color: '#446DFE' }}><i className="bi bi-check-lg" /></div>
                                    <h6>Data Encryption</h6>
                                    <p>With our Advance Data Encryption algorithm, Your transaction data is secured via
                                        end-to-end encryption, ensuring that only you have access to your personal
                                        information.</p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay={100}>
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: 'rgba(253, 232, 255, 1)', color: '#F36EFF' }}><i className="bi bi-check-lg" /></div>
                                    <h6>Browse DApps</h6>
                                    <p>Ignite Wallet provides the simplest yet most secure way to connect to
                                        blockchain-based applications. Find your favorite decentralized apps, without
                                        leaving your wallet.</p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay={200}>
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: 'rgba(255, 122, 65, 0.12)', color: '#FF7A41' }}><i className="bi bi-check-lg" /></div>
                                    <h6>Store your Assets</h6>
                                    {/* <p>Safely store and easily view all of your NFTs (ERC721 tokens) right in Your Wallet.
                                    </p> */}
                                    <p>Safely store and easily view all of your NFTs right in Your Wallet.
                                    </p>

                                </div> {/* /.block-style-six */}
                            </div>
                        </div>
                    </div> {/* /.bg-wrapper */}
                </div> {/* /.container */}
                {/* <div class="illustration-holder d-inline-block position-relative"> */}
                <img src="images/shape/1-1.png" alt="" className="shapes shape-one" />
                <img src="images/shape/1-2.png" alt="" className="shapes shape-two" />
                {/* </div> */}
            </div>


        </>

    )
}

export default FooterCryptoWallet;