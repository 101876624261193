function Footerlinkmaincontent() {
  return (
    <>

      <div className="fancy-feature-twenty position-relative mt-100 pb-50 lg-mt-100 lg-pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="block-style-five pe-xxl-5 me-xxl-5 md-pb-50" data-aos="fade-right">
                <div className="title-style-three">
                  {/* <div class="sc-title">QUESTIONS & ANSWERS</div> */}
                  <h2 className="main-title">What is ignite?</h2>
                </div> {/* /.title-style-three */}
                <p className="pt-20 pb-15 text-start">IGT is the native token on Ignite Chain. It is used to secure the Ignite Chain network through staking, on-chain governance, and to pay for the transaction fees.</p>
                {/* <a href="contact-us.html" class="btn-eight ripple-btn">Contact us</a> */}
              </div> {/* /.block-style-five */}
            </div>
            <div className="mt-40 pt-50 pb-40 mb-60 lg-mt-50 lg-pt-50 lg-pb-20 lg-mb-40 border-top ">
              <div className="row gx-xxl-12">
                <div className="col-lg-6">
                  <img src="images/icon/icon_11.svg" height="65px" width="65px" className="rounded-circle p-2" alt="" />
                  <h3 className="fs-4 fw-bold">Secure the Network</h3>
                  <div className="text-justify">
                    {/* <p className="small line-height">The main utility of the IGT token on Fantom is to secure the network via a Proof-of-Stake system.
                      To participate, validator nodes need to hold a minimum of 50000 IGT and stakers need to lock up their IGY.
                      In return for the service, both the nodes and the stakers are rewarded with epoch rewards and fees.
                      <br />Besides preventing centralization, the system is also environmentally friendly. </p> */}
                    {/* <p className="small line-height"> The primary utility of IGT token is to secure the Ignite Chain network with using Proof-of-Stake system. In order to run Ignite Node and participate in securing the network, validator must hold 50,000 IGT and voters have to lock up their IGT to any validator node to increase node capacity. In exchange for participation, validator and voter both receive rewards in IGT after every transaction.</p> */}
                    <p> The primary utility of IGT token is to secure the Ignite Chain network with using Proof-of-Stake system. In order to run Ignite Node and participate in securing the network, validator must hold 50,000 IGT and voters have to lock up their IGT to any validator node to increase node capacity. In exchange for participation, validator and voter both receive rewards in IGT after every transaction.</p>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 ms-auto">
                  <img src="images/icon/icon_32.svg" height="65px" width="65px" className=" p-2" alt="" />
                  <h3 className="fs-4 fw-bold">Payments</h3>
                  <div className="text-justify">
                    {/* <p className="small line-height ">IGT is ideal for cryptocurrency payments to enterprises and businesses all around the world. It offers very quick and low-cost transactions compared to other crypto payment options. As the network eligible to process 10,000 transactions per second (TPS), depending on the number of validator nodes, Ignite Chain is high throughput and affordable blockchain network to every user.</p> */}
                    <p>IGT is ideal for cryptocurrency payments to enterprises and businesses all around the world. It offers very quick and low-cost transactions compared to other crypto payment options. As the network eligible to process 10,000 transactions per second (TPS), depending on the number of validator nodes, Ignite Chain is high throughput and affordable blockchain network to every user.</p>
                  </div>
                </div>
              </div>
              <div className="row gx-xxl-5 pt-40">
                <div className="col-lg-6">
                  <img src="images/icon/icon_12.svg" height="65px" width="65px" className="rounded-circle p-2" alt="" />
                  <h3 className="fs-4 fw-bold">Network fees</h3>
                  <div className="text-justify">
                    {/* <p className="small line-height">IGT is used to pay for network fees such as transaction fees, smart contract deployment and other networks activities. The fees on Ignite Chain network is extremely cheap compared to other blockchain networks. However, lower fees does not compromise network states as it sets limitations to suspicious activities via IGT privacy protocol.</p> */}
                    <p>IGT is used to pay for network fees such as transaction fees, smart contract deployment and other networks activities. The fees on Ignite Chain network is extremely cheap compared to other blockchain networks. However, lower fees does not compromise network states as it sets limitations to suspicious activities via IGT privacy protocol.</p>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-6 ms-auto">
                  <img src="images/icon/icon_11.svg" height="65px" width="65px" className=" p-2" alt="" />
                  <h3 className="fs-4 fw-bold">Governance</h3>
                  <div className="text-justify">
                    {/* <p className="small line-height ">As being native token, IGT is used in Ignite on-chain governance. Ignite Chain is a fully decentralized network, hence any decision on the network is carried out by on-chain governance process. IGT holders can propose and vote for changes and improvements by staking their tokens. </p> */}
                    <p>As being native token, IGT is used in Ignite on-chain governance. Ignite Chain is a fully decentralized network, hence any decision on the network is carried out by on-chain governance process. IGT holders can propose and vote for changes and improvements by staking their tokens. </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div> {/* /.container */}
        {/* <img src="images/shape/shape_36.svg" alt="" class="shapes shape-one">
				<div class="shapes oval-one"></div> */}
      </div>


    </>

  )
}

export default Footerlinkmaincontent;