function StakeNowReward() {
    return (
        <>

            <div className="pricing-section-one mt-130 pt-80 lg-mt-110">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 m-auto">
                            <div className="title-style-one text-center">
                                <h2 className="main-title">Stake now and earn rewards</h2>
                                <p className="h5">Ignite PoS chain is run on Proof of Stake mechanism. &nbsp; Anyone can become a validator on the mainnet.</p>
                            </div> {/* /.title-style-one */}
                            <div className="title-style-one text-center mt-15">
                                <div className="sc-title-three font-weight-bold fs-5 p-3 py-2 rounded-pill" style={{ color: '#70A5FF', background: '#DBE8FF' }}>13% APR</div>
                            </div>
                            <div className="d-flex justify-content-center mb-50">
                                <p className="text-center">Check how much reward you can earn with your $IGT</p>
                                <a href="#" className="ml-3"> &nbsp; Go to Reward Calculator&nbsp;<span><i className="bi bi-arrow-up-right" /></span></a>
                            </div>
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="pricing-table-area-one" data-aos="fade-up" data-aos-delay={100}>
                    <div className="container">
                        <div className="row gx-xxl-5">
                            <div className="col-md-6">
                                <div className="pr-table-wrapper active md-mb-30">
                                    <div className="title-style-one text-left">
                                        <div className="sc-title-three text-center px-2 py-1">
                                            <span className="mb-0">Run a Node</span>
                                        </div>
                                    </div>
                                    <div className="pack-name text-left">Become a Validator</div>
                                    {/* <div class="pack-details">For individuals and teams. Get <span>1 year <br> premium market access</span></div> */}
                                    {/* /.top-banner */}
                                    <p className="text-justify fs-5 mt-15  w-100 text_justiufy">Any participant in the network can signal their intent to become a validator by creating a validator and registering its validator profile. Validators verify transactions and add new blocks on the blockchain. In return, they earn rewards.</p>
                                    {/* <div class="d-flex align-items-end"> */}
                                    {/* <img src="images/gallery/530.png" alt="" class="shape-ten m-auto float-end"/> */}
                                    {/* <button href="#" className="btn-five rounded-pill ripple-btn"><span>Setup a Node</span> </button> */}
                                    <a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/masternode" className="btn-five rounded-pill ripple-btn"><span>Setup a Node</span> </a>
                                    {/* </div> */}
                                </div> {/* /.pr-table-wrapper */}
                            </div>
                            <div className="col-md-6 pricing-box-image">
                                <div className="pr-table-wrapper">
                                    <div className="title-style-one text-left">
                                        <div className="sc-title-three px-2 py-1">
                                            <span className="mb-0">Delegate</span>
                                        </div>
                                    </div> {/* /.top-banner */}
                                    <div className="pack-name text-left">Become a Delegator</div>
                                    <p className="text-justify fs-5 mt-15 w-100 ">Ignite rewards are delivered on top of earnings from transaction fees. Delegation participants are eligible to earn rewards and contribution to network security and Delegate staking tokens to a validator from the Ignitechain Network.</p>
                                    {/* <br> */}
                                    {/* <img src="images/gallery/530.png" alt="" class="shape-ten m-auto float-end" /> */}
                                    {/* <div class="pack-details">For big agency & teams. Get <span>1 year <br> premium market access</span></div> */}
                                    {/* <button href="#" className="btn-five rounded-pill ripple-btn"><span>Become a Delegator</span></button> */}
                                    <a  className="btn-five rounded-pill ripple-btn"><span>Become a Delegator</span></a>
                                </div> {/* /.pr-table-wrapper */}
                            </div>
                        </div>
                        {/* <div class="msg-note mt-80 lg-mt-50" data-aos="fade-up">If you Need any Custom or others Pricing System. <br> Please <a href="contact-us.html">Send Message</a></div> */}
                    </div>
                </div> {/* /.pricing-table-area-one */}
            </div>


        </>






    )
}

export default StakeNowReward;