import Footer from "../footer";
import Header from "../header";

function TermsCondtions() {
  document.title="Terms & Conditions | IGNITE Blockchain";
  return (
    <>
    <Header/>
      <div className="main-page-wrapper">
      <div className="theme-inner-banner" >
        <div className="container">
          <h2 className="intro-title text-center">Terms and Conditions</h2>
          <ul className="page-breadcrumb style-none d-flex justify-content-center">
            <li><a href="Ignite.html">Home</a></li>
            <li className="current-page">Terms and Conditions</li>
          </ul>
        </div>
        <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
        <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
      </div>
      <div className="fancy-feature-two position-relative mt-140 lg-mt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="card" style={{padding: '30px', border: 'none'}}>
              <h4 className="mt-4"><strong>Eligibility to use Website</strong></h4>
              <p>This Website is offered and available to Users who are eligible to enter into a contract. By
                using this Website, you represent and warrant that user are of legal age and legally
                competent to form a binding contract with us and if they do not meet these requirements,
                They must not access or use the Website.</p>
              <h4><strong>Intellectual Property Right</strong></h4>
              <p>The Website and all its contents, features and functionalities (including but not limited to
                all information, software, text, displays, images, video and audio, and the design,
                selection and arrangement thereof), are owned by us, our licensors or other providers of
                such material and are protected by applicable laws and other intellectual property or
                proprietary rights laws.</p>
              <p>These Terms permit User to use the Website in the manner specified herein and in their
                Believer Transaction Documents. They must not reproduce, distribute, modify, create
                derivative works of, publicly display, republish, download, store or transmit any of the
                material on our Website, except as follows:</p>
              <ul>
                <li>Users computer may temporarily store copies of such materials in RAM incidental to their
                  accessing and viewing those materials;</li>
                <li>They may store files that are automatically cached by their web browser for display
                  enhancement purposes;</li>
                <li>If we provide desktop, mobile or other applications for download, they may download a
                  single copy to their computer or mobile device solely for their own personal,
                  non-commercial use, provided they agree to be bound by our end user license agreement
                  for such applications;</li> and
                <li>If we provide social media features with certain content, they may take such actions as
                  are enabled by such features.</li>
              </ul>
              <h5> User must not</h5>
              <ul style={{marginLeft: '-15px'}}>
                <li>Modify copies of any materials from this site;</li>
                <li>Use any illustrations, photographs, or graphics separately from the accompanying text;
                </li>
                Or
                <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies
                  of materials from this site.</li>
                <li>If you print, copy, modify, download or otherwise use or provide any other person with
                  access to any part of the Website in breach of these Terms, their right to use the
                  Website will cease immediately and they must, at our option, return or destroy any
                  copies of the materials they have made. No right, title or interest in or to the Website
                  or any content on the Website is transferred to them, and all rights not expressly
                  granted are reserved by us. Any use of the Website not expressly permitted by these
                  Terms is a breach of these Terms and may violate copyright, trademark and other
                  applicable laws.</li>
              </ul>
              <h4><strong>Disclaimer of Warranties</strong></h4>
              <p>we will not be liable for any loss or damage caused by a distributed denial-of-service
                attack, viruses or other technologically harmful material that may infect your page 10 of 15
                computer equipment, computer programs, data or other proprietary material due to your use of
                the website or any products or services obtained through the website or to your downloading
                of any material posted on it, or on any website linked to it. you agree that use of the
                website, its content, and any products or services obtained through the website is at your
                sole risk and expense. the website, its content, and any products or services obtained
                through the website are provided on an "as is" and "as available" basis, without any
                warranties of any kind whatsoever, either express or implied. neither do we, nor any person
                associated with us, makes any warranty or representation with respect to the completeness,
                security, reliability, quality, accuracy or availability of the website. without limiting to
                the foregoing, neither we nor anyone associated with us represents or warrants that the
                website, its content or any products or services obtained through the website will be
                accurate, reliable, uninterrupted, free of errors, viruses or other harmful components, or
                that any products or services obtained through the website will otherwise meet your needs or
                expectations. we disclaim all liability and responsibility arising from any reliance placed
                on user content or materials posted by you or any other user on the website, or by anyone
                who may be informed of any of its contents.</p>
              <p>You are responsible for implementing sufficient procedures and checkpoints to satisfy your
                particular requirements for anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our Website for any reconstruction of any lost data.</p>
              <h4><strong>Remuneration</strong></h4>
              <p>User agree to defend, Remunerate and hold harmless the Foundation, its affiliates, licensors
                and service providers, and its and their respective officers, directors, employees,
                contractors, agents, licensors, suppliers, successors and assigns from and against any
                claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including
                reasonable attorneys' fees) arising out of or relating to their violation of these Terms of
                Use or their use of the Website, including, but not limited to, any use of the Website's
                content, services and products other than as expressly authorized in these Terms of Use or
                their use of any information obtained from the Websites.</p>
              <h4><strong>Geographic Restrictions</strong></h4>
              <p>The owner of the Website is based in INDIA. We do not allow or given any kind of specific
                access to any individual or third-party company. Access to the Websites may not be legal by
                any individual or in any countries without permission. If any individual is doing so outside
                INDIA, the person is doing it for their own initiative and are responsible for compliance
                with local laws.</p>
              <h4><strong>Waiver and Severability</strong></h4>
              <p>No waiver of by the Foundation of any term or condition set forth in these Terms of Use shall
                be deemed a further or continuing waiver of such term or condition or a waiver of any other
                term or condition, and any failure of the Foundation to assert a right or provision under
                these Terms of Use shall not constitute a waiver of such right or provision.</p>
              <p>If any provision of these Terms of Use is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the remaining provisions of the Terms
                of Use will continue in full force and effect.</p>
              <h4><strong>Entire Agreement</strong></h4>
              <p>The Terms of Use, our Privacy Policy and terms of conditions for the registration of events
                constitute the sole and entire agreement between you and the Believer Foundation with
                respect to the Websites and supersede all prior and contemporaneous understandings,
                agreements, representations and warranties, both written and oral, with respect to the
                Websites.</p>
              <p>We may update this privacy policy from time to time. We will notify you of any changes by
                posting the new privacy policy on the Site. You are advised to review this privacy policy
                periodically for any changes.</p>
              <h4><strong>Risks</strong></h4>
              <h5>Internet Transmission Risks</h5>
              <p>The risks associated with using an internet-based system include, without limitation, the
                failure of hardware, software and internet connections, either ours or the third-party
                service providers. User acknowledge that they shall not be responsible for any communication
                failures, disruptions, errors, distortions, delays, or losses due to volatility in prices
                they may experience when trading via the Website, howsoever caused. they understand and
                agree that in the event of technical and other limitations, the Believer Tokens' market
                values may be displayed by the Website with a delay, and therefore may not necessarily
                reflect the current, live market value of such Believer Tokens.</p>
              <p>User understand the volatile nature of the market and agree that we are not liable for any
                price fluctuations and the resulting fallacy in the live market value data provided on the
                Website.</p>
              <p>User acknowledge and agree that the market rate information made available via our Website
                may differ from the prevailing market rates that they may gather from other sources. User
                agree and accept that markets can be volatile and prices can fluctuate significantly and the
                prices displayed on the Website are subject to change, and therefore cannot be guaranteed to
                remain unchanged from the time any order is received to the time the actual transaction
                takes place.</p>
              <h5>Trading Risks</h5>
              <p>Users are advised to determine their financial circumstances and risk tolerance before
                trading and carefully consider the risks dealt in particular in the Believer Transaction
                Documents. A considerable loss may be sustained/incurred while trading in Believer Tokens.
                By creating an Account, They acknowledge that they have carefully assessed their financial
                standing and risk tolerance and they confirm that the same is suitable for trading in
                Believer Tokens.</p>
              <h4><strong>Frozen Account</strong></h4>
              <h5>We reserve the right to block or freeze your Account in the following
                cases:</h5>
              <ul>
                <li>Upon a request from the government authorities to comply with an order in accordance
                  with applicable laws;</li>
                <li>If we found the request of the payment service provider.</li>
                <li>Upon a mistake found in your Account;</li>
                <li>Upon suspicious or fraudulent activities or violation of applicable law, or notification
                  from one of the other Users about trades and the information.</li>
                <li>If an Account is suspected of fraud or violation of law, and the User of the Account is
                  unable to clarify the suspicious activities, the funds of such Account will be seized
                  completely. The fund will be used to compensate the victims directly.</li>
                <li>In case the amount of money collected is less than the sum of all the victims, all the
                  money will be distributed to the victims at the rate of damage.</li>
              </ul>
              <h4><strong>Termination</strong></h4>
              <p>We reserve the right to refuse to continue providing user with access to the Website, or
                suspend or freeze their Account, or take any other actions, without notifying the in
                advance, if we discover that you are in violation of these Terms and/or applicable laws,
                including without limitation:</p>
              <h5>Incompetence to contract under these Terms;</h5>
              <ul>
                <li>Engaging in or have engaged in any act that infringes or harms any intellectual property
                  right, right to likeness, right to privacy, reputation, other right or benefit of us,
                  any other Users of the Website, or other third parties;</li>
                <li>Engaging in or have engaged in criminal and/or unlawful activities including, but not
                  limited to, money laundering, terrorist financing, proliferation of weapons of mass
                  destruction, human trafficking, promotion and/or marketing any illegal goods or services
                  (such as violent, obscene, erotic or pornographic content), marketing and/or promoting
                  services violating copyrights, property and/or rights of any person, entity or
                  organization, drugs, narcotics, or hallucinogens, weapons, explosive materials, illegal
                  gambling services, Ponzi, pyramid or any other 'get rich quick' schemes;</li>
                <li>An act of depositing money with us for any purpose other than to perform transactions
                  using the Website;</li>
                <li>An act that violates any of our internal rules;</li>
                <li>An act of transmitting information that includes a computer virus or other harmful
                  computer virus or an act that could interfere with our operation of the Website;</li>
                <li>An act of using another person's name (including that of a temporary establisher) to
                  open or attempt to open a User Account;</li>
                <li>Holding more than 1 (one) Account on the Website;</li>
                <ul>
                  <li>Breach of these Terms or any representation and/or any information provided by you
                    is herein is untrue or inaccurate, and/or we are unable to verify such information;
                  </li>
                  Or
                  <li>Are a resident of either any country or territory that is subject to world-wide or
                    territory wide sanctions or where the use of this Website and/or issue of the Matic
                    Tokens may be illegal or subject to any requirement for registration or licensing;
                  </li>
                  Or
                  <li>Their Account has been suspended or terminated by us for any reason whatsoever.</li>
                </ul>
              </ul>
              <p>Users Account can be blocked or terminated due to inability and/or denial to provide the
                required identity documents and/or any other data necessary for the KYC procedure and/or for
                any other reason. We may, at any time in its sole discretion, reinstate suspended Users.</p>
              <p>We shall not be responsible for compensating user for any damages sustained by them as a
                result of us taking the aforementioned actions.</p>
              <p>In such cases, we will retain the right to perform reversing trades to settle any unsettled
                trades in all outstanding leverage transactions without notifying them in advance. If User
                have been indefinitely suspended, their agree that they shall not register or attempt to
                register with the Website or use the Website in any manner whatsoever until such time that
                they are reinstated. Notwithstanding the foregoing, if they breach these Terms or the
                documents it incorporates by reference, we reserve the right to recover any amounts due and
                owed by them to us and to take strict legal action as deemed necessary.</p>
              <h4><strong>Taxes</strong></h4>
              <p>User shall undertake to pay all applicable taxes, which may be charged for the use of the
                Website and any transaction that they may undertake.</p>
              <p>By using this Website, they accept and agree that it is their sole responsibility to pay all
                taxes due for using the Website and any products availed and services obtained thereunder,
                to the appropriate tax authorities in their place of residence as required by the applicable
                law. We are not responsible for any violation (or attempt to violate) made by them to avoid
                fulfilling their tax obligations. We are not responsible for determining whether taxes apply
                to their transactions or for collecting, reporting, withholding, or remitting any taxes
                arising from any transaction.</p>
              <h4><strong>Contact Us</strong></h4>
              <p>If you have any questions about our privacy policy, or how your data is being collected and
                processed, please contact<br />
                <strong>info@ignitechain.network</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
    </>
  );
}

export default TermsCondtions;