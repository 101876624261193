function IgniteStudioGetReady() {
    return (
        <>

            <div className="address-section-one pt-130 lg-pt-100 mb-140">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 col-md-9 m-auto">
                            <div className="title-style-one text-center mb-50" data-aos="fade-up">
                                <div className="sc-title-two">Be a pro with us</div>
                                <h2 className="main-title">Join Ignite Studio Now It’s free and open for all</h2>
                            </div> {/* /.title-style-one */}
                            <div className="text-center" data-aos="fade-up" data-aos-delay={150}><a target="_blank"  href="https://docs.google.com/forms/d/1DrTofQNC12gZ9nXSWwCOa6tHSoNTqXb-A_sJn86ARMM/viewform?edit_requested=true" className="btn-four ripple-btn">Get Started <i className="fas fa-chevron-right" /></a></div>
                        </div>
                    </div>
                </div>
                <img src="images/assets/bg_05.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_01.svg" alt="" className="shapes shape-two" />
                <img src="images/shape/shape_02.svg" alt="" className="shapes shape-three" />
                <img src="images/shape/shape_02.svg" alt="" className="shapes shape-four" />
                <img src="images/shape/shape_03.svg" alt="" className="shapes shape-five" />
            </div>


        </>


    )
}

export default IgniteStudioGetReady;