function Preloader(){   
    return(
        <section>
        <div id="preloader">
          <div id="ctn-preloader" className="ctn-preloader">
            <div className="animation-preloader">
              <div className="icon"><img src="images/ignite_logo.png" alt="" className="m-auto d-block" width={80} /></div>
              <div className="txt-loading mt-2">
                <span data-text-preloader="I" className="letters-loading">
                  I
                </span>
                <span data-text-preloader="G" className="letters-loading">
                  G
                </span>
                <span data-text-preloader="N" className="letters-loading">
                  N
                </span>
                <span data-text-preloader="I" className="letters-loading">
                  I
                </span>
                <span data-text-preloader="T" className="letters-loading">
                  T
                </span><span data-text-preloader="E" className="letters-loading">
                  E
                </span>
              </div>
            </div>	
          </div>
        </div>
        {/* <div>
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img src="images\igt-flash-sale.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      </section>
      
    )
}
export default Preloader;