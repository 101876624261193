function IGNITECommunityjoinpage() {
    return (
        <>

<div className="partner-section-two mt-130 lg-mt-70 lg-pb-20">
  <div className="container">
    <div className="title-style-one text-center" data-aos="fade-up">
      {/* <div class="sc-title-four">over 150k+ client</div>	 */}
      <h2 className="main-title md">Let’s Join<span className="fw-bold"> OUR COMMUNITY </span></h2>
      <p>Ignite is a global, decentralized network with validators and community members from all around the world.Join the discussions on Discord and stay up to date with the latest news and announcements.</p>
    </div> {/* /.title-style-one */}
    <div className="row mt-50">
      <div className="col-12 m-auto">
        <ul className="style-none text-center">
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://twitter.com/ignitechain" className="d-flex align-items-center justify-content-center"><img src="images/logo/twitter.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://www.facebook.com/IgniteChainOfficial/" className="d-flex align-items-center justify-content-center"><img src="images/logo/facebook.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a target="_blank" href="https://t.me/IgniteChain" className="d-flex align-items-center justify-content-center"><img src="images/logo/telegram.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={100}><a target="_blank" href="https://discord.gg/DNvJGxdezJ" className="d-flex align-items-center justify-content-center"><img src="images/logo/discord.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={200}><a target="_blank" href="https://github.com/ignitechain" className="d-flex align-items-center justify-content-center"><img src="images/logo/github.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/" className="d-flex align-items-center justify-content-center"><img src="images/logo/gitbook.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={300}><a target="_blank" href="https://ignitechain.medium.com/" className="d-flex align-items-center justify-content-center"><img src="images/logo/medium.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://www.reddit.com/r/ignitechain/" className="d-flex align-items-center justify-content-center"><img src="images/logo/reddit.png" alt="" /></a></li>
          {/* <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://bitcointalk.org/" className="d-flex align-items-center justify-content-center"><img src="images/logo/bitcointalk.png" alt="" /></a></li> */}
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://www.instagram.com/ignitechain/" className="d-flex align-items-center justify-content-center"><img src="images/logo/insta.png" alt="" /></a></li>
          <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a target="_blank" href="https://www.youtube.com/channel/UCe2PDO2SBh9psGiqW6-CMIQ" className="d-flex align-items-center justify-content-center"><img src="images/logo/youtube.png" alt="" /></a></li>
          {/* <a href="#"><i class="fab fa-twitter display-6  me-3"></i></a>
								<a href="#"><i class="fab fa-medium-m display-6 me-3" ></i></a>
								<a href="#"><i class="fab fa-youtube display-6 me-3" ></i></a>
								<a href="#"><i class="fab fa-reddit display-6 me-3" ></i></a>
								<a href="#"><i class="fab fa-telegram-plane display-6 me-3" ></i></a>
								<a href="#"><i class="fab fa-github-alt display-6 " ></i></a> */}
        </ul>
      </div>
    </div>
  </div> {/* /.container */}
</div>


        </>

    )
}

export default IGNITECommunityjoinpage;

