function WithdrawSidebar() {
    return (
        <>


            <div className="sidebar">
                <div className="logo-details">
                    <i className="bx bx-menu" id="btn" />
                </div>
                <ul className="nav-list px-0">
                    <li>
                        <a href="/validator_delegators">
                            <i className="bx bxs-dashboard" />
                            <span className="links_name">Dashbord</span>
                        </a>
                        <span className="tooltip">Dashbord</span>
                    </li>
                    <li>
                        <a href="#" className="text-white">
                            <i className="bx bx-money" />
                            <span className="links_name">Level Income</span>
                        </a>
                        <span className="tooltip">Level Income</span>
                    </li>
                    <li>
                        <a href="#" className="text-white">
                            <i className="bx bx-list-minus" />
                            <span className="links_name">Staking List</span>
                        </a>
                        <span className="tooltip">Staking List</span>
                    </li>
                </ul>
            </div>



        </>


    )
}

export default WithdrawSidebar;