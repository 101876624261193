function DelegetorsArea() {
    return (
        <>

            <div className="fancy-feature-twentyFour mt-50">
                <div className="container">
                    <div className="row gx-xxl-5">
                        <div className="col-12">
                        </div>
                        <div className="col-lg-5 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate order-lg-2" data-aos="fade-up">
                            <div className="block-style-four become_sub_validators_area">
                                {/* <div class="icon d-flex align-items-end justify-content-md-start"><img width="45" height="49" src="http://fastwpdemo.com/newwp/sinco/wp-content/uploads/2022/02/icon_33.svg" class="attachment-sinco_49x49 size-sinco_49x49 wp-post-image" alt="" loading="lazy"></div> */}
                                <div className="d-flex">
                                    <div className="col-7 text-start d-flex ">
                                        <p>ETHEREUM WALLET BALANCE</p>
                                    </div>
                                    <div className="col-5 text-lg-end">
                                        <h4 className="fs-6 fw-bold">0 IGT</h4>
                                        <p>$ 0.000</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-7 text-start d-flex">
                                        <p>TOTAL STAKE</p>
                                    </div>
                                    <div className="col-5 text-lg-end">
                                        <h4 className="fs-6 fw-bold">267655.01 IGT</h4>
                                        <p>$ 169637.336</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-7 text-start d-flex">
                                        <p>SELF STAKE</p>
                                    </div>
                                    <div className="col-5 text-lg-end">
                                        <h4 className="fs-6 fw-bold">9716.8 IGT</h4>
                                        <p>$ 6158.420</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-7 text-start d-flex">
                                        <p>TOTAL WITHDRAWN REWARDS</p>
                                    </div>
                                    <div className="col-5 text-lg-end">
                                        <h4 className="fs-6 fw-bold">0 IGT</h4>
                                        <p>$ 0.000</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-7 text-start d-flex">
                                        <p>HEIMDALL FEES</p>
                                    </div>
                                    <div className="col-5 text-lg-end">
                                        <h4 className="fs-6 fw-bold">1 IGT</h4>
                                        <p>$ 0.634</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-7 text-start d-flex">
                                        <p>TOTAL REWARDS EARNED</p>
                                    </div>
                                    <div className="col-5 text-lg-end">
                                        <h4 className="fs-6 fw-bold">1185.69 IGT</h4>
                                        <p>$ 751.480</p>
                                    </div>
                                </div>
                            </div>
                            {/* /.block-style-four */}
                        </div>
                        <div className="col-lg-7 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up">
                            <div className="block-style-four become_sub_validators_area">
                                <div className="row pt-1 pb-1">
                                    <div className="col-lg-12 col-md-12 col-12 flex-container  d-md-flex justify-content-between justify-content-md-start align-self-center">
                                        <div className="m-3">Delegators</div>
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            {/* Lorem ipsum dolor sit amet. */}
                                            <div className="tab-content" id="pills-tabContent data">
                                                <div className="tab-pane fade show active" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab">
                                                    <div className="row gray_background border_top" style={{ margin: '3px' }}>
                                                        <div className="col-lg-7 col-md-6 col-12">
                                                            <p className="tab_heading">Accounts</p>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <p className="tab_heading_first ">Matic Staked</p>
                                                        </div>
                                                        <div className="border_bottom" />
                                                    </div>
                                                    <div className="row mt-4 border-bottom  ml-0 mr-0 justify-content-sm-center">
                                                        <div className="col-lg-1 col-md-1 col-12">
                                                            <i className="fa fa-heart table_icon" />
                                                        </div>
                                                        <div className="col-lg-5 col-md-11 col-12">
                                                            <p className="table_icon_heading text-break">0xb0628......0e1a6da3</p>
                                                        </div>
                                                        <div className="col-lg-6 col-md-3 col-12 text-center">
                                                            <p className="table_contents">74,034.421 MATIC%</p>
                                                        </div>
                                                    </div><div className="row mt-4 border-bottom  ml-0 mr-0 justify-content-sm-center">
                                                        <div className="col-lg-1 col-md-1 col-12">
                                                            <i className="fa fa-heart table_icon" />
                                                        </div>
                                                        <div className="col-lg-5 col-md-11 col-12">
                                                            <p className="table_icon_heading text-break">0xb0628......0e1a6da3</p>
                                                        </div>
                                                        <div className="col-lg-6 col-md-3 col-12 text-center">
                                                            <p className="table_contents">74,034.421 MATIC%</p>
                                                        </div>
                                                    </div><div className="row mt-4 border-bottom  ml-0 mr-0 justify-content-sm-center">
                                                        <div className="col-lg-1 col-md-1 col-12">
                                                            <i className="fa fa-heart table_icon" />
                                                        </div>
                                                        <div className="col-lg-5 col-md-11 col-12">
                                                            <p className="table_icon_heading text-break">0xb0628......0e1a6da3</p>
                                                        </div>
                                                        <div className="col-lg-6 col-md-3 col-12 text-center">
                                                            <p className="table_contents">74,034.421 MATIC%</p>
                                                        </div>
                                                    </div><div className="row mt-4 border-bottom  ml-0 mr-0 justify-content-sm-center">
                                                        <div className="col-lg-1 col-md-1 col-12">
                                                            <i className="fa fa-heart table_icon" />
                                                        </div>
                                                        <div className="col-lg-5 col-md-11 col-12">
                                                            <p className="table_icon_heading text-break">0xb0628......0e1a6da3</p>
                                                        </div>
                                                        <div className="col-lg-6 col-md-3 col-12 text-center">
                                                            <p className="table_contents">74,034.421 MATIC%</p>
                                                        </div>
                                                    </div><table id="example">
                                                        <tbody><tr>
                                                        </tr>
                                                            <tr>
                                                            </tr>
                                                            <tr>
                                                            </tr>
                                                            <tr>
                                                            </tr>
                                                        </tbody></table>
                                                    {/* <nav aria-label="Page navigation example">
													<ul class="pagination float-end pr-3 pt-3">
														<li class="page-item">
														<a class="page-link" href="#" aria-label="Previous">
															<span aria-hidden="true">&laquo;</span>
															<span class="sr-only">Previous</span>
														</a>
														</li>
														<li class="page-item"><a class="page-link" href="#">1</a></li>
														<li class="page-item"><a class="page-link" href="#">2</a></li>
														<li class="page-item"><a class="page-link" href="#">3</a></li>
														<li class="page-item">
														<a class="page-link" href="#" aria-label="Next">
															<span aria-hidden="true">&raquo;</span>
															<span class="sr-only">Next</span>
														</a>
														</li>
													</ul>
														
												</nav> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /.block-style-four */}
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default DelegetorsArea;