function Jobinformation() {
    return (
        <>


            <div className="pr-details-one mt-120 lg-mt-90 mb-170 lg-mb-100">
                <div className="container">
                    <div className="wrapper ps-xxl-4 pe-xxl-4 ms-xxl-5 me-xxl-5 ">
                        <div className="row gx-xxl-5">
                            <div className="col-lg-7">
                                <div className="text-wrapper pt-30 md-pt-20">
                                    <ul className="line-height pt-0 job_information_content">
                                        <h5 className="text-center">Node.js Developer Responsibilities:</h5>
                                        <li>Contribute to team and organizational improvements in process and infrastructure. </li>
                                        <li>Know HTML, CSS, JavaScript, PHP, and other relevant coding languages. </li>
                                        <li>Collaborating with front-end developers on the integration of elements. </li>
                                        <li>Implementing effective security protocols, data protection measures, and storage solutions.</li>
                                        <li>Running diagnostic tests, repairing defects, and providing technical support. </li>
                                        <li>Documenting Node.js processes, including database schemas, as well as preparing reports.</li>
                                        <li>Ready to work with other technology. </li>
                                        <li>Recommending and implementing improvements to processes and technologies. Keeping informed of advancements in the field of Node.js development.</li>

                                    </ul>


                                    <ul className="line-height pt-0 job_information_content">
                                        <h5 className="text-center">Node.js Developer Requirements:</h5>
                                        <li>Bachelor's degree in computer science, information science, or similar.</li>
                                        <li>Extensive knowledge of JavaScript, web stacks, libraries, and frameworks.</li>
                                        <li>Knowledge of front-end technologies such as HTML5 and CSS3.</li>
                                        <li>Superb interpersonal, communication, and collaboration skills.</li>
                                        <li>Exceptional analytical and problem-solving aptitude.</li>
                                        <li>Great organizational and time management skills.</li>
                                        <li>Availability to resolve urgent web application issues outside of business hours.</li>

                                    </ul>


                                    {/* <p className="text-justify line-hieght"><b>Key Responsibilities :</b> Experience in web application security assessments,
                                        hands on techniques for identifying SQL injections, XSS, CSRF, authentication,
                                        OWASP top issues, Good knowledge of security technologies for secure software development
                                        such as e-commerce apps, apis, authentication techniques and protocols etc. Experience on both
                                        commercial and open source tools Cenzic Hailstorm, Burpsuite, AppScan, WebInspect, Appspider,
                                        sqlmap, OWASP ZAP, d Experience in BEEF, MetaSploit and other exploitation framework</p> */}
                                    {/* <p className="text-justify line-hieght"><b>Technical Experience :</b>  a Looking for candidates with Platform experience
                                        especially on enterprise platformb Proven experience in identifying and exploiting business logic and framework
                                        related vulnerabilities c Vast experience in removing false positives, analyzing dynamic scan webinspect, appscan
                                        reportsd Knowledge of Secure SDLC and Security standards like OWASP, CWE, NIST, OSSTMMd Provide expert advice and
                                        recommendation to application development team as well as vendor</p> */}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="project-info ps-xl-5 mt-30">
                                    <div className="row gx-xxl-5">
                                        <div className="col-sm-6 ">
                                            {/* <div class="pt-title">DATe</div> */}
                                            <div className="pt-text">Position</div>
                                            {/* <p>Application Security Tester / Penetration Tester</p> */}
                                            <p className="job_description">Node.js Developer</p>
                                        </div>
                                        <div className="col-sm-6 ">
                                            {/* <div class="pt-title">Client</div> */}
                                            <div className="pt-text">Location</div>
                                            <p className="job_description">Remote</p>
                                        </div>
                                        <div className="col-sm-4">
                                            {/* <div class="pt-title">Project Type</div> */}
                                            <div className="pt-text">Experience</div>
                                            <p className="job_description">1 - 2 years  </p>
                                        </div>
                                        <div className="col-sm-3">
                                            {/* <div class="pt-title">Duration</div> */}
                                            <div className="pt-text">Vacancy</div>
                                            <p className="job_description">1</p>
                                        </div>
                                        <div className="col-sm-5">
                                            {/* <div class="pt-title">Duration</div> */}
                                            <div className="pt-text">Date</div>
                                            <p className="job_description">2022-05-10</p>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="text-center">
                                                <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfkHU2IuOU4aIoX2-hDuQFY6z1irq9q8lFx5jNw_a5VmGtegw/viewform" type="button" className="btn-five px-5">Apply For Job</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <ul class="d-flex social-icon style-none mt-20">
										<li><a href="#"><i class="fab fa-pinterest"></i></a></li>
										<li><a href="#"><i class="fab fa-twitter"></i></a></li>
										<li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
									</ul> */}
                                </div> {/* /.project-info */}
                            </div>
                        </div>
                    </div> {/* /.wrapper */}
                </div>
            </div>



        </>






    )
}

export default Jobinformation;