function FooterexploredApps() {
    return (
        <>

            <div className="pricing-section-two position-relative mt-150 lg-mt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-6 col-md-8 m-auto">
                            <div className="title-style-one text-center mb-20" data-aos="fade-up">
                                {/* <div class="sc-title-five">Pricing & Plan</div> */}
                                <h2 className="fs-2 fw-bold"> Explore infinite of dApps and discover in one platform </h2>
                                <p className="info mt-15 lg-mt-50" data-aos="fade-up">Find your favorite decentralized apps,
                                    without leaving your wallet.</p>
                                {/* <p class="small mt-25 " data-aos="fade-up">We've done it carefully and simply.</p> */}
                            </div> {/* /.title-style-one */}
                        </div>
                    </div>
                    <div className="col-md-12 d-flex flex-wrap justify-content-center">
                        <div className="pl-3 pb-10">
                            <a target="_blank" href="https://docs.google.com/forms/d/1YfsH6v4nuQo05dv8LltinZAyQSfU5SKbh8keUwts9Jc/viewform?edit_requested=true" className="btn-five py-1 px-4 ripple-btn rounded-pill mr-3"> Submit a dapp </a>
                        </div>
                        <div className="mx-2">
                            <a className="btn-five py-1 px-4 ripple-btn rounded-pill"> Submit a Token </a>
                        </div>
                    </div>
                </div>
                {/* <img src="images/shape/shape_34.svg" alt="" class="shapes shape-one"> */}
                <img src="images/shape/2.png" alt="" className="shapes shape-1" />
                <img src="images/shape/1.png" alt="" className="shapes shape-2" />
            </div>


        </>


    )
}

export default FooterexploredApps;