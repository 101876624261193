import Header from ".././header/index";
import Ignitestudiomainheading from "./studiodata";
import Experties from "./experties";
import Datasolution from "./datasolutions";
import IGNITEStudioProcess from "./ignitestudioprocess";
import IGNITEStudioRecentWork from "./Ignitestudiorecentwork";
import IgniteStudioGetReady from "./ignitestudiogetready";
import Footer from '.././footer/index';


function IgniteStudio() {
    document.title="Ignite-Studio - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
            <Header/>
            <Ignitestudiomainheading/>
            <Experties/>
            <Datasolution/>
            <IGNITEStudioProcess/>
            <IGNITEStudioRecentWork/>
            <IgniteStudioGetReady/>
            <Footer />     
     
            </div>


        </div>
    );
}
export default IgniteStudio;