import Header from ".././header/index";
import IgtSolutionsHeading from "./igtsolutionheading";
import IgtSolutionsCounter from "./igtsolutioncounter";
import IgtSolutionsDescriptionProblem from "./igtsolutiondescriptionproblem";
import IgtSolutionsDescriptionSolution from "./igtsolutiondescriptionsolutions";
import IgtSolutionsDescriptionContactUs from "./solutiondescriptioncontactus";
import Footer from '.././footer/index';

function IgniteSolutionsDescription () {
    document.title="Solutions-Description - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <IgtSolutionsHeading/>
                <IgtSolutionsCounter/>
                <IgtSolutionsDescriptionProblem/>
                <IgtSolutionsDescriptionSolution/>
                <IgtSolutionsDescriptionContactUs/>
                <Footer />

            </div>


        </div>
    );
}
export default IgniteSolutionsDescription;