
function IGNITEBlockchain() {
  return (
    <> <div className="fancy-feature-eighteen position-relative   lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100" style={{height: 700}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
            <div className="block-style-two aos-init aos-animate" data-aos="fade-left">


              <div className="title-style-one text-left" data-aos="fade-up">
                <div className="sc-title-three"> blockchain</div>

                {/* <h2 className="main-title md">Partners and Investors </h2> */}
              </div> {/* /.title-style-one */}

              <div className="title-style-three">
                {/* <div class="sc-title">Over 150k+ Client</div> */}
                <h2 className="main-title"><span className="ignite_site_main_heading">IGNITE</span>
                &nbsp;  Blockchain</h2>
              </div> {/* /.title-style-three */}
              <p className="pt-20 pb-25 lg-pb-20 text-justify">Ignite Blockchain is a public, permissionless, Ethereum Virtual Machine (EVM) compatible blockchain running on the world’s leading Proof-of-Stake Voting consensus mechanism. The Ignite blockchain is open to the public, allowing participants from around the world to join in to secure and verify the record reflect accurate state of affairs.
              </p>
              {/* <a href="#" className="btn-eight mt-50 lg-mt-30 mb-350">Kick Start Your Token in Minute</a> */}
            </div> {/* /.block-style-two */}
          </div>
        </div>
      </div> {/* /.container */}
      <div className="illustration-holder aos-init aos-animate"  style={{top:0}}  data-aos="fade-right">
      <img src="images/assets/Blockchain.png" alt="" className="w-100 main-illustration ignite_blockchain_image"/>
        {/* <img src="images/assets/ils_14.webp" alt="" className="w-100 main-illustration" />
        <img src="images/assets/ils_14_1_11zon.webp" alt="" className="shapes shape-one aos-init aos-animate" data-aos="fade-down" />
        <img src="images/assets/ils_14_2.svg" alt="" className="shapes shape-two aos-init aos-animate" data-aos="fade-down" data-aos-delay={100} />
        <img src="images/assets/ils_14_3.svg" alt="" className="shapes shape-three aos-init aos-animate" data-aos="fade-down" data-aos-delay={200} />
        <img src="images/assets/ils_14_4.svg" alt="" className="shapes shape-four" />
        <img src="images/assets/ils_14_5.svg" alt="" className="shapes shape-five" />
        <img src="images/assets/ils_14_6.webp" alt="" className="shapes shape-six" />
        <img src="images/assets/ils_14_7.webp" alt="" className="shapes shape-seven" /> */}
      </div> {/* /.illustration-holder */}
      <div className="shapes oval-one" />
      <div className="shapes oval-two" />
      <div className="shapes oval-three" />
    </div></>
  );
}

export default IGNITEBlockchain;