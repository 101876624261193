function ValidatorIgnitenetwork() {
    return (
        <>

            <div className="fancy-feature-fourteen pt-100 mt-5">
                <div className="container">
                    <div className="d-flex justify-content-center mx-5">
                        {/* <div class="col-xl-7 col-md-8 order-md-last"> */}
                        <div className="block-style-two text-center" data-aos>
                            <div className="title-style-one text-center">
                                <div className="sc-title-five">Ignite Network</div>
                                <h2 className="display-4 fw-bold">Validators is the spine of Ignite network.</h2>
                                {/* <h4 class="display-4 font-weight-bold">Earn IGT</h4> */}
                            </div> {/* /.title-style-one */}
                            <p className="pt-10 pb-20 mx-5 mt-15">By processing transactions and participating consensus, apiece validator helps make Ignite the most censorship-resistant and highest-performance blockchain network globally.</p>
                            {/* <ul class="style-none list-item color-rev">
									<li>Various analysis options.</li>
									<li>Page Load (time, size, number of requests).</li>
									<li>Adance Data analysis operation.</li>
								</ul> */}
                            {/* <a href="about-us2.html" class="btn-six ripple-btn mt-50 lg-mt-30">More about us</a> */}
                        </div> {/* /.block-style-two */}
                        {/* </div> */}
                        {/* <div class="col-xl-7 col-md-6 order-md-first"> */}
                        {/* <div class="display-1 text-center">
								<span class="font-weight-bold">14.3%
								<br>APR</span>
							</div> */}
                        {/* <div class="illustration-holder d-inline-block position-relative sm-mt-50">
								<img src="images/assets/ils_12.svg" alt="">
								<img src="images/assets/ils_12_1.svg" alt="" class="shapes shape-one" data-aos="fade-down" data-aos-duration="1500">
								<img src="images/assets/ils_12_1.svg" alt="" class="shapes shape-two" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
								<img src="images/assets/ils_12_2.svg" alt="" class="shapes shape-three" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
								<img src="images/assets/ils_12_3.svg" alt="" class="shapes shape-four">
								<img src="images/assets/ils_12_4.svg" alt="" class="shapes shape-five" data-aos="fade-left" data-aos-duration="1500">
							</div>  */}
                        {/* /.illustration-holder */}
                        {/* </div> */}
                    </div>
                </div> {/* /.container */}
            </div>


        </>

    )
}

export default ValidatorIgnitenetwork;