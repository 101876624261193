function StakingOnIgnitepose() {
    return (
        <>

            <div className="fancy-feature-nineteen position-relative pt-50 lg-pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 sm-mb-60">
                            <div className="block-style-thirteen" data-aos="fade-left">
                                {/* <div class="title-style-three pb-15"> */}
                                {/* <div class="sc-title">WORK PROCESS</div> */}
                                {/* <h2 class="main-title">Staking on IGNITE PoS</h2> */}
                                {/* </div>  */}
                                {/* /.title-style-three */}
                                <ul className="style-none list-item">
                                    
                                    <li data-aos="fade-up" data-aos-delay={50}>
                                        <div className="numb2 tran3s"><img src="images/icon/Enhance-its-network-security_1.png" height="45px" width="45px" alt="" /></div>
                                        <h6 className="font-weight-bold">Enhance its network security</h6>
                                        <span>Ignite makes it possible for any actors that do not know each other and have no reason to trust one another, to interact and transact, securely it's a platform for building trust where none might naturally exist.</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-delay={50}>
                                        <div className="numb2 tran3s"><img src="images/icon/xtremely-Scalable-And-Environmentally-Sustainable_1.png" height="45px" width="45px" alt="" /></div>
                                        <h6 className="font-weight-bold">Extremely Scalable And Environmentally Sustainable</h6>
                                        <span>Ignite to scale to global requirements with minimal energy requirements. Ignite does not require exponentially more energy to increase performance and add blocks.</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-delay={50} className="text-justify">
                                        <div className="numb2 tran3s"><img src="images/icon/Open-And-Incentivized-Participation_1.png" height="45px" width="45px" alt="" /></div>
                                        <h6 className="font-weight-bold">Open And Incentivized Participation</h6>
                                        <span>With a committed community at its core, Ignite is an open-source project developed through open participation to ensure the longevity and health of the network Ignite features an incentive mechanism that rewards users - either as stake pool operators or stake delegators - for their participation.</span>
                                    </li>
                                    {/* <div class="col-lg-12 col-md-12 col-sm-12 my-5"> */}
                                    {/* </div> */}
                                    {/* <li data-aos="fade-up" data-aos-delay="50">
										<div class="numb2 tran3s"><img src="images/gallery/ignite.png" height="45px" width="45px" alt=""></div>
										<h6 class="font-weight-bold">Open And Incentivized Participation</h6>
										<span>With a committed community at its core, Ignite is an open-source project developed through open participation to ensure the longevity and health of the network Ignite features an incentive mechanism that rewards users - either as stake pool operators or stake delegators - for their participation.</span>
									</li> */}
                                    <li data-aos="fade-up" data-aos-delay={50}>
                                        <div className="numb2 tran3s"><img src="images/icon/Simple-experience_1.png" height="45px" width="45px" alt="" /></div>
                                        <h6 className="font-weight-bold">Simple experience</h6>
                                        <span>Ignite provide Smooth onboarding, performance tracking, and reporting. See rewards, account balances, and technical statistics on one dashboard.</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-delay={50}>
                                        <div className="numb2 tran3s"><img src="images/icon/Earn-Rewards_1.png" height="45px" width="45px" alt="" /></div>
                                        <h6 className="font-weight-bold">Earn Rewards</h6>
                                        <span>Ignite rewards are delivered on top of earnings from transaction fees. As a validator, you get to set your commission for accepting delegations to your node.</span>
                                    </li>
                                </ul>
                            </div> {/* /.block-style-thirteen */}
                        </div>
                        <div className="col-md-5 mt-30 sm-mt-50">
                            <div className="block-style-two aos-init aos-animate" data-aos="fade-left">
                                <div className="title-style-three text-justify pt-140 sm-pt-10">
                                    {/* <div class="sc-title">Over 150k+ Client</div> */}
                                    <h3 className="fs-3 fw-bold text-center">Staking on
                                        IGNITE PoS</h3>
                                    <span className="pt-2 fs-5 pb-25 lg-pb-20  text-sm-center">Along with providing enterprise-grade blockchain solution to MSMEs, Ignite chain is designed to achieve high performance in the area of web3, decentralized finance and more specifically regarding cross-chain connectivity and digital assets management.</span>
                                    <img src="images/gallery/staking.png" className=" mt-100" alt="" />
                                </div> {/* /.title-style-three */}
                            </div> {/* /.block-style-two */}
                        </div>
                    </div>
                </div> {/* /.container */}
                {/* <div class="illustration-holder" data-aos="fade-left">
					<img src="images/assets/ils_15.svg" alt="" class="w-100 main-illustration">
					<img src="images/assets/ils_15_1.svg" alt="" class="shapes shape-one">
					<img src="images/assets/ils_15_2.svg" alt="" class="shapes shape-two">
					<img src="images/assets/ils_15_3.svg" alt="" class="shapes shape-three">
					<img src="images/assets/ils_15_4.svg" alt="" class="shapes shape-four">
					<img src="images/assets/ils_15_5.svg" alt="" class="shapes shape-five" data-aos="fade-down" data-aos-delay="200" data-aos-duration="2000">
					<img src="images/assets/ils_15_6.svg" alt="" class="shapes shape-six" data-aos="fade-down" data-aos-delay="100" data-aos-duration="2000">
					<img src="images/assets/ils_15_7.svg" alt="" class="shapes shape-seven" data-aos="fade-down" data-aos-duration="2000">
				</div> */}
                {/* /.illustration-holder */}
                {/* <div class="shapes oval-one"></div>
				<div class="shapes oval-two"></div>
				<img src="images/shape/shape_35.svg" alt="" class="shapes bg-shape"> */}
            </div>


        </>





    )
}

export default StakingOnIgnitepose;