import React, { Component } from "react";
import Slider from "react-slick";

export default class PreviousNextMethods extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: !0,
      arrows: !1,
      centerPadding: "0px",
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: !0,
      autoplaySpeed: 3e3,
      responsive: [{ breakpoint: 768, settings: { slidesToShow: 1 } }]
    };
    return (
      <>    <div className="feedback-section-two  xl-mt-100 md-mt-60" data-aos="fade-up" id="tools">
        {/* <img src="images/shape/shape_08.svg" alt="" className="shapes shape-one" />
      <img src="images/shape/shape_09.svg" alt="" className="shapes shape-two" /> */}
        <div className="container" style={{ marginTop: -156 }}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-4 col-md-5 col-sm-8">

              <div className="title-style-one align-items-center" data-aos="fade-up">
                {/* <div className="sc-title-three">over 150k+ client</div> */}

                {/* <h2 className="main-title md">Partners and Investors </h2> */}
              </div> {/* /.title-style-one */}


              <div className="title-style-one text-center text-sm-start xs-pb-20">
                <h2 className="main-title">Recent Dapps built with Ignite Studio</h2>
                {/* <h2 class="main-title">Check Some of Our Recent Work.</h2> */}
                {/* <p>The Product Layer provides a variety of ways to interact with the Ignite blockchain ecosystem including Swap, Cross Chain Wallet, Explorer,Dex,NFT Market, Token Issuer and more.								</p> */}
              </div> {/* /.title-style-one */}
            </div>
            <div className="col-xl-6 col-lg-8 col-md-7 col-sm-4 ms-auto d-flex justify-content-center justify-content-sm-end">
              {/* <ul className="slider-arrows slick-arrow-two d-flex style-none">
              <li className="prev_f1 slick-arrow ripple-btn"  onClick={this.previous}><i className="bi bi-arrow-left" /></li>
              <li className="next_f1 slick-arrow ripple-btn" onClick={this.next}><i className="bi bi-arrow-right" /></li>
               <li className="prev_f1 slick-arrow ripple-btn"  onClick={this.previous}></li>
              <li className="next_f1 slick-arrow ripple-btn" onClick={this.next}></li>
            </ul> */}


            </div>
          </div>
        </div>
        {/* /.container */}
        <div className="inner-content mt-60 md-mt-40">
          <div className="slider-wrapper">
            <div className="feedback_slider_two">
              <Slider ref={c => (this.slider = c)} {...settings}>
                <div className="item">
                  <div className="feedback-block-two d-sm-flex">
                    {/* <div className="justify-content-lg-center"> */}
                    <div className="img-meta">
                      {/* <img src="images/tools/dex_blc.png" alt="" /> */}
                      <img src="images/tools/Dex.png" alt="" />

                    </div>
                    <div className="text-wrapper">
                      {/* <div className="h3">DEX</div> */}
                      <div className="h3">gDEX</div>
                      {/* <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p> */}
                      <p>A decentralized exchange to swap crypto assets on Ignite Chain. Liquidity providers are incentivized with extra benefits in their staked LP tokens. </p>
                      <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                        {/* <span>Continue Reading</span> */}
                        {/* <img src="images/gallery/img_02.jpg" alt="" className="arrow" /> */}
                      </a>
                    </div>
                  </div>
                </div>

                {/* /.text-wrapper */}
                {/* /.feedback-block-two */}
                <div className="item">
                  <div className="feedback-block-two d-sm-flex">
                    {/* <div className="justify-content-lg-center"> */}
                    <div className="img-meta">

                      {/* <img src="images/tools/explorer_blc.png" alt="" /> */}
                      <img src="images/tools/Explorer.png" alt="" />
                    </div>
                    <div className="text-wrapper">
                      {/* <div className="h3">Explorer</div> */}
                      <div className="h3">TOKONSWAP</div>
                      {/* <p>We’v 9,000 agents across around the country, Find agents near your neighborhood.</p> */}
                      <p>Tokonswap is a p2p NFT swapping platform where all NFT collectors and traders exchange their NFTs with each other.</p>
                      <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                        {/* <span>Continue Reading</span> */}
                        {/* <img src="images/icon/icon_14.svg" alt="" className="arrow" /> */}
                      </a>
                    </div>
                    {/* /.text-wrapper */}
                  </div>

                  {/* /.feedback-block-two */}
                </div>
                <div className="item">
                  <div className="feedback-block-two d-sm-flex">
                    {/* <div className="justify-content-lg-center"> */}
                    <div className="img-meta">
                      {/* <img src="images/tools/nft-market_blc.png" alt="" /> */}
                      <img src="images/tools/NFT.png" alt="" />


                    </div>
                    <div className="text-wrapper">
                      <div className="h3">EXPLORER</div>
                      <p>Get latest information of transaction details, hash, blocks, nodes and other blockchain insights. </p>
                      <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                        {/* <span>Continue Reading</span> */}
                        {/* <img src="images/icon/icon_14.svg" alt="" className="arrow" /> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feedback-block-two d-sm-flex">
                    {/* <div className="justify-content-lg-center"> */}
                    <div className="img-meta">

                      {/* <img src="images/tools/swap_blc.png" alt="" /> */}
                      <img src="images/tools/swap.png" alt="" />
                    </div>
                    <div className="text-wrapper">
                      <div className="h3">TOKEN ISSUER</div>
                      <p>Create a IGT-20 token easily without writing any line of code using zk-token creator.</p>
                      <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                        {/* <span>Continue Reading</span> */}
                        {/* <img src="images/icon/icon_14.svg" alt="" className="arrow" /> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feedback-block-two d-sm-flex">
                    {/* <div className="justify-content-lg-center"> */}
                    <div className="img-meta">
                      {/* <img src="images/tools/token-issuer_blc.png" alt="" /> */}
                      <img src="images/tools/token-user.png" alt="" />

                    </div>
                    <div className="text-wrapper">
                      <div className="h3">IGNITE WALLET</div>
                      <p>A crypto wallet to send, receive and store your cryptocurrency assets from different blockchain network.  </p>
                      <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                        {/* <span>Continue Reading</span> */}
                        {/* <img src="images/icon/icon_14.svg" alt="" className="arrow" /> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feedback-block-two d-sm-flex ">
                    {/* <div className="justify-content-lg-center"> */}
                    <div className="img-meta">
                      {/* <img src="images/tools/wallet_blc.png" alt="" /> */}
                      <img src="images/tools/wallet.png" alt="" />

                    </div>
                    <div className="text-wrapper">
                      <div className="h3">Your Dapp</div>
                      <p>This spot is reserved for your Dapp.
                        Join Ignite Studio Now
                      </p>
                      <a href="#" className="read-btn d-flex align-items-center justify-content-between">
                        {/* <span>Continue Reading</span> */}
                        {/* <img src="images/icon/icon_14.svg" alt="" className="arrow" /> */}
                      </a>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            {/* /.feedback_slider_two */}
          </div>
          {/* /.slider-wrapper */}
        </div>


        <div className="row align-items-center" style={{ marginTop: -99 }}>
          <div className="col-xl-6 col-lg-8 col-md-7 col-sm-4 ms-auto d-flex justify-content-center justify-content-sm-end">
            <ul className="slider-arrows slick-arrow-two d-flex style-none">
              <li className="prev_f1 slick-arrow ripple-btn" onClick={this.previous}><i className="bi bi-arrow-left" /></li>
              <li className="next_f1 slick-arrow ripple-btn" onClick={this.next}><i className="bi bi-arrow-right" /></li>
              {/* <li className="prev_f1 slick-arrow ripple-btn" onClick={this.previous}></li>
                <li className="next_f1 slick-arrow ripple-btn" onClick={this.next}></li> */}
            </ul>
          </div>
        </div>




        {/* /.inner-content */}
      </div></>
    );
  }
}