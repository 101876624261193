import Header from ".././header/index";
import AddressArea from "./address_area";
import DelegetorsArea from "./delegetors_area";
import Footer from '.././footer/index';


function Become_Sub_Validator() {
    document.title="Ignite-Studio - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
            <Header/>
            <AddressArea/>
            <DelegetorsArea/>
            <Footer />     
     
            </div>


        </div>
    );
}
export default Become_Sub_Validator;