function WithdrawBoxHeading() {
    return (
        <>

            <div className="fancy-feature-thirteen position-relative mt-100 pb-50 lg-pb-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-sm-12 d-flex aos-init aos-animate mt-3 " data-aos="fade-up">
                            <div className="block-style-ten tran3s mt-0 py-4">
                                <div className="col-xl-12 col-md-7">
                                    <div className="d-flex flex-wrap align-content-center px-3">
                                        <h4 className="hero-heading fs-2 fw-bold text-capitalize m-2"> Withdraw</h4>
                                        {/* <p><a href="" class="text-white">https://ict.mind-heart-soul.org</a></p> */}
                                    </div>
                                    <div className="input-box my-4 text-center">
                                        <div className="input-area d-flex flex-column flex-md-row mb-3">
                                            <div className="input-text w-100">
                                                <input type="text" placeholder={0.00} style={{ width: '90%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="d-flex  mt-30 px-1 justify-content-center">
										<div class=" px-3 py-2 text-start d-flex w-100">
											<input type="text" placeholder="" style="width: 95%;">
										</div>
									</div> */}
                                    <ul className="style-none button-group d-lg-flex justify-content-center  pt-25 pb-50">
                                        <li><a href="/validator_withdraw_box"  className="btn-five rounded-pill ripple-btn px-5 py-1">Withdraw</a></li>
                                    </ul>
                                    <div className="text-center">Transaction Hash</div>
                                    <p className="text-center text-dark">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
                                </div>
                            </div> {/* /.block-style-ten */}
                        </div>
                        <div className="col-xl-4 col-sm-6 d-flex aos-init aos-animate mt-3" data-aos="fade-up" data-aos-delay={100}>
                            <div className="block-style-ten tran3s mt-0 py-4 text-center">
                                <br /><br /><br />
                                <div className="text-center"><h3 className="text-dark fw-bold">Available Balance</h3></div>
                                <h2 className="text-center text-dark fw-bold">1520.0 IGT</h2>
                            </div>
                        </div>
                    </div>
                </div> {/* /.container */}
            </div>


        </>

    )
}

export default WithdrawBoxHeading;