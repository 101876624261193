function AddressArea() {
    return (
        <>


            <div className="fancy-feature-twentyFour  mt-150">
                <div className="container">
                    <div className="row gx-xxl-5">
                        <div className="col-lg-12 col-sm-12 mb-40 xs-mb-30 d-flex aos-init aos-animate justify-content-between" data-aos="fade-up">
                            <div className="col-12">
                                <div className="block-style-four become_sub_validators_area">
                                    <div className="col-xl-12 col-md-7">
                                        <div className="d-flex flex-wrap align-content-center px-3">
                                            <img src="images/icon/icon_10.svg" alt="" />
                                            <h4 className="hero-heading fs-2 text-capitalize m-2"> Mind Heart Soul</h4>
                                            {/* <p><a href="" class="text-white">https://ict.mind-heart-soul.org</a></p> */}
                                        </div>
                                        <div className="d-flex flex-wrap mt-30 px-1">
                                            <div className=" px-3 py-2  text-start d-flex ">
                                                <p className="card-text text-dark"><span className="fw-bold">Owner</span>&nbsp; 0xd3136a...621c56 &nbsp; <i className="fa fa-copy" /> </p>
                                            </div>
                                            <div className=" px-3 py-2 text-start d-flex ">
                                                <p className="card-text text-dark"><span className="fw-bold">Signer</span>&nbsp; 0xd3136a...621c56 &nbsp; <i className="fa fa-copy" /> </p>
                                            </div>
                                            <div className=" px-3 py-2 text-start d-flex ">
                                                <p className="card-text text-dark"><i className="fa fa-trophy" />&nbsp; Checkpoints signed &nbsp;<span>100%</span></p>
                                            </div>
                                            <div className=" px-3 py-2 text-start d-flex ">
                                                <p className="card-text text-dark"><i className="fa fa-trophy" />&nbsp;&nbsp; Commission &nbsp;&nbsp;<span>10%</span></p>
                                            </div>
                                        </div>
                                        {/* <p class="text-lg pt-25 pb-40 lg-pb-20 sm-pt-10">Sinco delivered blazing fast, striking ai solution</p> */}
                                        <ul className="style-none button-group d-lg-flex align-items-center pt-25">
                                            {/* <li ><a href="contact-us.html" class="btn-five rounded-pill ripple-btn px-3 py-2">Become a Delegator</a></li> */}
                                            <li className="help-btn btn-lg"><a href="#" className="text-dark">https://ict.mind-heart-soul.org</a></li>
                                        </ul>
                                        <ul className="style-none button-group d-lg-flex align-items-center pt-25 pb-50">
                                            <li><a href="contact-us.html" className="btn-five rounded-pill ripple-btn px-3 py-2">Become a Delegator</a></li>
                                            {/* <li class="help-btn btn-lg"><a href="#" class="text-dark">https://ict.mind-heart-soul.org</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /.block-style-four */}
                        </div>
                    </div>
                </div>
            </div>



        </>

    )
}

export default AddressArea;