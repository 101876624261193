function Proofofreward() {
    return (
        <>

            <div className="fancy-feature-eighteen position-relative pt-50 pb-100 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 ">
                            <div className="block-style-two aos-init aos-animate" data-aos="fade-left">


                                <div className="title-style-one text-center" data-aos="fade-up">
                                    <div className="sc-title-three"> Consensus</div>

                                    {/* <h2 className="main-title md">Partners and Investors </h2> */}
                                </div> {/* /.title-style-one */}

                                <div className="title-style-three">
                                    {/* <div class="sc-title">Over 150k+ Client</div> */}
                                    <h2 className="main-title  text-center proof_of_reward_main_heading"> Proof of <span className="ignite_site_main_heading">REWARD</span>
                                       </h2>
                                </div> {/* /.title-style-three */}
                                <p className="pt-20 pb-25 lg-pb-20 text-center mx-5" >Ignite block rewards are IGT awarded to miners for being the first to solve a complex math problem and creating a new block of verified IGT transactions. also, every user can get rewards for every transaction.
                                </p>

                                <center>
                                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdyG9H_tXKJqY0936vkLEP6GVpC2xNqiLIGYLTuA73sFTIIjw/viewform" className="mt-10 mb-40 lg-mt-30 proof_button">Apply</a>
                                </center>

                                <img src="images/blog/proof_reward_image_two.png" alt="" className="w-60 mx-auto tran4s img-fluid" />
                                {/* <img src="../" alt="" /> */}



                                <div className="mt-100 lg-mt-70">
                                    <div className="container">
                                        <div className="row justify-content-center gx-xxl-5">
                                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                                                <div className="block-style-three mb-25 gallary_image">
                                                     <div className="numb">1</div>
                                                    {/* <h6>Data Automation Solution.</h6> */}
                                                    <p>  Ignite aims to be the developer-friendly blockchain. It fulfills with full-stack development tools for multiple programming languages.
                                                    </p>
                                                </div> {/* /.block-style-three */}
                                            </div>
                                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={150}>
                                                <div className="block-style-three mb-25 gallary_image">
                                                    <div className="numb">2</div>
                                                    {/* <h6>Analytics Business.</h6> */}
                                                    <p>Ignite provides Smooth onboarding, performance tracking, and reporting. See rewards, account balances, and technical statistics on one dashboard.</p>
                                                </div> {/* /.block-style-three */}
                                            </div>
                                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={300}>
                                                <div className="block-style-three mb-25 gallary_image">
                                                    <div className="numb">3</div>
                                                    {/* <h6>A New Breed Of AI.</h6> */}
                                                    <p>No expensive hardware is required to earn rewards Ignite to scale to global requirements with minimal energy requirements.</p>
                                                </div> {/* /.block-style-three */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/* /.block-style-two */}
                        </div>
                    </div>


                </div> {/* /.container */}

                <div className="shapes oval-one" />
                <div className="shapes oval-two" />
                <div className="shapes oval-three" />
            </div>



        </>

    )
}

export default Proofofreward;