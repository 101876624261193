import Header from ".././header/index";
import Jobdescriptionmainpage from "./jobdescriptionmainpage";
import Jobinformation from "./jobinformation";
import Footer from '.././footer/index';

function MobileApplicationDeveloper () {
    document.title="Job-Description - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
                <Header />
                <Jobdescriptionmainpage />
                <Jobinformation/>
                <Footer />

            </div>


        </div>
    );
}
export default MobileApplicationDeveloper;