function FooterCryptoCurrencies() {
    return (
        <>

            <div className="fancy-short-banner-two mt-100">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row align-items-top">
                            <div className="col-xl-5 col-lg-6 order-lg-last" data-aos="fade-left">
                                <div className="title-style-two">
                                    <div className="sc-title">DOWNLOAD APP</div>
                                    <h2 className="main-title">Cryptocurrencies in your pocket</h2>
                                    <p className="sub-title">Buy and Sell your favorite Assets &amp; track on the go, with the
                                        all-new Ignite Wallet app.</p>
                                </div> {/* /.title-style-two */}
                                <div className="d-sm-flex align-items-center button-group mt-40 lg-mt-30">
                                    <a href="#" className="d-flex align-items-center windows-button">
                                        <img src="images/icon/playstore.svg" alt="" className="icon" />
                                        <div>
                                            <span>Get it on</span>
                                            <strong>Google play</strong>
                                        </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center ios-button">
                                        <img src="images/icon/apple.svg" alt="" className="icon" />
                                        <div>
                                            <span>Download on the</span>
                                            <strong>App store</strong>
                                        </div>
                                    </a>
                                </div> {/* /.button-group */}
                            </div>
                            <div className="col-xxl-6 col-xl-7 col-md-6 col-sm-9 order-lg-first m-auto me-lg-0 ms-lg-0">
                                <div className="mobile-screen md-mt-40" data-aos="fade-up"><img src="images/media/Wallet-Mobile.png" alt="" /></div>
                            </div>
                        </div>
                    </div> {/* /.container */}
                    <img src="images/shape/shape_27.png" alt="" className="shapes shape-one" />
                    <img src="images/assets/shape_28.png" alt="" className="shapes shape-two" />
                    <img src="images/shape/shape_29.png" alt="" className="shapes shape-three" />
                </div> {/* /.bg-wrapper */}
            </div>


        </>

    )
}

export default FooterCryptoCurrencies;