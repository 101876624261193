
function Client() {
    return (
        <>   <div className="partner-section-two mt-110">
        <div className="container">
          <div className="title-style-one text-center" data-aos="fade-up">
            <div className="sc-title-three"> ecosystem - partner</div>
            {/* <h2 className="main-title md">Join 27,000+ companies who’ve reached </h2> */}
            {/* <h2 className="main-title md">Partners and Investors </h2> */}
          </div> {/* /.title-style-one */}
          <div className="row">
            <div className="col-12 m-auto">
              <ul className="style-none text-center">
                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a target="_blank" href="https://www.certik.com/projects/ignitechain" className="d-flex align-items-center justify-content-center"><img src="images/logo/partner_one.png" alt="" /></a></li>
                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={100}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener_twoo.png" alt="" /></a></li>
                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={200}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener_three.png" alt="" /></a></li>
                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={300}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener_four.png" alt="" /></a></li>
                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener-5.png" alt="" /></a></li>
              </ul>
            </div>
          </div>
        </div> {/* /.container */}
      </div></>
    );
  }
  
  export default Client;