function Jobinformation() {
    return (
        <>


            <div className="pr-details-one mt-120 lg-mt-90 mb-170 lg-mb-100">
                <div className="container">
                    <div className="wrapper ps-xxl-4 pe-xxl-4 ms-xxl-5 me-xxl-5 ">
                        <div className="row gx-xxl-5">
                            <div className="col-lg-7">
                                <div className="text-wrapper pt-30 md-pt-20">
                                    <ul className="line-height pt-0 job_information_content">
                                        <h5 className="text-center">Responsibilities</h5>
                                        <li>	Develop and collaborate strategies with international teams, agencies and internal divisions. </li>
                                        <li>	Participate in development and evaluation of marketing budgets according to plans and minimize marketing costs.</li>
                                        <li>	Manage legal approval process for territories and proposed efficient activities.</li>
                                        <li>	Manage all communication with internal teams and provide support to all marketing updates to region.</li>
                                        <li>	Collaborate with international and national training departments to identify and implement new programs according to local laws and customs.</li>
                                        <li>	Develop and maintain professional contacts with community, clients, and international marketing representatives.</li>
                                    </ul>

                                
                                    <ul className="line-height pt-0 job_information_content">
                                    <h5 className="text-center">Requirements</h5>
                                        <li> 	Bachelor's degree in business, marketing or related area </li>
                                        <li>	Excellent Communication Skill</li>
                                        <li>	Knowledge of International Market</li>
                                        <li>	Strong networking abilities</li>
                                        <li>	Excellent analytical and detail skills</li>
                                        <li>	Interest and knowledge of international laws and business requirements</li>
                                    </ul>


                                    {/* <p className="text-justify line-hieght"><b>Key Responsibilities :</b> Experience in web application security assessments,
                                        hands on techniques for identifying SQL injections, XSS, CSRF, authentication,
                                        OWASP top issues, Good knowledge of security technologies for secure software development
                                        such as e-commerce apps, apis, authentication techniques and protocols etc. Experience on both
                                        commercial and open source tools Cenzic Hailstorm, Burpsuite, AppScan, WebInspect, Appspider,
                                        sqlmap, OWASP ZAP, d Experience in BEEF, MetaSploit and other exploitation framework</p> */}
                                    {/* <p className="text-justify line-hieght"><b>Technical Experience :</b>  a Looking for candidates with Platform experience
                                        especially on enterprise platformb Proven experience in identifying and exploiting business logic and framework
                                        related vulnerabilities c Vast experience in removing false positives, analyzing dynamic scan webinspect, appscan
                                        reportsd Knowledge of Secure SDLC and Security standards like OWASP, CWE, NIST, OSSTMMd Provide expert advice and
                                        recommendation to application development team as well as vendor</p> */}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="project-info ps-xl-5 mt-30">
                                    <div className="row gx-xxl-5">
                                        <div className="col-sm-6 ">
                                            {/* <div class="pt-title">DATe</div> */}
                                            <div className="pt-text">Position</div>
                                            {/* <p>Application Security Tester / Penetration Tester</p> */}
                                            <p className="job_description">International Business Marketer</p>
                                        </div>
                                        <div className="col-sm-6 ">
                                            {/* <div class="pt-title">Client</div> */}
                                            <div className="pt-text">Location</div>
                                            <p className="job_description">Remote</p>
                                        </div>
                                        <div className="col-sm-4">
                                            {/* <div class="pt-title">Project Type</div> */}
                                            <div className="pt-text">Experience</div>
                                            <p className="job_description">5 Years</p>
                                        </div>
                                        <div className="col-sm-3">
                                            {/* <div class="pt-title">Duration</div> */}
                                            <div className="pt-text">Vacancy</div>
                                            <p className="job_description">1</p>
                                        </div>
                                        <div className="col-sm-5">
                                            {/* <div class="pt-title">Duration</div> */}
                                            <div className="pt-text">Date</div>
                                            <p className="job_description">2022-05-10</p>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="text-center">
                                                <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfkHU2IuOU4aIoX2-hDuQFY6z1irq9q8lFx5jNw_a5VmGtegw/viewform" type="button" className="btn-five px-5">Apply For Job</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <ul class="d-flex social-icon style-none mt-20">
										<li><a href="#"><i class="fab fa-pinterest"></i></a></li>
										<li><a href="#"><i class="fab fa-twitter"></i></a></li>
										<li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
									</ul> */}
                                </div> {/* /.project-info */}
                            </div>
                        </div>
                    </div> {/* /.wrapper */}
                </div>
            </div>



        </>






    )
}

export default Jobinformation;