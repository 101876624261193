import React, {useState} from "react"


function ValidatorsDashboardSidebar() {

    const [toggleClass, setToggleClass] = useState(true)

    const handleToggleClass = () =>{
        setToggleClass(!toggleClass)
    }


    return (
        <>

            <div className={toggleClass ? "sidebar" : "sidebar open"}>
                <div className="logo-details" onClick={handleToggleClass}>
                    <i className={ toggleClass ? "bx bx-menu" : "bx bx-menu-alt-right"} id="btn" />
                </div>
                <ul className="nav-list px-0">
                    <li>
                        <a href="/validators_dashboard">
                            <i className="bx bxs-dashboard" />
                            <span className="links_name">Dashbord</span>
                        </a>
                        <span className="tooltip">Dashbord</span>
                    </li>
                    <li>
                        <a href="/validator_delegator_list" className="text-white">
                            <i className="bx bx-money" />
                            <span className="links_name">withdraw list</span>
                        </a>
                        <span className="tooltip">withdraw list</span>
                    </li>
                    <li>
                        <a href="Delegator_list copy.html" className="text-white">
                            <i className="bx bx-list-minus" />
                            <span className="links_name">Deligators List</span>
                        </a>
                        <span className="tooltip">Deligators List</span>
                    </li>
                </ul>
            </div>


        </>

    )
}

export default ValidatorsDashboardSidebar;