function IgtSolutionsHeading() {
    return (
        <>

            <div className="theme-inner-banner">
                <div className="container">
                    <div className="col-lg-10 col-sm-12">
                        <p className="text-white fw-bolder">solutions</p>
                        <h2 className="intro-title text-start">Ignite for blockchain-based <br /> digital assets</h2>
                        <p className=" text-white-50 pb-25 pt-15 line-height">Blockchain-powered assets allow financial institutions to make existing financial <br /> instruments truly digital and create new investment vehicles.</p>
                        <a href="#" className="btn-eight rounded-pill ripple-btn mb-80">Contact us</a>
                    </div>
                </div>
                {/* <img src="images/shape/shape_38.svg" alt="" class="shapes shape-one">
				<img src="images/shape/shape_39.svg" alt="" class="shapes shape-two"> */}
            </div>


        </>

    )
}

export default IgtSolutionsHeading;