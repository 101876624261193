import Header from ".././header/index";
import IGNITELedger from "./ledger";
import IGNITETransactionid from "./transactionid";
import ValidatorIgnitenetwork from "./validatorignitenetwork";
import StakingOnIgnitepose from "./stakingonignitepose";
import StakeNowReward from "./stakenowreward";
import BecomeavalidatorFAQS from "./becomevalidatorfaqs";
import Footer from '.././footer/index';
import { useEffect, useState } from "react";
// const axios = require('axios');
// const CryptoJS = require("crypto-js");
// const encKey = CryptoJS.AES.encrypt(JSON.stringify(process.env.REACT_APP_KEY), process.env.REACT_APP_PASSWORD).toString();

function Becomeavalidator() {

    // document.title = "Become-Validator - Decentralized finance with Secure & Scalable Blockchain Network";
    // const [toalBlocks, setTotalBlocks] = useState(0);
    // const [totalTransactions, setTotalTransactions] = useState(0);

    // useEffect(() => {
    //     getHomeBolcks();
    //     mytrans();
    // }, [])
    // async function getHomeBolcks() {
    //     await axios.post(process.env.REACT_APP_API_URL, { method: "get_home_Blocks", key: encKey })
    //         .then(res => {
    //             if (res.data.error) {
    //                 return false;
    //             }
    //             setTotalBlocks(res.data[0].block_no);
    //         })
    // }


    // async function mytrans() {
    //     await axios.post(process.env.REACT_APP_API_URL, { method: 'mytrans', key: encKey })
    //         .then(res => {

    //             if (res.data.error) {
    //                 return false;
    //             }
    //             setTotalTransactions(res.data.length);
    //         })
    // }
    return (

        <div>
            <div className="main-page-wrapper">
                <Header />
                <IGNITELedger />
                <IGNITETransactionid totalBlocks={'90k'} totalTransactions={146} />
                <ValidatorIgnitenetwork />
                <StakingOnIgnitepose />
                <StakeNowReward />
                <BecomeavalidatorFAQS />
                <Footer />

            </div>


        </div>
    );
}
export default Becomeavalidator;