import Footer from "../footer";
import Header from "../header";

function Roadmap() {
  document.title="Roadmap | IGNITE Blockchain";

  return (
    <>
      <Header />
      <div className="main-page-wrapper">
        <div className="theme-inner-banner" >
          <div className="container">
            <h2 className="intro-title text-center">Roadmap</h2>
            <ul className="page-breadcrumb style-none d-flex justify-content-center">
              <li><a href="Ignite.html">Home</a></li>
              <li className="current-page">Roadmap</li>
            </ul>
          </div>
          <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
          <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
        </div>
        
        <section id="roadmap" className="vc_rows wpb_row vc_row-fluid section-pad mt-50 mb-100">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6">
                      <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="block-style-two" data-aos="fade-left">
                              </div>
                            </div>
                          </div>	
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-o-content-middle vc_row-flex mt-80">
                    <div data-animate="fadeInUp" data-delay="0.4" className="wpb_column vc_column_container vc_col-sm-12 animated">
                      <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                          <div className="timeline-row timeline-row-odd timeline-row-done">
                            <div className="row no-gutters text-left text-lg-center justify-content-center idgikh" style={{bsGutterX: '0rem'}}>
                              <div className="col-xl">
                                <div className="timeline-item timeline-done">
                                  <span className="timeline-date">2017 Q1</span>
                                  <h6 className="timeline-title">Concept</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Concept Generation</li>
                                      <li>Team Assemble</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl">
                                <div className="timeline-item timeline-done">
                                  <span className="timeline-date">2017 Q2</span>
                                  <h6 className="timeline-title">Research</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Proving the concept can work</li>
                                      <li>Strategic Plan</li>
                                      <li>White paper conpletion</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl">
                                <div className="timeline-item timeline-done">
                                  <span className="timeline-date">2018 Q1</span>
                                  <h6 className="timeline-title">Design</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Platform design and technical demonstration
                                      </li>
                                      <li>Building the MVP</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl">
                                <div className="timeline-item timeline-done">
                                  <span className="timeline-date">2018 Q2</span>
                                  <h6 className="timeline-title">Pre-Sale</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Public financing &amp; Seed funding raised</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline-row timeline-row-even">
                            <div className="row no-gutters text-left text-lg-center justify-content-center  flex-row-reverse">
                              <div className="col-lg">
                                <div className="timeline-item timeline-current">
                                  <span className="timeline-date">2018 Q3</span>
                                  <h6 className="timeline-title">Alpha Test</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>In-house testing of functional</li>
                                      <li>Prototype published and linked to Ethereum
                                        blockchain with real-time scanning</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="timeline-item">
                                  <span className="timeline-date">2018 Q4</span>
                                  <h6 className="timeline-title">Token Sale</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>ICO Press Tour</li>
                                      <li>Open global sales of ICOblock token</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="timeline-item">
                                  <span className="timeline-date">2019 Q1</span>
                                  <h6 className="timeline-title">App Beta Test</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Private closed beta</li>
                                      <li>Open beta launched to public and improvement
                                        the app</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline-row timeline-row-odd timeline-row-last">
                            <div className="row no-gutters text-left text-lg-center justify-content-center ">
                              <div className="col-lg">
                                <div className="timeline-item">
                                  <span className="timeline-date">2019 Q2</span>
                                  <h6 className="timeline-title">Crowdfunding Integration</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Smart contracts support creators</li>
                                      <li>Ethereum tokens support</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="timeline-item">
                                  <span className="timeline-date">2019 Q3</span>
                                  <h6 className="timeline-title">Community Benefits</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Establishing global user base</li>
                                      <li>US start retailer selection</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="timeline-item">
                                  <span className="timeline-date">2020 Q1</span>
                                  <h6 className="timeline-title">More Operational</h6>
                                  <div className="timeline-info">
                                    <ul>
                                      <li>Integration of third party controllers</li>
                                      <li>Marketplace cooperative module</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        <div className="clearfix" ></div>
      </section>

      </div >
    <Footer />
    </>
  );
}

export default Roadmap;