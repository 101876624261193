function Jobdescriptionmainpage() {
    return (
        <>


            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title  fs-2 text-center nav-link-color text-capitalize">Growth Marketer</h2>
                    {/* <p className="text-center text-white pt-25">Exprience : 2 years</p> */}
                    <p className="text-center text-white pt-25">We are looking for a Growth Marketer who thrives on hitting business targets to join our innovative company. You will support all end-to-end growth projects with the aim of acquiring new clients and ultimately revenue for this brand.</p>
                    {/* <ul class="page-breadcrumb style-none d-flex justify-content-center">
							<li><a href="index-2.html">Home</a></li>
							<li class="current-page">Blog</li>
						</ul> */}
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
            </div>


        </>



    )
}

export default Jobdescriptionmainpage;