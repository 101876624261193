function FooterPartener() {
    return (
        <>

            <div className="feedback-section-four position-relative mt-100 pb-50 lg-mt-100 lg-pb-80">
                <div className="container">
                    <div className="title-style-one text-center" data-aos="fade-up">
                        {/* <div class="sc-title-four">FEEDBACK</div> */}
                        <h2 className="main-title">Partner</h2>
                    </div>
                    {/* /.title-style-one */}
                </div> {/* /.container */}
                {/* /.inner-content */}
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-auto">
                            {/* <ul className="style-none text-center mt-40 lg-mt-20">
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-13.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={100}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-12.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={200}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-9.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={300}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-7.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-8.png" alt="" /></a></li>
                            </ul> */}

                            <ul className="style-none text-center">
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partner_one.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={100}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener_twoo.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={200}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener_three.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={300}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener_four.png" alt="" /></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay={400}><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/partener-5.png" alt="" /></a></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <img src="images/shape/3-1.png" alt="" className="shapes shape-1" />
                <img src="images/shape/3-2.png" alt="" className="shapes shape-2" />
            </div>


        </>

    )
}

export default FooterPartener;