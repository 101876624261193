function IGNITEStudioProcess() {
    return (
        <>

            <div className="fancy-feature-three position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="block-style-two pe-xxl-5" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="sc-title">Working Process</div>
                                    <h2 className="main-title">How Ignite Studio works?</h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-20 pb-30 lg-pb-10">Ignite Studio builds crypto based products and solution from scratch as well help in making existing solutions perform better.</p>
                                {/* <div className="btn-three">Want to learn more about us? <a href="about-us1.html">Click here <i className="fas fa-chevron-right" /></a></div> */}
                            </div> {/* /.block-style-two */}
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-6 ms-auto text-end">
                            <div className="illustration-holder position-relative d-inline-block sm-mt-50">

                                <img src="images/assets/how_ignite_studio.png" alt="" className="main-illustration w-100" />
                                {/* <img src="images/assets/ils_03.svg" alt="" className="main-illustration w-100" />
                                <img src="images/assets/ils_03_1.svg" alt="" className="shapes shape-one" />
                                <img src="images/assets/ils_03_2.svg" alt="" className="shapes shape-two" />
                                <img src="images/assets/ils_03_2.svg" alt="" className="shapes shape-three" />
                                <img src="images/assets/ils_03_4.svg" alt="" className="shapes shape-four" />
                                <img src="images/assets/ils_03_3.svg" alt="" className="shapes shape-five" data-aos="fade-up" data-aos-delay={100} data-aos-duration={1500} />
                                <img src="images/assets/ils_03_3.svg" alt="" className="shapes shape-six" data-aos="fade-up" data-aos-delay={150} data-aos-duration={1500} /> */}
                            </div> {/* /.illustration-holder */}
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="mt-100 lg-mt-70">
                    <div className="container">
                        <div className="row justify-content-center gx-xxl-5">
                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                                <div className="block-style-three mb-25 ignite_feature_box">
                                    <div className="numb">1</div>
                                    <h6>1.	Submit Your Product/Idea.</h6>
                                    <p>Tell us about your idea or existing product.</p>
                                </div> {/* /.block-style-three */}
                            </div>
                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={150}>
                                <div className="block-style-three mb-25 ignite_feature_box">
                                    <div className="numb">2</div>
                                    <h6>2.	Connect to Ignite Team.</h6>
                                    <p>We connect and discuss & develop the solution.</p>
                                </div> {/* /.block-style-three */}
                            </div>
                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={300}>
                                <div className="block-style-three mb-25 ignite_feature_box">
                                    <div className="numb">3</div>
                                    <h6>3.	Get Your Product to User.</h6>
                                    <p>You get your product in market to users.</p>
                                </div> {/* /.block-style-three */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>



    )
}

export default IGNITEStudioProcess;