function Footerlinkmainpage() {
    return (
        <>

            <div className="hero-banner-five mt-100 mb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-md-7">
                            <h1 className="hero-heading text-white">Scale Today with IGNITE</h1>
                            {/* <p class="text-lg mb-50 pt-40 pe-xl-5 md-pt-30 md-mb-40">Sinco delivered blazing fast, striking ai solution lorem quis data user learning analysis.</p> */}
                            <ul className="style-none button-group d-flex align-items-center">
                                {/* <li className="me-4"><a href="contact-us.html" className="btn-eight	 ripple-btn tran3s">Request A Demo</a></li> */}
                                {/* <li><a class="fancybox video-icon tran3s" data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0"><i class="fas fa-play"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="illustration-holder">
                    <img src="images/assets/ils_13.svg" alt="" className="main-illustration ms-auto" />
                    <img src="images/assets/ils_13_1.svg" alt="" className="shapes shape-one" />
                    <img src="images/assets/ils_13_2.svg" alt="" className="shapes shape-two" data-aos="fade-down" />
                    <img src="images/assets/ils_13_2.svg" alt="" className="shapes shape-three" data-aos="fade-down" />
                </div>
                {/* /.illustration-holder */}
                <div className="shapes oval-one" />
            </div>



        </>

    )
}

export default Footerlinkmainpage;