import Header from ".././header/index";
import Footerlinkmainpage from "./footerlinkmainpage";
import Footerlinkmaincontent from "./footerlinkmaincontent";
import Footerlinkmaininformation from "./footerlinkmaininformation";
import Footer from '.././footer/index';

function IgniteCoin() {
    document.title="Ignite-Coin - Decentralized finance with Secure & Scalable Blockchain Network"
    return (
        <div>
            <div className="main-page-wrapper">
            <Header/>
            <Footerlinkmainpage/>
            <Footerlinkmaincontent/>
            <Footerlinkmaininformation/>
            {/* <IGNITEigt/>
            <Productdata/>
            <Querydata/> */}
            <Footer />     
     
            </div>


        </div>
    );
}
export default IgniteCoin;