function Networkinfo() {
  return (
    <>

      <div className="container mt-150">
        <div className="row gx-xxl-5">
          <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up">
            <div className="block-style-four ignite_feature_box">
              {/* <div class="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_35.svg" alt=""></div> */}
              <a href="#"><h5>Block explorer</h5></a>
              <p>Feature-rich blockchain explorer and analytics platform for IgniteChain, a new blockchain built for the next generation of apps, games, and digital assets. Ignite explorer provides Search, analysis, and visualize blockchain that occurs on the Ignite Chain, such as extrinsic, transfers, accounts, tokens, prices, staking and other activities.</p>
              <a target="_blank" href="https://ignitescan.com/" className="more-btn"><img src="images/icon/icon_13.svg" alt="" className="tran3s" /></a>
            </div> {/* /.block-style-four */}
          </div>
          <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
            <div className="block-style-four ignite_feature_box">
              {/* <div class="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_08.svg" alt=""></div> */}
              <a href="#"><h5>State</h5></a>
              <p>Welcome to Ignite State home for real-time and historical data on system performance. Ignite State ensures providing accurate data Such as Explorer, Main net, Test net, Dev net, RPC URL, System Matrics, TPS, Average ping time, Transaction count, Response Time, and other activities.</p>
              <a href="#" className="more-btn"><img src="images/icon/icon_13.svg" alt="" className="tran3s" /></a>
            </div> {/* /.block-style-four */}
          </div>
          <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
            <div className="block-style-four ignite_feature_box">
              {/* <div class="icon d-flex align-items-end justify-content-center"><img src="images/icon/icon_09.svg" alt=""></div> */}
              <a href="#"><h5>Network Status</h5></a>
              <p>IgniteChain defines Real-time latencies, data API monitoring, Market status, and endpoint checks. </p>
              <a href="#" className="more-btn"><img src="images/icon/icon_13.svg" alt="" className="tran3s" /></a>
            </div> {/* /.block-style-four */}
          </div>
        </div>
      </div>

    </>

  )
}

export default Networkinfo;