function Pricingtable() {
    return (
        <>
            <div className="pricing-section-three mt-50 pt-80 lg-mt-110">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 m-auto">
                            {/* <div class="title-style-one text-center">
								<h2 class="main-title">Re-Search Paper of Ignite</h2>
								<p class="h5">Ignite PoS chain is run on Proof of Stake mechanism. Anyone can become a validator on the mainnet.</p>
							</div> */}
                            {/* /.title-style-one */}
                            {/* <div class="title-style-one text-center mt-15">
									<div class="sc-title-three font-weight-bold fs-5 p-3 rounded-pill" style="color: #70A5FF; background:#DBE8FF;">14.3% APR</div>
							</div> */}
                            {/* <div class="d-flex justify-content-center mb-50">
								<p class="text-center">Check how much reward you can earn with your $IGT</p>
								<a href="rewards_calculator.html" class="ml-3">Go to Reward Calculator&nbsp;<span><i class="bi bi-arrow-up-right"></i></span></a>
							</div> */}
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="pricing-table-area-one pricing_table_area" data-aos="fade-up" data-aos-delay={100}>
                    <div className="container">
                        <div className="row gx-xxl-5">
                            <div className="col-md-12">
                                <div className="pr-table-wrapper img-1 active md-mb-30">
                                    <div className="title-style-one text-left">
                                    </div>
                                    <div className="pack-name text-left box_heading_first">White Paper</div>
                                    {/* <div class="pack-details">For individuals and teams. Get <span>1 year <br> premium market access</span></div> */}
                                    {/* /.top-banner */}
                                    <p className="text-justify fs-5 mt-15 w-100 pb-15 box_heading_first">This paper proposes a new blockchain architecture based on Proof of Stake Voting. IgniteChain vision of lowering the barrier and enabling established businesses with blockchain technology to create value and solve real-world economic problems.</p>
                                    {/* <div class="d-flex align-items-end"> */}
                                    {/* <img src="images/gallery/530.png" alt="" class="shape-ten m-auto float-end"> */}
                                    
                                        {/* <a target="_blank" href="https://whitepaper.ignite.info" className="btn-five rounded-pill ripple-btn"><span>White Paper</span></a> */}

                                       
                                        {/* <button> <a target="_blank" href="https://whitepaper.ignite.info" className="btn-five rounded-pill ripple-btn">White Paper</a></button> */}
                                        <button> <a target="_blank" href="images/white_paper_ignite_final.pdf" className="btn-five rounded-pill ripple-btn">White Paper</a></button>
                                   
                                    {/* <button className="btn-five rounded-pill ripple-btn" href="/whitepaper.ignite.info "><span>White Paper</span> </button> */}
                                    {/* </div> */}
                                </div> {/* /.pr-table-wrapper */}
                            </div>
                        </div>
                        {/* <div class="msg-note mt-80 lg-mt-50" data-aos="fade-up">If you Need any Custom or others Pricing System. <br> Please <a href="contact-us.html">Send Message</a></div> */}
                    </div>
                </div>
                {/* /.pricing-table-area-one */}
            </div>


        </>
    )
}

export default Pricingtable;