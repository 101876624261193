function IGNITELedger() {
    return (
        <>

            <section className="hero-banner-four">
                <div className="container">
                    <div className="row pt-150">
                        <div className="col-xl-8 col-xl-7 col-lg-8 col-md-11 m-auto ">
                            <center> <div className="slogan"><strong>IGNITE IGT STAKING</strong></div>
                                <h1 className="hero-heading text-white">Secure the Ledger
                                    <br />Earn Ignite</h1>
                                <p className="mb-50 lg-mb-30 text-white">Participate in Decentralize  staking to secure Ignite network and earn IGT coins as a reward. To stake, you don’t need any particular hardware or device. You can do it directly from your Phone or PC.</p>
                            </center>
                        </div>
                        <div className="col-md-12 d-flex flex-wrap justify-content-center mb-100">
                            <div className="pl-3 pb-10">
                                {/* <a target="_blank" href="/become_sub_validator" className="btn-five py-2  ripple-btn rounded-pill mr-3"> Become a Validator </a> */}
                                <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdyG9H_tXKJqY0936vkLEP6GVpC2xNqiLIGYLTuA73sFTIIjw/viewform" className="btn-five py-2  ripple-btn rounded-pill mr-3"> Become a Validator </a>
                            </div>
                            <div className="mx-2">
                                <a target="_blank" href="https://ignitechain.gitbook.io/ignitechain-docs/masternode" className="btn-five py-2 ripple-btn rounded-pill"> Setup a Node </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>



    )
}

export default IGNITELedger;