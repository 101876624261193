function IgniteSolutionsHeading() {
    return (
        <>

            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title text-start">Ignite Solutions</h2>
                    <a href="#" className="btn-eight mt-15 rounded-pill ripple-btn">Contact us</a>
                </div>
                {/* <img src="images/shape/shape_38.svg" alt="" class="shapes shape-one">
				<img src="images/shape/shape_39.svg" alt="" class="shapes shape-two"> */}
            </div>


        </>


    )
}

export default IgniteSolutionsHeading;